import clsx from 'clsx';
import React, { forwardRef, HTMLAttributes, memo, ReactNode } from 'react';
import * as SvgIcon from './svg-icons';
import {ColorType, FullSizeType} from "../components.types";

type SvgIcons =
    | 'CustomApple'
    | 'CustomFacebook'
    | 'CustomFactory'
    | 'CustomFrance'
    | 'CustomGermany'
    | 'CustomGoogle'
    | 'CustomGithub'
    | 'CustomHtml5'
    | 'CustomPayoneer'
    | 'CustomPaypal'
    | 'CustomReact'
    | 'CustomRocketLaunch'
    | 'CustomSaudiArabia'
    | 'CustomStorybook'
    | 'CustomSwift'
    | 'CustomTurkey'
    | 'CustomUsa';

interface IRefWrapperProps extends Record<string, any> {
    children: ReactNode;
}
// @ts-ignore
const RefWrapper = forwardRef<HTMLSpanElement, IRefWrapperProps>(({ children }, ref) => {
    if (ref) {
        return (
            <span ref={ref} data-only-ref='true'>
				{children}
			</span>
        );
    }
    return children;
});

export interface IconProps extends HTMLAttributes<HTMLSpanElement> {
    icon?: SvgIcons;
    className?: string;
    color?: ColorType;
    size?: FullSizeType;
    forceFamily?: null | 'custom' | 'material';
}
const Icon = forwardRef<HTMLSpanElement, IconProps>(
    ({ icon, className, color, size, forceFamily, ...props }, ref) => {
        const IconName = icon;

        // @ts-ignore
        // eslint-disable-next-line import/namespace
        const SvgIconWrapper = SvgIcon[IconName];

        const ClassName = clsx(
            'svg-icon',
            { [`svg-icon-${size}`]: size, [`text-${color}`]: color },
            className,
        );

        const isForceCustom = forceFamily === 'custom';
        const isForceMaterial = forceFamily === 'material';

        if (isForceCustom || (!isForceMaterial && typeof SvgIconWrapper === 'function')) {
            return (
                <RefWrapper ref={ref}>
                    <SvgIconWrapper
                        data-name={`SvgIcon--${IconName}`}
                        className={clsx('svg-icon--custom', ClassName)}
                        {...props}
                    />
                </RefWrapper>
            );
        }
        return null;
    },
);

export default memo(Icon);
