import {Enumify} from "./Enumify";
import {formatMemberStatusToForm} from "../helpers/businessHelper";

export default class MemberStatusEnum extends Enumify<string> {
    static readonly probation = new MemberStatusEnum('PROBATION');
    static readonly probationFreezed = new MemberStatusEnum('PROBATION_FREEZED');
    static readonly active = new MemberStatusEnum('ACTIVE');
    static readonly inactive = new MemberStatusEnum('INACTIVE');
    static readonly dead = new MemberStatusEnum('DEAD');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatMemberStatusToForm(this)
    }
}
