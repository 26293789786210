import React, {useContext, useLayoutEffect} from 'react';
import clsx from "clsx";

import Aside from "./Aside";
import Header from "./Header";
import Footer from "./Footer";
import {defaultMetadata, Metadata} from "../../commons/data/metadata";
import {globalT} from "../../lang";
import config from "../../config";
import ThemeContext from "../../commons/contexts/ThemeContext";

type LayoutProps = {
    withAside?: boolean,
    withHeader?: boolean,
    withFooter?: boolean,
    metadata?: Metadata,
    className?: string,
    children: React.ReactElement | React.ReactElement[],
}

const Layout = ({ metadata = defaultMetadata, withFooter = true, withHeader = true, withAside = true, children, className = '' }: LayoutProps) => {
    const { asideStatus } = useContext(ThemeContext)

    useLayoutEffect(() => {
        const _title = metadata.skipTitleIntl ? metadata.title : globalT(metadata.title);
        document.getElementsByTagName('TITLE')[0].textContent = metadata.titleWithoutAppName
            ? _title
            : `${_title} | ${config.appName}`;

        // @ts-ignore
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', globalT(metadata.description || 'metadata.default.desc'));
    });

    return (
        <main className={clsx('main', !asideStatus && 'aside-hidden')}>
            <Aside />
            <section className='app-container'>
                <Header />

                <div className='main-content-wrapper'>
                    <section className={`main-content ${className}`}>
                        {children}
                    </section>

                    <Footer />
                </div>
            </section>
        </main>
    );
};

export default Layout;
