import {Enumify} from "./Enumify";
import {formatTicketStatusToForm} from "../helpers/businessHelper";

export default class TicketStatusEnum extends Enumify<string> {
    static readonly pending = new TicketStatusEnum('PENDING');
    static readonly treated = new TicketStatusEnum('TREATED');
    static readonly cancelled = new TicketStatusEnum('CANCELLED');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatTicketStatusToForm(this)
    }

    get getStatusMap () {
        switch (this) {
            case TicketStatusEnum.pending:
                return { color: 'warning' }
            case TicketStatusEnum.treated:
                return { color: 'primary' }
            case TicketStatusEnum.cancelled:
                return { color: 'danger' }
            default:
                return { color: 'danger' }
        }
    }
}
