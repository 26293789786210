import Countries from '../data/countries.json';

/**
 * Language supported for country name
 */
export interface Lang {
    readonly br: string;
    readonly de: string;
    readonly es: string;
    readonly fa: string;
    readonly fr: string;
    readonly hr: string;
    readonly it: string;
    readonly ja: string;
    readonly nl: string;
    readonly pt: string;
}

export interface LangFull extends Lang {
    readonly en: string;
}

export interface CountryLight {
    name: string,
    flag: string,
    alpha2Code: string,
    alpha3Code:string,
    translation: string
}

export interface Country {
    alpha2Code: string;
    alpha3Code: string;
    altSpellings: string[];
    area: number;
    borders: string[];
    callingCodes: string[];
    capital: string;
    cioc: string;
    currencies: Array<{
        code: string;
        name: string;
        symbol: string;
    }>;
    demonym: string;
    flag: {
        emoji: string,
        unicode: string,
        image: string
    };
    gini: number;
    languages: Array<{
        iso639_1: string;
        iso639_2: string;
        name: string;
        nativeName: string;
    }>;
    latlng: [number, number];
    name: string;
    nativeName: string;
    numericCode: string;
    map: {
        geo: string,
        topo: string,
    };
    population: number;
    region: string;
    regionalBlocs: string[];
    subregion: string;
    timezones: string[];
    topLevelDomain: string[];
    translations: Lang
}

export interface CountryTranslated extends Country {
    translation: string
}

class CountryManager {
    private readonly countries: Country[];

    constructor() {
        // @ts-ignore
        this.countries = Countries;
    }

    getCountryFromAlpha3Code = (alpha3Code: string) : Country | null => {
        const item = this.countries.find(c => c.alpha3Code === alpha3Code.toUpperCase());
        return item ?? null;
    };

    getCountryFromAlpha2Code = (alpha2Code: string) : Country | null => {
        const item = this.countries.find(c => c.alpha2Code === alpha2Code.toUpperCase());
        return item ?? null;
    };

    getCountriesAlpha2Code = () : string[] => {
        return this.countries.map(c => c.alpha2Code);
    }

    getCountryAlpha2CodeWithFlagMap = (alpha2Code: string, translation: (keyof LangFull) = 'fr') => {
        const c = this.getCountryFromAlpha2Code(alpha2Code);
        if (!c)
            return null;

        return {
            name: c.name,
            flag: c.flag.emoji,
            alpha2Code: c.alpha2Code,
            alpha3Code: c.alpha3Code,
            translation: translation === 'en'
                ? c.name
                : c.translations[translation] || '',
        };
    }

    getCountriesWithFlagMap = (translation: (keyof LangFull) = 'fr') => {
        return this.countries.map(c => ({
            name: c.name,
            flag: c.flag.emoji,
            alpha2Code: c.alpha2Code,
            alpha3Code: c.alpha3Code,
            translation: translation === 'en'
                ? c.name
                : c.translations[translation] || '',
        }));
    }

    isValidAlpha2Code = (alpha2Code: string) : boolean => {
        return this.getCountryFromAlpha2Code(alpha2Code) !== null;
    }
}

export default new CountryManager();
