import React from 'react';

import BaseRequest from "./BaseRequest";
import IntlMessage from "../IntlMessage";

const BadRequest400 = () => (
    <BaseRequest
        showBtn
        message={{
            id: 'errors.400.message'
        }}
        title={<IntlMessage id="errors.400.title" />}
    />
);

export default BadRequest400;
