import React from 'react';
import * as yup from "yup";
import {AxiosError} from "axios";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";
import {isReference} from "../../../commons/helpers/funcHelper";

import {globalT} from "../../../lang";
import AuthLayout from "../AuthLayout";
import rpnClient from "../../../network";
import {AUTH} from "../../../commons/urls/front";
import InputLabel from "@mui/material/InputLabel";
import {NavLink, useNavigate} from "react-router-dom";
import IntlMessage from "../../../components/IntlMessage";
import useSettingsStore from "../../../store/slices/settings";
import * as yupSchema from "../../../components/forms/yupShema";
import InputPassword from "../../../components/forms/InputPassword";
import {getAllQueryParams} from "../../../commons/helpers/urlHelpers";
import NotificationManager from "../../../components/notifications/NotificationManager";


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    password: yupSchema.password,
});

type FormType = {
    password?: string
}

const ForgotPasswordConfirmation = () => {
    const navigate = useNavigate()
    let reference = getAllQueryParams<{ reference: string }>().reference
    reference = isReference(reference) ? reference : undefined

    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormType> = (data) => {
        setRequestGlobalLoader(true)
        rpnClient
            .auth
            .forgotPassword
            .confirm(reference!, data.password!)
            .then(() => {
                setRequestGlobalLoader(false)
                NotificationManager.success(globalT("auth.forgotPassword.reset.success.message"))
                navigate(AUTH.LOGIN)
            })
            .catch((err: AxiosError) => {
                setRequestGlobalLoader(false)
                if (err.response && err.response.status) {
                    if (err.response.status === 404) {
                        NotificationManager.error(globalT("auth.forgotPassword.reset.reference.notFound"))
                    }
                }
            })
    }

    return (
        <AuthLayout
            titleString='auth.forgotPassword.reset.title'
            title={(
                <h3 className='h3 text-center'>
                    <IntlMessage id='auth.forgotPassword.reset.title' />
                </h3>
            )}
            paperClassName="col-md-10 col-lg-7 py-15 text-center">
            {!reference ? (
                <Alert severity="warning">
                    <IntlMessage id='auth.forgotPassword.reset.reference.notFound' />
                </Alert>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} className="">
                    <div className="row g-4 justify-content-center">
                        <div className="col-sm-12">
                            <InputLabel className='white-space-inherit'>
                                <IntlMessage id='auth.forgotPassword.reset.message' />
                            </InputLabel>
                        </div>

                        <div className="col-sm-12">
                            <InputPassword
                                autoFocus
                                name="password"
                                errors={errors}
                                control={control}
                                noPlaceholder={false}
                                label={<IntlMessage id="form.field.password.new" />}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>

                        <div className="col-md-12">
                            <Button
                                fullWidth
                                type='submit'
                                color='secondary'
                                variant='contained'
                                startIcon={<SaveIcon />}
                                className='text-inherit'>
                                <IntlMessage id='button.save' />
                            </Button>
                        </div>

                        <div className="col-md-12 mt-3">
                            <Typography>
                                <IntlMessage
                                    id='auth.forgotPassword.reset.return.text'
                                    values={{
                                        a: (chunks: string) => (
                                            <NavLink to={AUTH.FORGOT_PASSWORD.INDEX} className='a-tag text-end text-secondary text-underline'>
                                                {chunks}
                                            </NavLink>
                                        )
                                    }}
                                />
                            </Typography>
                        </div>
                    </div>
                </form>
            )}
        </AuthLayout>
    );
};

export default ForgotPasswordConfirmation;
