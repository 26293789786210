import dayjs, {Dayjs} from "dayjs";
import DeathRequest from "./DeathRequest";
import DeathDocumentType from "./DeathDocumentType";

export default class DeathDocument {
    public id: string
    public path: string
    public death: DeathRequest
    public documentType: DeathDocumentType
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.path = data.path
        this.death = new DeathRequest(data.death)
        this.documentType = new DeathDocumentType(data.proofDocument)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }
}
