import dayjs, {Dayjs} from "dayjs";

export default class TicketType {
    public id: string
    public name: string
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public description: string
    public roles: Array<{ id: string, name: string }>
    
    constructor(data: any) {
        this.name = data.name
        this.id = data.reference || data.id
        this.description = data.description
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.roles = data?.roles?.map((r: any) => { return {id: r.reference, name: r.label}})
    }

    get rolesIds (): string[] {
        return this.roles.map(p => p.id)
    }
}
