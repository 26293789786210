import React, {useEffect} from "react";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import {Control, UseFormSetError, UseFormSetValue} from "react-hook-form/dist/types/form";
import {UseControllerProps} from "react-hook-form/dist/types/controller";
import {Controller, ControllerProps, RegisterOptions} from 'react-hook-form';

import Label from "./Label";
import {globalT} from "../../lang";
import ErrorInputComponent from "./ErrorInputComponent";
import {resolveFieldError} from "../../commons/helpers/funcHelper";
import {BaseProps, CreatableSelect, Select} from "./select/ExtendedSelect";

export type FieldValues = Record<any, any>;

export type InputComponentProps = ((Partial<TextFieldProps> | Partial<ControllerProps<any>>) | BaseProps<any, boolean, any> ) & {
    // control and errors are handled by react-hook-form
    control: Control<any, any>,
    errors: FieldErrors<any>,
    label?: React.ReactNode,
    helperLabel?: React.ReactNode,
    name: string,
    disabled?: boolean,
    noPlaceholder?: boolean,
    displayRequiredAsterisk?: boolean,
    type?: HTMLInputElement['type'],
    options?: Array<any>,
    isRequired?: boolean,
    isDisabled?: boolean,
    setValue?: UseFormSetValue<any>;
    autoSelectTheFirst?: boolean,
    defaultDisabledIfOnlyOneItem?: boolean,
    controllerProps?: object,
    wrapperProps?: object,
    componentType?: 'input' | 'select' | 'select-create' | 'date' | 'custom' | 'textarea',
    // Override validate to fix typescript error on validate function.
    // Example: (val) => condition && (<IntlMessage id='xxx' />)
    otherValidators?: Omit<UseControllerProps['rules'], 'validate'> & {
        validate?: RegisterOptions['validate'] | ((val: string | undefined) => true | JSX.Element)
    },
    customInputRender?: (props: any) => React.ReactNode,
    children?: React.ReactNode
    isOutsideLabel?: boolean
}

const InputComponent = ({
                            control,
                            errors,
                            label,
                            helperLabel,
                            name,
                            noPlaceholder = true,
                            isRequired = true,
                            displayRequiredAsterisk = false,
                            componentType = 'input',
                            setValue,
                            isDisabled = undefined,
                            autoSelectTheFirst = false,
                            defaultDisabledIfOnlyOneItem = false,
                            isOutsideLabel = false,
                            options,
                            customInputRender,
                            otherValidators = {},
                            controllerProps = {},
                            wrapperProps = {},
                            ...restProps
                        }: InputComponentProps) => {
    const fieldError = resolveFieldError(errors, name);

    useEffect(() => {
        if (autoSelectTheFirst && setValue && options && options.length > 0) {
            setValue(name, options[0]);
        }
    }, []);

    return (
        <>
            {componentType === 'custom' ? (
                // @ts-ignore
                <Controller 
                    name={name} 
                    control={control}
                    // @ts-ignore
                    rules={{
                        required: {
                            value: isRequired,
                            message: globalT('form.errors.required')
                        },
                        ...otherValidators
                    }}
                    {...restProps} 
                />
            ) : (
                <Controller
                    name={name}
                    control={control}
                    // @ts-ignore
                    rules={{
                        required: {
                            value: isRequired,
                            message: globalT('form.errors.required')
                        },
                        ...otherValidators
                    }}
                    {...controllerProps}
                    render={({ field }) => {
                        if (componentType === 'select' || componentType === 'select-create') {
                            const Component = componentType === 'select' ? Select : CreatableSelect;
                            return (
                                <>
                                    {label && (
                                        <Label id={`${field.name}-label`} isRequired={isRequired}>
                                            {label}
                                        </Label>
                                    )}
                                    {/* @ts-ignore */}
                                    <Component
                                        {...field}
                                        options={options}
                                        isDisabled={isDisabled || (defaultDisabledIfOnlyOneItem && options && options.length === 1)}
                                        {...restProps}
                                    />
                                    <ErrorInputComponent
                                        fieldError={fieldError}
                                    />
                                </>
                            );
                        }

                        const hasError = Boolean(fieldError && fieldError.message)

                        if (customInputRender)
                            return (
                                <>
                                    {customInputRender({
                                        component: componentType,
                                        field,
                                        required: isRequired,
                                        // @ts-ignore
                                        placeholder: noPlaceholder ? undefined : globalT((helperLabel || label).props.id),
                                        ...restProps
                                    })}
                                    <ErrorInputComponent
                                        fieldError={fieldError}
                                    />
                                </>
                            )

                        return (
                            <>
                                {isOutsideLabel && label && (
                                    <Label isRequired={isRequired}>
                                        {label}
                                    </Label>
                                )}
                                <TextField
                                    name={field.name}
                                    value={field.value}
                                    // @ts-ignore
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    fullWidth
                                    type='text'
                                    size='small'
                                    variant='outlined'
                                    label={isOutsideLabel ? undefined : label}
                                    // @ts-ignore
                                    placeholder={noPlaceholder ? undefined : globalT(label.props.id)}
                                    // onBlur={async () => {
                                    //     if (restProps.trigger) {
                                    //         await restProps.trigger(name);
                                    //     }
                                    // }}
                                    // InputLabelProps={{
                                    //     shrink: restProps.type !== "text",
                                    // }}
                                    error={hasError}
                                    required={isRequired}
                                    helperText={hasError ? fieldError?.message : undefined}
                                    {...restProps}
                                />
                            </>
                        );
                    }}
                />
            )}
        </>
    );
};

export default InputComponent;

type IAddError = (
    name: string, message: string, isReactHookForm: boolean
) => void

type IIsGlobalFormValid = (
    setError: UseFormSetError<any>,
    callback: (addError: IAddError) => void
) => boolean

/**
 * Handle form validation
 * @param setError
 * @param callback
 */
export const isGlobalFormValid: IIsGlobalFormValid = (setError, callback) => {
    // The name field is JUST for debug
    const errors: Array<{ name: string, checker: (shouldFocus: boolean) => unknown }> = []

    const addError: IAddError = (name, message, isReactHookForm = true) => {
        errors.push({
            name,
            checker: (shouldFocus) => {
                if (isReactHookForm) {
                    setError(name, { type: "custom", message }, { shouldFocus })
                } else {
                    setError(name, { type: "custom", message }, { shouldFocus: false })

                    const element = document.getElementById(`${name}-label`)
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }
            }
        })
    }

    // Validating form
    callback(addError)

    if (errors.length > 0)
        errors.forEach((item, index) => {
            // You can use the name field here for debug
            // console.debug('Current error ', item.name)
            if (index === 0)
                item.checker(true);
            else item.checker(false)
        });

    return errors.length === 0
}
