import React, {createContext, useMemo, useState} from "react"
import config from "../../config"
import useDeviceScreen from "../hooks/useDeviceScreen"
import StorageHelper, {StorageKeys} from "../helpers/StorageHelper"

export interface IThemeContextProps {
    darkModeStatus: boolean
    asideStatus: boolean
    fullScreenStatus: boolean
    mobileDesign: boolean
    setAsideStatus: (value: ((prevState: boolean) => boolean) | boolean) => void
    setDarkModeStatus: (value: ((prevState: boolean) => boolean) | boolean) => void
    setFullScreenStatus: (value: ((prevState: boolean) => boolean) | boolean) => void
}

const ThemeContext = createContext<IThemeContextProps>({} as IThemeContextProps)

export type ThemeContextProviderProps = {
    children: React.ReactNode
}

export const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({ children }) => {
    const deviceScreen = useDeviceScreen()

    const mobileDesign = Number(deviceScreen?.width) <= config.ui.mobileBreakpointSize

    const [darkModeStatus, setDarkModeStatus] = useState(false)
    const [fullScreenStatus, setFullScreenStatus] = useState(false)
    const [asideStatus, setAsideStatus] = useState(
        StorageHelper.getItem(StorageKeys.ASIDE_STATUS)
            ? StorageHelper.getItem(StorageKeys.ASIDE_STATUS) === 'true'
            : Number(deviceScreen?.width) >= config.ui.asideBreakpointSize,
    )

    const values: IThemeContextProps = useMemo(
        () => ({
            darkModeStatus,
            setDarkModeStatus,
            mobileDesign,
            fullScreenStatus,
            setFullScreenStatus,
            asideStatus,
            setAsideStatus,
        }),
        [
            asideStatus,
            fullScreenStatus,
            mobileDesign,
        ],
    )

    return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
}

export default ThemeContext
