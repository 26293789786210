import clsx from "clsx";
import {NavLink} from "react-router-dom";
import React, {useLayoutEffect, useState} from 'react';

import * as menus from "../Navigation/menu";
import {HOME} from "../../../commons/urls/front";
import Navigation from "../Navigation/Navigation";

const backgroundUrl = "https://www.notrerpn.org/bundles/img/home1.jpg"

const Aside = () => {
    const [asideOverlayHasScroll, setAsideOverlayHasScroll] = useState(false);
    const asideOverlayRef = React.useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (asideOverlayRef.current) {
            if (asideOverlayRef.current.scrollHeight > asideOverlayRef.current.clientHeight) {
                setAsideOverlayHasScroll(true);
            }
        }
    }, []);

    return (
        <>
            <aside className={clsx('with-image')} style={{ backgroundImage: `url(${backgroundUrl})` }}>
                <div
                    ref={asideOverlayRef}
                    className={clsx('aside-overlay', !asideOverlayHasScroll && 'no-scroll')}>
                    <div className="aside-header py-2 mb-2">
                        <div className="brand-logo">
                            <NavLink className='a-tag text-white text-underline-inherit' to={HOME}>
                                <img
                                    src="/assets/images/rpn.png"
                                    className={clsx('img img-fluid')}
                                    alt='Logo'
                                    width={50}
                                />
                                <span className='ms-2' style={{ fontFamily: 'cursive' }}>
                                    Retour au Pays Natal
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="aside-body">
                        <Navigation
                            menu={menus.mainMenu}
                            id='navigation-mainMenu'
                        />
                        <Navigation
                            menu={menus.communityMenu}
                            id='navigation-communityMenu'
                        />
                        <Navigation
                            menu={menus.deaths}
                            id='navigation-deaths'
                        />
                        <Navigation
                            menu={menus.finances}
                            id='navigation-finances'
                        />
                        <Navigation
                            menu={menus.securityMenu}
                            id='navigation-securityMenu'
                        />
                        <Navigation
                            menu={menus.otherMenu}
                            id='navigation-otherMenu'
                        />
                    </div>
                    <div className="aside-footer">

                    </div>
                </div>
            </aside>
            <div className="aside-backdrop" />
        </>
    );
};

export default Aside;
