import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import {FetchResource} from "../../../network/network.types"
import SystemSettings from "../../../commons/models/SystemSettings";

type SystemSettingsStore = FetchResource<SystemSettings>

const useSystemSettingsStore = create<SystemSettingsStore>()(devtools(immer(
    (set) => ({
        loading: false,
        fetched: false,
        content: null,
        error: null,
    })), { name: 'SystemSettingsStore', store: 'SystemSettingsStore' })
)

export default useSystemSettingsStore
