import React from 'react';
import clsx from 'clsx';
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import useTheme from "@mui/material/styles/useTheme";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Dialog, {DialogProps} from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import IntlMessage from "../IntlMessage";

export type DialogComponentProps = Partial<DialogProps> & {
    show: boolean;
    title: any;
    titleClassName?: string;
    style?: any;
    contentClassName?: string;
    headerRight?: React.ReactNode;
    showCloseButton?: boolean;
    disableBackdropClick?: boolean;
    headerWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
    onClose: (event?: React.FormEvent<HTMLButtonElement>) => void;
    submitBtn?: {
        others?: Record<string, any>;
        onClick: (event?: React.FormEvent<HTMLButtonElement>) => any;
    }
}

/**
 * Base component for dialog and modal box
 */
const DialogComponent = ({
                             show,
                             title,
                             titleClassName,
                             headerRight,
                             showCloseButton = true,
                             disableBackdropClick = false,
                             maxWidth,
                             style,
                             onClose,
                             submitBtn,
                             children,
                             contentClassName,
                             headerWrapperProps: {className: headerRightClassName, ...restHeaderWrapperProps} = {},
                             ...restProps}
                             : DialogComponentProps): JSX.Element => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            scroll="paper"
            classes={{
                scrollPaper: 'aligns-items-flex-start',
                paperScrollBody: 'vertical-align-top',
            }}
            fullWidth
            open={show}
            onClose={(event, reason) => {
                if ( !(disableBackdropClick && reason === 'backdropClick') )
                    // @ts-ignore
                    onClose(event)
            }}
            
            maxWidth={maxWidth}
            // disableBackdropClick
            disableEscapeKeyDown
            fullScreen={fullScreen}
            aria-labelledby="responsive-dialog-title"
            {...restProps}
        >
            <DialogTitle id="form-dialog-title">
                <div className={clsx("d-flex justify-content-between align-items-center", headerRightClassName)} {...restHeaderWrapperProps}>
                    <h3 className={clsx('h3 mb-0', titleClassName)}>
                        {title}
                    </h3>
                    {headerRight}
                    {showCloseButton && (
                        <IconButton
                            color="primary"
                            aria-label="close"
                            className="text-danger"
                            onClick={onClose}>
                            <CancelIcon />
                        </IconButton>
                    )}
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={`mt-2 ${contentClassName}`}>
                    {children}
                </div>
                {submitBtn && (
                    <div className="my-4">
                        <Button
                            color="primary"
                            variant="contained"
                            // startIcon={<SendIcon />}
                            startIcon={<SaveIcon />}
                            onClick={submitBtn.onClick}
                            className="fw-bold text-inherit me-3"
                            {...(submitBtn.others || {})}
                        >
                            <IntlMessage id="button.save" />
                        </Button>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

DialogComponent.defaultProps = {
    maxWidth: 'sm'
};

export default DialogComponent;
