import {createTheme} from "@mui/material";
import config from "../../config";

const theme = createTheme({
    palette: {
        primary: {
            light: config.colors['primary']['light'],
            main: config.colors['primary']['default'],
            dark: config.colors['primary']['dark'],
            contrastText: '#fff',
        },
        secondary: {
            light: config.colors['secondary']['light'],
            main: config.colors['secondary']['default'],
            dark: config.colors['secondary']['dark'],
            contrastText: '#fff',
        },
    },
});

export default theme;
