import systemSettingsStore from './index'
import rpnClient from "../../../network";
import SystemSettings from "../../../commons/models/SystemSettings";

export const setSystemSettingsLoader = () => systemSettingsStore.setState((state) => {
    state.loading = true
})

export const setSystemSettingsSuccess = (value: SystemSettings) => systemSettingsStore.setState((state) => {
    state.loading = false
    state.fetched = true
    state.content = value
    state.error = null
})
export const  setSystemSettingsFailure = (value: any) => systemSettingsStore.setState((state) => {
    state.loading = false
    state.fetched = true
    state.content = null
    state.error = value || new Error()
})

export const setSystemSettings = () => {
    setSystemSettingsLoader()
    return rpnClient.security.systemSettings.getAll()
        .then((res) => {
            setSystemSettingsSuccess(res.data)
            return Promise.resolve(res.data)
        })
        .catch(err => {
            setSystemSettingsFailure(err)
            return Promise.resolve(err)
        })
}

