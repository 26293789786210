import StorageHelper, {StorageKeys} from "../helpers/StorageHelper";

export default class Permission {
    public id: string;
    public en: string;
    public fr: string;
    public code: string;

    constructor(data: any) {
        this.id = data.reference;
        this.en = data.en;
        this.fr = data.fr;
        this.code = data.code;
    }

    get name() {
        const lang = StorageHelper.getItem(StorageKeys.LANGUAGE);
        return lang === 'en' ? this.en : this.fr
    }
}
