import dayjs, {Dayjs} from "dayjs";
import {LanguageDict} from "../types";
import {generateId} from "../helpers/funcHelper";

export class PollItem {
    public id: string
    public nameEn: string
    public nameFr: string
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public options: Array<PollOption>
    public answers: Array<PollAnswer>
    public allowManyAnswer: boolean

    constructor(data: any) {
        this.id = data.reference
        this.nameEn = data.nameEn
        this.nameFr = data.nameFr
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.allowManyAnswer = data.allowManyAnswer
        this.options = Array.isArray(data.options) ? data.options.map((i: any) => new PollOption(i)) : []
        this.answers = Array.isArray(data.answers) ? data.answers.map((i: any) => new PollAnswer(i)) : []
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}

export class PollOption {
    public id: string
    public nameEn: string
    public nameFr: string
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public allowComment: boolean

    constructor(data: any) {
        this.id = data.reference
        this.nameEn = data.nameEn
        this.nameFr = data.nameFr
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.allowComment = data.allowComment
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}

export class PollAnswer {
    public id: string
    public comment?: string
    public item: string
    public community: string | null
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public option: PollOption

    constructor(data: any) {
        this.id = generateId()
        this.item = data.item
        this.community = data.community
        this.comment = data.comment ?? ''
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.option = new PollOption(data.pollOption)
    }
}

export default class Poll {
    public id: string
    public displayId: number
    public publicResult: boolean
    public isForAllCommunity?: boolean
    public anonymousPoll: boolean
    public answered: boolean | null
    public available: boolean | null
    public nameEn: string
    public nameFr: string
    public descriptionEn: string
    public descriptionFr: string
    public expiredAt: Dayjs
    public items: Array<PollItem>
    public communities: Array<{id: string, name: string}> | undefined
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.displayId = data.id
        this.publicResult = data.publicResult
        this.anonymousPoll = data.anonymousPoll
        this.answered = typeof data.answered === 'boolean' ? data.answered : null
        this.available = typeof data.available === 'boolean' ? data.available : null
        this.nameEn = data.nameEn
        this.nameFr = data.nameFr
        this.isForAllCommunity = data.isForAllCommunity
        this.descriptionEn = data.descriptionEn
        this.descriptionFr = data.descriptionFr
        this.items = Array.isArray(data.items) ? data.items.map((i: any) => new PollItem(i)) : []
        this.communities = Array.isArray(data.communities) ? data.communities.map((c: any) => {return {id: c.reference, name: c.name}}) : undefined
        this.expiredAt = dayjs(data.expiredAt)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }

    getDescription = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`description${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
