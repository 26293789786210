import dayjs, {Dayjs} from "dayjs";

export default class Partner {
    public id: string;
    public label: string;
    public image: string;
    public link: string;
    public createdAt: Dayjs

    constructor(data: any) {
        this.id = data.reference;
        this.label = data.label;
        this.image = data.image;
        this.link = data.link;
        this.createdAt = dayjs(data.createdAt)
    }
}
