import React from 'react';
import BaseCGU from '../cgu';
import { Button } from '@mui/material';
import IntlMessage from '../../IntlMessage';
import DialogComponent from '../../dialogs/DialogComponent';


export type CGUDialogProps = {
    show: boolean,
    onClose: () => void,
    onAccept?: () => void,
}

const CGUDialog = ({ show, onClose, onAccept }: CGUDialogProps) => {
    return (
        <DialogComponent
            id="cgu"
            maxWidth="md"
            show={show}
            disableBackdropClick
            onClose={() => onClose()}
            title={""}
        >
            <BaseCGU />
            <div className="col-md-12 mt-2 d-flex justify-content-end">
                <Button
                    color='secondary'
                    variant='contained'
                    className='text-inherit'
                    onClick={() => onClose()}
                >
                    <IntlMessage id='button.cancel' />
                </Button>
                { onAccept && (
                    <Button
                        color='primary'
                        variant='contained'
                        className='text-inherit'
                        style={{ marginLeft: 10 }}
                        onClick={() => onAccept()}
                    >
                        <IntlMessage id='convention.accept' />
                    </Button>
                )}
            </div> 
        </DialogComponent>
    );
};

export default CGUDialog;
