import React from 'react';

import BaseRequest from "./BaseRequest";
import IntlMessage from "../IntlMessage";

const ConnexionExpired401 = () => (
    <BaseRequest
        showBtn
        message={{
            id: 'errors.401.message'
        }}
        title={<IntlMessage id="errors.401.title" />}
    />
);

export default ConnexionExpired401;
