import React from 'react';

const Footer = () => {
    return (
        <footer className='px-2 py-2'>
            Copyright @ 2024 | RPN
        </footer>
    );
};

export default Footer;
