import {NavLink} from "react-router-dom";
import Button from "@mui/material/Button";
import React, {useEffect} from 'react';
import CircularProgress from "@mui/material/CircularProgress";

import AuthLayout from "../AuthLayout";
import PreRegister from "./PreRegister";
import rpnClient from "../../../network";
import {AUTH} from "../../../commons/urls/front";
import RegistrationManager from "./RegistrationManager";
import RegistrationStepper from "./RegistrationStepper";
import IntlMessage from "../../../components/IntlMessage";
import CodeChecking from "./RegistrationManager/CodeChecking";
import {useImmerReducer} from "../../../commons/hooks/useImmer";
import StorageHelper, {StorageKeys} from "../../../commons/helpers/StorageHelper";
import ErrorRequestComponent from "../../../components/errors/ErrorRequestComponent";
import MultipleItemTransitions from "../../../components/transitions/MultipleItemTransitions";
import {FetchResource, RegistrationManager as RegistrationManagerModel} from "../../../network/network.types";
import RegistrationCommunity from "./RegistrationCommunity";
import {AxiosError} from "axios";
import UserConditions from "./UserConditions";
import Welcome from "./Welcome";

type RegisterControl = {
    step: number,
    shouldShowEmailCheckingText: boolean,
    manager: FetchResource<RegistrationManagerModel>,
}

type OldModelIds = { managerId: string | null }

type ActionType =
    | { type: 'pre-condition/start' }
    | { type: 'pre-condition/over' }
    | { type: 'pre-register/over' }
    | { type: 'manager/save', payload: { manager: RegistrationManagerModel, action: 'create' | 'update' } }
    | { type: 'manager/over' }
    | { type: 'manager/old/load' }
    | { type: 'manager/old/load/success', payload: RegistrationManagerModel }
    | { type: 'manager/old/load/failure', payload: any }
    | { type: 'community/over', payload: { community: Required<RegistrationManagerModel['community']>, action: 'create' | 'update' } }
    | { type: 'community/prev' }
    | { type: 'community/accept-conditions' }

function handleRegisterControl(draft: RegisterControl, action: ActionType) {
    switch (action.type) {
        case 'pre-condition/start':
            draft.step = 0
            break;
        case 'pre-condition/over':
            draft.step = 0.5
            break;
        case 'pre-register/over':
            draft.step = 1
            break;
        case 'manager/old/load':
            draft.manager.loading = true
            break;
        case 'manager/old/load/success':
            draft.manager.content = action.payload
            draft.manager.error = null
            draft.manager.loading = false
            draft.manager.fetched = true
            draft.step = action.payload.community
                ? 3
                : action.payload.emailVerified ? 2 : 1.2
            break;
        case 'manager/old/load/failure':
            draft.manager.error = action.payload
            draft.manager.content = null
            draft.manager.loading = false
            draft.manager.fetched = true
            break;
        case 'manager/save':
            draft.step = (action.payload.action === 'update' && action.payload.manager.emailVerified) ? 2 : 1.2
            draft.shouldShowEmailCheckingText = false
            draft.manager.content = action.payload.manager
            draft.manager.error = null
            draft.manager.loading = false
            draft.manager.fetched = true
            break;
        case 'manager/over':
            draft.step = 2
            if (draft.manager.content)
                draft.manager.content.emailVerified = true
            break;
        case 'community/prev':
            draft.step = 1
            break;
        case 'community/over':
            draft.step = 3
            draft.shouldShowEmailCheckingText = false
            draft.manager.content!.community = action.payload.community
            draft.manager.error = null
            draft.manager.loading = false
            draft.manager.fetched = true
            break;
        case 'community/accept-conditions':
        default:
            StorageHelper.removeItem(StorageKeys.REGISTRATION_CURRENT_STEP)
            StorageHelper.removeItem(StorageKeys.REGISTRATION_MANAGER_ID)
    }

    StorageHelper.setItem(StorageKeys.REGISTRATION_CURRENT_STEP, draft.step.toString())
}

function initRegisterControl(oldModelIds: OldModelIds) {
    let oldCurrentStep = Number(StorageHelper.getItem(StorageKeys.REGISTRATION_CURRENT_STEP))
    if (![0, 0.5, 1, 1.2, 3].includes(oldCurrentStep)) {
        oldCurrentStep = -1
    }

    if (oldCurrentStep === 1.2 || oldCurrentStep === 2) {
        if (!oldModelIds.managerId)
            oldCurrentStep = -1
    }

    return {
        step: oldCurrentStep !== -1 ? oldCurrentStep : 0,
        shouldShowEmailCheckingText: oldCurrentStep === 1.2,
        manager: {
            content: null,
            error: null,
            loading: oldModelIds.managerId !== null,
            fetched: false
        }
    }
}

const RegisterIndex = () => {

    StorageHelper.removeItem(StorageKeys.REGISTRATION_CURRENT_STEP);
    StorageHelper.removeItem(StorageKeys.REGISTRATION_MANAGER_ID);

    const oldModelIds: OldModelIds = {
        managerId: StorageHelper.getItem(StorageKeys.REGISTRATION_MANAGER_ID),
    };

    const [registerControl, registerControlDispatch] = useImmerReducer<RegisterControl, ActionType, OldModelIds>(
        handleRegisterControl,
        oldModelIds,
        initRegisterControl
    )

    const loadDataManager = () => {
        if (!oldModelIds.managerId) {
            registerControlDispatch({ type: 'pre-condition/start' })
            return;
        }

        registerControlDispatch({ type: 'manager/old/load' })
        rpnClient
            .auth
            .register
            .managers
            .getOne(oldModelIds.managerId)
            .then(res => {
                registerControlDispatch({ type: 'manager/old/load/success', payload: res.data })
            })
            .catch((err: AxiosError) => {
                if (err.response && err.response.status) {
                    if (err.response.status === 404) {
                        StorageHelper.removeItem(StorageKeys.REGISTRATION_MANAGER_ID)
                    }
                }
                registerControlDispatch({ type: 'manager/old/load/failure', payload: new Error() })
            })
    }

    useEffect(() => {
        loadDataManager();
    }, []);

    if (registerControl.manager.loading || registerControl.manager.error) {
        return (
            <AuthLayout
                titleString='auth.signUp'
                title={(
                    <h3 className='h3 text-center mb-3'>
                        <IntlMessage id='auth.signUp' />
                    </h3>
                )}
                paperClassName="col-md-12 col-lg-10 py-15"
                headerRight={(
                    <Button
                        component='div'
                        color='primary'
                        variant='contained'
                        className='text-inherit ms-2'>
                        <NavLink to={AUTH.LOGIN} className='text-color-inherit text-underline-inherit'>
                            <IntlMessage id='auth.login.btn' />
                        </NavLink>
                    </Button>
                )}
            >
                {registerControl.manager.error ? (
                    <ErrorRequestComponent error={registerControl.manager.error} loadData={loadDataManager} />
                ) : (
                    <div className='center-hor-ver'>
                        <CircularProgress />
                    </div>
                )}
            </AuthLayout>
        )
    }

    return (
        <AuthLayout
            titleString='auth.signUp'
            title={(
                <>
                    {registerControl.step === 0 && (
                        <h3 className='h3 text-center mb-3'>
                            <IntlMessage id='registration.welcome.title' />
                        </h3>
                    )}
                    {registerControl.step === 0.5 && (
                        <h3 className='h3 text-center mb-3'>
                            <IntlMessage id='auth.signUp' />
                        </h3>
                    )}
                    {registerControl.step > 0.5 && (
                        <RegistrationStepper
                            activeStep={[1, 1.2].includes(registerControl.step) ? 0 : registerControl.step - 1}
                        />
                    )}
                </>
            )}
            paperClassName="col-md-12 col-lg-10 py-15"
            headerRight={(
                <Button
                    component='div'
                    color='primary'
                    variant='contained'
                    className='text-inherit ms-2'>
                    <NavLink to={AUTH.LOGIN} className='text-color-inherit text-underline-inherit'>
                        <IntlMessage id='auth.login.btn' />
                    </NavLink>
                </Button>
            )}
        >
            <MultipleItemTransitions mode={registerControl.step}>
                {registerControl.step === 0 ? (
                    <Welcome onPreConditionOver={() => registerControlDispatch({ type: 'pre-condition/over' })} />
                ) : registerControl.step === 0.5 ? (
                    <PreRegister onPreRegisterOver={() => registerControlDispatch({ type: 'pre-register/over' })} />
                ) : (registerControl.step === 1) ? (
                    // <RegistrationCommunity
                    //     manager={{id: '1', firstName: 'first', lastName: 'last', email: 'de@gmail.com', birthDate: 'l', idCardNumber: 'l', idCardType: 'null', alpha2Code: 'l', city:'l', address: 'l', postalCode: 'l', emailVerified: true, language: 'fr', phoneNumber: 'l', community: null  }}
                    //     onGoToPrevious={() => registerControlDispatch({ type: 'community/prev' })}
                    //     onGoToNext={(newCommunity, action) => registerControlDispatch({
                    //         type: 'community/over',
                    //         payload: { community: newCommunity, action }
                    //     })}
                    // />
                    <RegistrationManager
                        oldManager={registerControl.manager.content}
                        onGoToPrevious={() => registerControlDispatch({ type: 'pre-register/over' })}
                        onGoToNext={(newManager, action) => registerControlDispatch({
                            type: 'manager/save',
                            payload: { manager: newManager, action }
                        })}
                    />
                ) : (registerControl.step === 1.2 && registerControl.manager.content !== null) ? (
                    <CodeChecking
                        isRetry={registerControl.shouldShowEmailCheckingText}
                        managerId={registerControl.manager.content.id}
                        managerEmail={registerControl.manager.content.email}
                        onGoToPrev={() => registerControlDispatch({ type: 'pre-register/over' })}
                        onVerificationDone={() => registerControlDispatch({ type: 'manager/over' })}
                    />
                ) : (registerControl.step === 2 && registerControl.manager.content !== null) ? (
                    <RegistrationCommunity
                        manager={registerControl.manager.content}
                        onGoToPrevious={() => registerControlDispatch({ type: 'community/prev' })}
                        onGoToNext={(newCommunity, action) => registerControlDispatch({
                            type: 'community/over',
                            payload: { community: newCommunity, action }
                        })}
                    />
                ) : (registerControl.step === 3 && registerControl.manager.content !== null) ? (
                    <UserConditions
                        manager={registerControl.manager.content}
                        onGoToPrevious={() => registerControlDispatch({ type: 'manager/over' })}
                        onGoToNext={() => registerControlDispatch({
                            type: 'community/accept-conditions',
                        })}
                    />
                ) : null}
            </MultipleItemTransitions>
        </AuthLayout>
    );
};

export default RegisterIndex;
