import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import DeathDocumentType from "../../../commons/models/DeathDocumentType"
import {FetchResource, RPNClientApiPaginatedResponse} from "../../../network/network.types"

type DeathDocumentTypesStore = FetchResource<RPNClientApiPaginatedResponse<DeathDocumentType[]>>

const useDeathDocumentTypesStore = create<DeathDocumentTypesStore>()(devtools(immer(
    (set) => ({
        loading: false,
        fetched: false,
        content: null,
        error: null,
    })), { name: 'DeathDocumentTypesStore', store: 'DeathDocumentTypesStore' })
)

export default useDeathDocumentTypesStore
