import React, { useEffect } from 'react';
import config from '../../../config/config.dev';

type MonerisCheckoutProps = {
  token: string | null,
  onCompleted: (e: any) => void,
  onCancelled: (e: any) => void,
  onError: (e: any) => void,
}

const MonerisCheckout = ({token, onCompleted, onCancelled, onError}: MonerisCheckoutProps) => {

  useEffect(() => {
    if(token) {
      // @ts-ignore
      const myCheckout = new window.monerisCheckout();
      myCheckout.setMode(config.monerisEnv);
      myCheckout.setCheckoutDiv("monerisCheckout");
      myCheckout.setCallback("cancel_transaction", onCancelled);
      myCheckout.setCallback("error_event", onError);
      myCheckout.setCallback("payment_complete", onCompleted);
      myCheckout.startCheckout([token]);
    }
  }, [token]);

  return (
    <div id="monerisCheckout"></div>
  );
};

export default MonerisCheckout;
