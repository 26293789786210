import dayjs, { Dayjs } from "dayjs"

export default class EventStat {

    public id: string
    public approved: boolean
    public content: string
    public name: string
    public createdAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.approved = data.approved
        this.content = data.content
        this.name = data.name
        this.createdAt = dayjs(data.createdAt)
    }
}
