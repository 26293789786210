import React from 'react';
import clsx from "clsx";
import {NavLink} from "react-router-dom";
import {HOME} from "../commons/urls/front";

const Logo: React.FC<Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'>> = ({ className, ...restProps }) => {
    return (
        <NavLink to={HOME}>
            <img
                src="/assets/images/rpn.png"
                className={clsx('img img-fluid', className)}
                alt='Logo'
                {...restProps}
            />
        </NavLink>
    );
};

export default Logo;
