import React, {useState} from 'react';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputComponent, {InputComponentProps} from "./InputComponent";

const InputPassword = (props: InputComponentProps) => {
    const [show, setShow] = useState(false);
    return (
        <InputComponent
            type={show ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => setShow(!show)}
                            className='me--10px'
                            edge='end'>
                            {show
                                ? <VisibilityOffIcon />
                                : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...props}
        />
    );
};

export default InputPassword;
