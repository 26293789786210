
import StorageHelper, {StorageKeys} from "./StorageHelper";

type JWTData = {
    accessToken: string
}

export const getJwtData = (): JWTData | null => {
    let jwtData = StorageHelper.getItem(StorageKeys.JWT);
    if (!jwtData) return null;

    try {
        const _jwtData = JSON.parse(jwtData);
        if (!_jwtData) return null;

        return {
            accessToken: _jwtData.accessToken
        }
    } catch (e) {
        return null;
    }
}

export const setJwtData = (accessToken: string): void => {
    StorageHelper.setItem(StorageKeys.JWT, JSON.stringify({accessToken}));
}
