import React from 'react';
import {Navigate, Route, Routes } from 'react-router-dom';

import Login from "../views/auth/login";
import * as FRONT from '../commons/urls/front'
import Error404 from "../views/errors/Error404";
import RegisterIndex from "../views/auth/register";
import ForgotPassword from "../views/auth/forgot-password";
import SetCredentials from "../views/auth/login/SetCredentials";
import ForgotPasswordConfirmation from "../views/auth/forgot-password/ForgotPasswordConfirmation";
import UploadDeathDocuments from '../views/deaths/UploadDeathDocuments';
import Cgu from '../views/conditions/cgu';
import Convention from '../views/conditions/convention';

const UnauthRoutes = () => {
    return (
        <Routes>
            <Route path={FRONT.AUTH.ROUTES_ENGINE.FORGOT_PASSWORD.RESET} element={<ForgotPasswordConfirmation />} />
            <Route path={FRONT.AUTH.ROUTES_ENGINE.FORGOT_PASSWORD.INDEX} element={<ForgotPassword />} />
            <Route path={FRONT.AUTH.ROUTES_ENGINE.REGISTER.SET_CREDENTIALS} element={<SetCredentials />} />
            <Route path={FRONT.AUTH.ROUTES_ENGINE.REGISTER.INDEX} element={<RegisterIndex />} />
            <Route path={FRONT.AUTH.ROUTES_ENGINE.LOGIN} element={<Login />} />
            <Route path={FRONT.DEATHS.UPLOAD_DOCUMENTS} element={<UploadDeathDocuments />} />
            <Route path={FRONT.CONDITIONS.CGU} element={<Cgu />} />
            <Route path={FRONT.CONDITIONS.CONVENTION} element={<Convention />} />
            <Route path={FRONT.HOME} element={<Navigate to={FRONT.AUTH.ROUTES_ENGINE.LOGIN} replace />} />

            <Route path='*' element={<Error404 />} />
        </Routes>
    );
};

export default UnauthRoutes;
