import Radio from '@mui/material/Radio';
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import FormLabel from '@mui/material/FormLabel';
import SendIcon from '@mui/icons-material/Send';
import React, {useEffect, useState} from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import IntlMessage from "../../../components/IntlMessage";
import ContentLoader from "../../../components/loaders/ContentLoader";
import useSystemSettingsStore from "../../../store/slices/systemSettings";
import {SystemSettingsEnum} from "../../../commons/models/SystemSettings";
import {setSystemSettings} from "../../../store/slices/systemSettings/actions";
import SingleItemTransition from "../../../components/transitions/SingleItemTransition";

const PreRegister: React.FC<{ onPreRegisterOver: () => void }> = ({ onPreRegisterOver }) => {
    const systemSettings = useSystemSettingsStore(s => s.content)
    const [isCameroonian, setIsCameroonian] = useState<boolean | undefined>(undefined);
    const [hasEnoughMembers, setHasEnoughMembers] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setSystemSettings().catch(() => null)
    }, []);

    return (
        <div className='row text-center'>
            <div className='col-sm-12 col-md-12'>
                {(isCameroonian === false || hasEnoughMembers === false) && (
                    <Alert severity='error' className='my-2'>
                        <IntlMessage id='registration.pre.question.1.error' />
                    </Alert>
                )}
                <FormControl className='mb-3'>
                    <FormLabel id="registration.pre.question.1-label" className='fw-600 p-tag text-gray-700'>
                        <IntlMessage id='registration.pre.question.1' />
                    </FormLabel>
                    <RadioGroup
                        row
                        value={isCameroonian?.toString()}
                        name="registration.pre.question.1"
                        className='justify-content-center'
                        aria-labelledby="registration.pre.question.1-label"
                        onChange={(event) => setIsCameroonian(event.target.value === 'true')}
                    >
                        <FormControlLabel
                            value='true'
                            control={<Radio />}
                            label={<IntlMessage id='button.yes' />}
                        />
                        <FormControlLabel
                            value='false'
                            control={<Radio />}
                            label={<IntlMessage id='button.no' />}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className='col-sm-12 col-md-12'>
                <SingleItemTransition show={isCameroonian === true}>
                    {systemSettings === null ? (
                        <ContentLoader />
                    ) : (
                        <FormControl>
                            <FormLabel id="registration.pre.question.2-label" className='fw-600 p-tag text-gray-700'>
                                <IntlMessage
                                    id='registration.pre.question.2'
                                    values={{
                                        minMemberCount: systemSettings.getValueByKey(SystemSettingsEnum.MINIMUM_MEMBER_PER_COMMUNITY)
                                    }}
                                />
                            </FormLabel>
                            <RadioGroup
                                row
                                value={hasEnoughMembers?.toString()}
                                name="registration.pre.question.2"
                                className='justify-content-center'
                                aria-labelledby="registration.pre.question.2-label"
                                onChange={(event) => setHasEnoughMembers(event.target.value === 'true')}
                            >
                                <FormControlLabel
                                    value='true'
                                    control={<Radio />}
                                    label={<IntlMessage id='button.yes' />}
                                />
                                <FormControlLabel
                                    value='false'
                                    control={<Radio />}
                                    label={<IntlMessage id='button.no' />}
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                </SingleItemTransition>
            </div>

            <div className='col-sm-12 col-md-12'>
                <SingleItemTransition show={hasEnoughMembers === true}>
                    <Button
                        size='small'
                        // component='div'
                        color='secondary'
                        variant='contained'
                        onClick={() => onPreRegisterOver()}
                        endIcon={<SendIcon />}
                        className='text-inherit mt-3 px-3 ms--10px'>
                        <IntlMessage id='button.next' />
                    </Button>
                </SingleItemTransition>
            </div>
        </div>
    );
};

export default PreRegister;
