import React, {useContext} from 'react';
import {useNavigate} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';

import Language from '../Language';
import IntlMessage from "../../IntlMessage";
import BsButton from "../../bootstrap/BsButton";
import {HOME, SECURITY} from "../../../commons/urls/front";
import useAuthUserStore from "../../../store/slices/authUser";
import {logout} from "../../../store/slices/authUser/actions";
import ThemeContext from "../../../commons/contexts/ThemeContext";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import CommunityStatusEnum from '../../../commons/enums/CommunityStatusEnum';

const Header = () => {
    const navigate = useNavigate()
    const { asideStatus, setAsideStatus } = useContext(ThemeContext)
    const authUser = useAuthUserStore(s => s.content!)

    return (
        <header className='header'>
            <div className="container-fluid">
                <div className="row d-flex align-items-center">
                    <div className="header-left col-md">
                        <IconButton
                            aria-label="toggle navigation"
                            className='border'
                            // color="primary"
                            onClick={() => setAsideStatus(!asideStatus)}
                        >
                            <MenuIcon />
                        </IconButton>

                        {authUser?.community && (
                            <span style={{ marginLeft: 20, fontSize: 18, color: `${ authUser?.community?.status === CommunityStatusEnum.activated ? '#04a551' : '#e11610'}` }}>
                                <IntlMessage id='status' />: &nbsp;
                                { authUser?.community?.status === CommunityStatusEnum.activated ? 
                                    <IntlMessage id="static.types.member.status.active" /> :
                                    <>
                                        <IntlMessage id="static.types.member.status.inactive" /> 
                                        { authUser?.community?.status === CommunityStatusEnum.suspended && <IntlMessage id="suspended.description" /> }
                                    </>
                                }
                            </span>
                        )}
                    </div>

                    <div className="header-right col-md-auto">
                        <div className='row g-3'>
                            <Language />
                            {/* User Avatar */}
                            <div className="col-auto">
                                <Dropdown>
                                    <DropdownToggle hasIcon={false}>
                                        <div className='d-flex cursor-pointer'>
                                            <div className='me-2 text-end mt-1'>
                                                <h6 className='fw-bold fs-6 mb-0'>{authUser.user.firstName ? authUser.user.firstName.split(' ')[0] : ''} {authUser.user.lastName ? authUser.user.lastName.split(' ')[0] : ''}</h6>
                                                <span className='text-muted'>
                                                    <small>{authUser.role.name} { authUser?.community?.acronym ? '/ '+authUser?.community?.acronym : '' } </small>
                                                </span>
                                            </div>
                                            <div>
                                                <AccountCircleTwoToneIcon
                                                    color="primary"
                                                    style={{
                                                        fontSize: '3rem'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </DropdownToggle>

                                    <DropdownMenu isAlignmentEnd isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <BsButton
                                                icon='bx-enter'
                                                onClick={() => {
                                                    navigate(SECURITY.PASSWORD.INDEX)
                                                }}>
                                                <IntlMessage id='form.field.password' />
                                            </BsButton>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <BsButton
                                                icon='bx-enter'
                                                onClick={() => {
                                                    logout()
                                                    navigate(HOME)
                                                }}>
                                                <IntlMessage id='auth.logout' />
                                            </BsButton>
                                        </DropdownItem>
                                    </DropdownMenu>

                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
