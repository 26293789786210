import {Enumify} from "./Enumify";
import {formatDocumentTypeToForm} from "../helpers/businessHelper";

export default class DocumentTypeEnum extends Enumify<string> {
    static readonly communication = new DocumentTypeEnum('COMMUNICATION');
    static readonly funeral = new DocumentTypeEnum('FUNERAL');
    static readonly userGuide = new DocumentTypeEnum('USER_GUIDE');
    static readonly constitution = new DocumentTypeEnum('CONSTITUTION');
    static readonly report = new DocumentTypeEnum('REPORT');
    static readonly generalAssemblyReport = new DocumentTypeEnum('AG_REPORT');
    static readonly financeReport = new DocumentTypeEnum('FINANCE_REPORT');
    static readonly auditReport = new DocumentTypeEnum('AUDIT_REPORT');
    static readonly otherReport = new DocumentTypeEnum('OTHER_REPORT');

    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatDocumentTypeToForm(this)
    }

    get transPrefix() {
        switch (this) {
            case DocumentTypeEnum.communication:
                return 'business.documents.type.communication'
            case DocumentTypeEnum.funeral:
                return 'business.documents.type.funeral'
            case DocumentTypeEnum.userGuide:
                return 'business.documents.type.userGuide'
            case DocumentTypeEnum.constitution:
                return 'business.documents.type.constitution'
            case DocumentTypeEnum.generalAssemblyReport:
                return 'business.documents.type.report.ag'
            case DocumentTypeEnum.auditReport:
                return 'business.documents.type.report.audit'
            case DocumentTypeEnum.otherReport:
                return 'business.documents.type.report.other'
            case DocumentTypeEnum.financeReport:
                return 'business.documents.type.report.finance'
            case DocumentTypeEnum.report:
            default:
                return 'business.documents.type.communication'
        }
    }
}
