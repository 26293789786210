import dayjs, {Dayjs} from "dayjs";
import CommunityStatusEnum from "../enums/CommunityStatusEnum";

export default class Community {
    public id: string
    public name: string
    public acronym: string
    public memberCount: number
    public currentMemberCount: number
    public conditionAccepted: boolean
    public address: string
    public city: string
    public region: string
    public countryAlpha2Code: string
    public phoneNumber: string
    public postalCode: string
    public status: CommunityStatusEnum
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public paymentDate?: Dayjs
    public language: string
    public minimumManagerRequired: number
    public managerCount: number
    public balance?: number
    public email?: string

    constructor(data: any) {
        this.id = data.reference || data.id
        this.name = data.name
        this.language = data.language
        this.acronym = data.acronym
        this.conditionAccepted = data.conditionAccepted
        this.memberCount = Number.isInteger(Number(data.memberCount)) ? Number(data.memberCount) : 0
        this.countryAlpha2Code = data.countryAlpha2Code || data.alpha2Code
        this.city = data.city
        this.region = data.region
        this.address = data.address
        this.phoneNumber = data.phoneNumber || data.telephone
        this.postalCode = data.postalCode
        this.minimumManagerRequired = data.minimumManagerRequired
        this.managerCount = data.managerCount
        this.currentMemberCount = data.currentMemberCount
        this.status = CommunityStatusEnum.tryParse(data.status)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.paymentDate = data.paymentDate ? dayjs(data.paymentDate) : data.paymentDate
        this.balance = data.balance
        this.email = data.email || undefined
    }
}
