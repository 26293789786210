// @ts-nocheckkk
import React, {
	Children,
	cloneElement,
	forwardRef,
	ReactElement,
	ReactNode,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TagWrapper from './TagWrapper';
import Collapse from './Collapse';
import {ColorType} from "../components.types";

type TActiveItemId = string | number | boolean | null;

interface IAccordionItemProps {
	id: string | number;
	icon?: string;
	title: React.ReactNode;
	children: ReactNode;
	tag?: 'div' | 'section';
	headerTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span' | 'section';
	overWriteColor?: null | ColorType;
	parentId?: string | number | null;
	activeItem?: TActiveItemId;
	setActiveItem?: any;
	className?: string,
	headerClassName?: string,
	headerRight?: React.ReactNode,
}
export const AccordionItem = forwardRef<HTMLDivElement, IAccordionItemProps>(
	({
		 id,
		 icon,
		 title,
		 children,
		 tag = 'div',
		 headerTag = 'h2',
		 overWriteColor,
		 className,
		 headerClassName,
		 headerRight = null,
		 ...props }, ref) => {
		// eslint-disable-next-line react/prop-types
		const ACTIVE = props.activeItem === id;

		return (
			<TagWrapper tag={tag} ref={ref} className={clsx('accordion-item', className)}>
				<TagWrapper tag={headerTag} className={clsx('accordion-header', headerClassName)} id={id?.toString()}>
					<button
						className={clsx('accordion-button', {
							collapsed: !ACTIVE,
							[`accordion-button-${overWriteColor}`]: overWriteColor,
						})}
						type='button'
						data-bs-toggle='collapse'
						data-bs-target={`#${id}Collapse`}
						aria-expanded={ACTIVE}
						aria-controls={`${id}Collapse`}
						onClick={() =>
							// eslint-disable-next-line react/prop-types
							ACTIVE ? props.setActiveItem(null) : props.setActiveItem(id)
						}>
						{icon && (
							<i className={clsx('accordion-icon bx bx-md me-2', icon, { [`text-${overWriteColor}`]: overWriteColor })} />
						)}
						{title}
					</button>
					{headerRight}
				</TagWrapper>
				<Collapse
					isOpen={ACTIVE}
					id={`${id}Collapse`}
					className={clsx('accordion-collapse')}
					aria-labelledby={id}
					data-bs-parent={`#${props.parentId}`}>
					<div className={clsx('accordion-body')}>{children}</div>
				</Collapse>
			</TagWrapper>
		);
	},
);
AccordionItem.displayName = 'AccordionItem';
AccordionItem.propTypes = {
	/**
	 * Unique ID
	 */
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	/**
	 * Automatically added
	 */
	parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Item title
	 */
	title: PropTypes.string.isRequired,
	/**
	 * Item Content
	 */
	children: PropTypes.node.isRequired,
	/**
	 * Item title icon
	 */
	icon: PropTypes.string,
	tag: PropTypes.oneOf(['div', 'section']),
	headerTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span', 'section']),
	/**
	 * If you have chosen color from the accordion's own properties, you can overwrite it with this.
	 */
	overWriteColor: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
	]),
};
AccordionItem.defaultProps = {
	parentId: null,
	icon: undefined,
	tag: 'div',
	headerTag: 'h2',
	overWriteColor: null,
};

interface IAccordionProps {
	tag?: 'div' | 'section';
	id: string | number;
	activeItemId?: TActiveItemId;
	children: ReactElement<IAccordionItemProps> | ReactElement<IAccordionItemProps>[] | Array<any>;
	shadow?: null | 'none' | 'sm' | 'default' | 'lg';
	color?: ColorType;
	isFlush?: boolean;
	className?: string;
}
const Accordion = forwardRef<HTMLDivElement | HTMLTableSectionElement, IAccordionProps>(
	({ tag = 'div', id, activeItemId, children, shadow = 'default', color = 'primary', isFlush, className }, ref) => {
		const [activeItem, setActiveItem] = useState<TActiveItemId>(
			activeItemId === false
				? null
				: activeItemId || (Array.isArray(children) && children?.flat()[0].props.id),
		);

		return (
			<TagWrapper
				tag={tag}
				ref={ref}
				className={clsx(
					'accordion',
					{
						'accordion-flush': isFlush,
						'shadow-none': isFlush,
						[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
					},
					className,
				)}
				id={id?.toString()}>
				{Children.map(children, (child) =>
					// @ts-ignore
					['AccordionItem'].includes(child?.type?.displayName) ? (
						cloneElement(child as unknown as ReactElement<any>, {
							activeItem,
							setActiveItem,
							parentId: id,
							overWriteColor: child?.props?.overWriteColor || color,
						})
					) : (
						<code className='d-block'>
							Only AccordionItem component should be used as a child.
						</code>
					),
				)}
			</TagWrapper>
		);
	},
);

Accordion.displayName = 'Accordion';

export default Accordion;
