import dayjs, {Dayjs} from "dayjs";
import CommunityStatusEnum from "../enums/CommunityStatusEnum";
import Manager from "./Manager";
import Transaction from "./Transaction";
import Contribution from "./Contribution";
import LogItem from "./LogItem";

export default class CommunityDetails {

    public id: string;
    public name: string;
    public acronym: string;
    public telephone: string;
    public email: string;
    public postalCode: string;
    public memberCount: number;
    public city: string;
    public address: string;
    public region: string;
    public country: string;
    public explanation?: string;
    public language: string;
    public status: CommunityStatusEnum;
    public activationDate: Dayjs;
    public paymentDate: Dayjs;
    public balance: number;
    public activeMembers: number;
    public inactiveMembers: number;
    public deadMembers: number;
    public probationMembers: number;
    public freezedMembers: number;
    public createdAt: Dayjs
    public managers: Manager[];
    public transactions: Transaction[];
    public events: Contribution[];
    public histories: LogItem[];

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.acronym = data.acronym;
        this.telephone = data.telephone;
        this.email = data.email;
        this.postalCode = data.postalCode;
        this.memberCount = Number.isInteger(Number(data.memberCount)) ? Number(data.memberCount) : 0
        this.city = data.city;
        this.address = data.address;
        this.region = data.region;
        this.country = data.country;
        this.language = data.language;
        this.status = CommunityStatusEnum.tryParse(data.status);
        this.activationDate = data.activationDate ? dayjs(data.activationDate) : data.activationDate
        this.paymentDate = data.paymentDate ? dayjs(data.paymentDate) : data.paymentDate
        this.balance = data.balance;
        this.activeMembers = Number.isInteger(Number(data.activeMembers)) ? Number(data.activeMembers) : 0;
        this.inactiveMembers = Number.isInteger(Number(data.inactiveMembers)) ? Number(data.inactiveMembers) : 0;
        this.deadMembers = Number.isInteger(Number(data.deadMembers)) ? Number(data.deadMembers) : 0;
        this.probationMembers = Number.isInteger(Number(data.probationMembers)) ? Number(data.probationMembers) : 0;
        this.freezedMembers = Number.isInteger(Number(data.freezedMembers)) ? Number(data.freezedMembers) : 0;
        this.createdAt = dayjs(data.createdAt);
        this.managers = data.managers?.map((m: any) => new Manager(m));
        this.transactions = data.transactions?.map((t: any) => new Transaction(t));
        this.events = data.events?.map((c: any) => new Contribution(c));
        this.histories = data.histories?.map((l: any) => new LogItem(l));
        this.explanation = data.explanation;
    }

    getStatusMap = () => {
        switch (this.status) {
            case CommunityStatusEnum.activated:
                return { color: 'success' }
            case CommunityStatusEnum.blocked:
                return { color: 'danger' }
            case CommunityStatusEnum.confirmed:
                return { color: 'info' }
            case CommunityStatusEnum.pending:
                return { color: 'warning' }
            default:
                return { color: 'secondary' }
        }
    }
}
