import React from 'react';
import IntlMessage from "../IntlMessage";
import BsButton from "../bootstrap/BsButton";
import Humans from '../../assets/img/scene/scene4.png';
import HumansWebp from '../../assets/img/scene/scene4.webp';

export type BaseRequestProps = {
	showBtn: boolean;
	title: React.ReactElement,
	message: {
		id: string;
		values?: Record<string, any>;
	};
};

const BaseRequest = ({ title, message, showBtn }: BaseRequestProps) => {
	return (
		<div>
			<div className='row d-flex align-items-center'>
				<div className='col-12 mt-5 d-flex flex-column justify-content-center align-items-center'>
					<div
						// style={{ fontSize: 'calc(1rem + 2vw)' }}>
					>
						<h1 className='text-primary text-center fw-bold'>{title}</h1>
					</div>
					<div
						// style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
						style={{ fontSize: '17px', textAlign: 'center', maxWidth: '80%' }}>
						<IntlMessage {...message} />
					</div>
				</div>
				<div className='col-12 my-4 d-flex align-items-baseline justify-content-center'>
					<img
						srcSet={HumansWebp}
						src={Humans}
						alt='Humans'
						style={{ height: '40vh' }}
					/>
				</div>
				<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
					{showBtn && (
						<BsButton
							className='px-4 py-2 me-5'
							color='primary'
							isLight
							icon='bx-home'
							tag='a'
							href='/'>
							<IntlMessage id="button.backToHome" />
						</BsButton>
					)}
				</div>
			</div>
		</div>
	);
};

export default BaseRequest;
