import dayjs, {Dayjs} from "dayjs";
import TransactionDirectionEnum from "../enums/TransactionDirectionEnum";

export default class Transaction {
    public id: string
    public amount: number
    public balanceBefore: number
    public balanceAfter: number
    public direction: TransactionDirectionEnum
    public receivedAt: string
    public reason: string
    public referral: string
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.amount = data.amount
        this.balanceBefore = data.balanceBefore
        this.balanceAfter = data.balanceAfter
        this.direction = TransactionDirectionEnum.tryParse(data.direction)
        this.receivedAt = data.receivedAt
        this.reason = data.reason
        this.referral = data.referral
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }
}
