import dayjs, {Dayjs} from "dayjs";
import MemberTypeEnum from "../enums/MemberTypeEnum";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import RelationTypeEnum from "../enums/relationTypeEnum";

export default class Member {
    public id: string
    public firstName: string
    public lastName: string
    public birthDate: string
    public gender?: 'M' | 'F'
    public email?: string
    public identificationType?: string
    public identificationNumber?: string
    public nationality: string
    public countryAlpha2Code: string
    public city?: string
    public region?: string
    public address?: string
    public explanation?: string
    public postalCode?: string
    public phoneNumber?: string
    public statusLocked?: boolean
    public deathAt: string | null
    public type: MemberTypeEnum
    public status: MemberStatusEnum
    public expirationDate: string | null
    public arrivalDateAfterLimit?: boolean
    //public arrivalDate?: string
    public probationEndDate?: string
    public comment: string | null
    public avatar: string | null
    public filiationFile: string | null
    public matricule: string
    public oldMatricule: string
    public relation: {
        id: string,
        relationship: RelationTypeEnum
        member: Member | null
    } | null
    public communityName: string
    public filiation: RelationTypeEnum
    public filiationDescription: string
    public communityAcronym: string
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.birthDate = data.birthdate
        this.gender = ['MALE', 'M'].includes(data.gender) ? 'M'
            : ['FEMALE', 'F'].includes(data.gender) ? 'F' : undefined
        this.email = data.email
        this.identificationType = data.identificationType
        this.identificationNumber = data.identificationNumber
        this.countryAlpha2Code = data.countryAlpha2Code || data.country || 'CM'
        this.nationality = data.nationality || 'CM'
        this.city = data.city || ""
        this.region = data.region || ""
        this.address = data.address || ""
        this.postalCode = data.postalCode || ""
        this.phoneNumber = data.telephone
        this.deathAt = data.deathAt || null
        this.type = MemberTypeEnum.tryParse(data.type)
        this.status = MemberStatusEnum.tryParse(data.status)
        //this.arrivalDate = data.arrivalDate || null
        this.arrivalDateAfterLimit = data.arrivalDateAfterLimit
        this.expirationDate = data.expirationDate || null
        this.comment = data.comment || null
        this.filiationFile = data.filiationFile
        this.filiation = data.filiation && RelationTypeEnum.tryParse(data.filiation) || null
        this.filiationDescription = data.filiationDescription
        this.probationEndDate = data.probationEndDate
        this.avatar = data.avatar || data.photo || null
        this.matricule = data.matricule
        this.oldMatricule = data.oldMatricule
        this.relation = data.relationData ? {
            member: data.relationData.member || null,
            relationship: RelationTypeEnum.tryParse(data.relationData.relationship),
            id: data.relationData.id || data.relationData.reference,
        } : null
        this.communityName = data.communityName
        this.explanation = data.explanation
        this.communityAcronym = data.communityAcronym
        this.statusLocked = data.statusLocked
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`
    }

    getStatusMap = () => {
        switch (this.status) {
            case MemberStatusEnum.probation:
                return { color: 'warning' }
            case MemberStatusEnum.active:
                return { color: 'success' }
            case MemberStatusEnum.inactive:
                return { color: 'secondary' }
            case MemberStatusEnum.probationFreezed:
                return { color: 'info' }
            case MemberStatusEnum.dead:
            default:
                return { color: 'secondary' }
        }
    }

    hasDeathRequest = () => {
        return this.deathAt !== null && this.status !== MemberStatusEnum.dead
    }

    isDead = () => {
        return this.status === MemberStatusEnum.dead
    }

    static getClone = (data: any) => {
        return new Member({
            ...data,
            reference: data.id,
            birthdate: data.birthDate,
            country: data.country,
            telephone: data.phoneNumber,
            identificationType: data.idCardType,
            identificationNumber: data.idCardNumber,
        })
    }
}
