import React from 'react';
import {globalT} from "../../lang";
import IntlMessage from '../IntlMessage';

const paragraphStyle: React.CSSProperties = {
    textAlign: 'justify'
}

const BaseCGU = () => {
    return (
        <div className='pb-4 px-sm-4 px-0'>
            <div className='p-15'>
                <p className='fw-bold' style={{ fontSize: 20, marginBottom: 30 }}>{<IntlMessage id="cgu.title" />}</p>
                <p style={paragraphStyle}>{<IntlMessage id="cgu.paragraph1" />}</p>
                <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: globalT("cgu.paragraph2") }} />
                <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: globalT("cgu.paragraph3") }} />
                <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: globalT("cgu.paragraph4") }} />
                <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: globalT("cgu.paragraph5") }} />
                <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: globalT("cgu.paragraph6") }} />
                <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: globalT("cgu.paragraph7") }} />
                <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: globalT("cgu.paragraph8") }} />
            </div>
        </div>
    );
};

export default BaseCGU;
