import React from 'react';
import {LinearProgress} from "@mui/material";
import useSettingsStore from "../store/slices/settings";

const RequestGlobalLoader = () => {
    const requestGlobalLoader = useSettingsStore(state => state.requestGlobalLoader);
    return (
        <div className="global-loader justify-content-center">
            {requestGlobalLoader && <LinearProgress />}
            <div className={`${requestGlobalLoader ? 'global-loader-overlay' : ''}`} />
        </div>
    )
}

export default RequestGlobalLoader;
