import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import AuthUser from "../../../commons/models/AuthUser"
import {FetchResource} from "../../../network/network.types"

type AuthUserStore = FetchResource<AuthUser>

const useAuthUserStore = create<AuthUserStore>()(devtools(immer(
    (set) => ({
        loading: false,
        fetched: false,
        content: null,
        error: null,
    })), { name: 'AuthUserStore', store: 'AuthUserStore' })
)

export default useAuthUserStore
