import React, {
	FC,
	useState,
	Children,
	forwardRef,
	ReactNode,
	ReactElement,
	HTMLAttributes,
} from 'react';
import clsx from 'clsx';
import BsButton from './BsButton';
import {IconProps} from '../icons';
import TagWrapper, {TagWrapperProps} from './TagWrapper';
import {BorderSizeType, ColorType} from '../components.types';


type CardShadowType = null | 'none' | 'sm' | 'md' | 'lg' | '3d';

type CardSizeType = 'sm' | null | 'lg';

export interface ICardLabelProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag'];
	className?: string;
	children:
		| ReactElement<ICardTitleProps>
		| ReactElement<ICardTitleProps>[]
		| ReactElement<ICardSubTitleProps>
		| ReactElement<ICardSubTitleProps>[]
		| ReactNode;
	// icon?: IconProps['icon'];
	icon?: string;
	iconColor?: IconProps['color'];
	pre?: ReactNode;
}
export const CardLabel = forwardRef<HTMLDivElement, ICardLabelProps>(
	({ tag = 'div', className, children, icon, iconColor = 'primary', pre, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx('card-label', className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{pre}
				{icon && (
					<>
						<i className={clsx('bx bx-md me-2', icon, { [`text-${iconColor}`]: iconColor })} />
						{/*<Icon
							icon={icon}
							className={clsx('card-icon', { [`text-${iconColor}`]: iconColor })}
						/>*/}
					</>
				)}
				<div className='card-title-wrapper'>{children}</div>
			</TagWrapper>
		);
	},
);

interface ICardActionsProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag'];
	className?: string;
	children: ReactNode;
}
export const CardActions = forwardRef<HTMLDivElement, ICardActionsProps>(
	({ tag = 'div', className, children, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx('card-actions', className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);

interface ICardTitleProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	children: ReactNode;
}
export const CardTitle = forwardRef<HTMLDivElement, ICardTitleProps>(
	({ tag = 'h5', className, children, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx('card-title', className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);


interface ICardSubTitleProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	children: ReactNode;
}
export const CardSubTitle = forwardRef<HTMLDivElement, ICardSubTitleProps>(
	({ tag = 'h6', className, children, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx('card-subtitle', className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);


interface ICardHeaderProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	children:
		| ReactElement<ICardLabelProps>
		| ReactElement<ICardLabelProps>[]
		| ReactElement<ICardActionsProps>
		| ReactElement<ICardActionsProps>[]
		| ReactNode;
	size?: CardSizeType;
	borderSize?: BorderSizeType;
	borderColor?: ColorType;
}
export const CardHeader = forwardRef<HTMLDivElement, ICardHeaderProps>(
	({ tag = 'div', className, children, size, borderSize, borderColor, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx(
					'card-header',
					{
						[`card-header-${size}`]: size,
						[`card-header-border-${borderSize}`]: borderSize,
						[`card-header-border-${borderColor}`]: borderColor,
					},

					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);

interface ICardBodyProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	isScrollable?: boolean;
	children: ReactNode;
}
export const CardBody = forwardRef<HTMLDivElement, ICardBodyProps>(
	({ tag = 'div', className, isScrollable = false, children, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx(
					'card-body',
					{ 'card-body-scrollable': isScrollable },
					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);

interface ICardFooterLeftProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	children: ReactNode;
}
export const CardFooterLeft = forwardRef<HTMLDivElement, ICardFooterLeftProps>(
	({ tag = 'div', className, children, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx('card-footer-left', className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);

interface ICardFooterRightProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	children: ReactNode;
}
export const CardFooterRight = forwardRef<HTMLDivElement, ICardFooterRightProps>(
	({ tag = 'div', className, children, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx('card-footer-right', className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);


interface ICardFooterProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	children:
		| ReactElement<ICardFooterLeftProps>
		| ReactElement<ICardFooterLeftProps>[]
		| ReactElement<ICardFooterRightProps>
		| ReactElement<ICardFooterRightProps>[];
	size?: CardSizeType;
	borderSize?: BorderSizeType;
	borderColor?: ColorType;
}
export const CardFooter = forwardRef<HTMLDivElement, ICardFooterProps>(
	({ tag = 'div', className, children, size, borderSize, borderColor, ...props }, ref) => {
		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx(
					'card-footer',
					{
						[`card-footer-${size}`]: size,
						[`card-footer-border-${borderSize}`]: borderSize,
						[`card-footer-border-${borderColor}`]: borderColor,
					},
					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</TagWrapper>
		);
	},
);

interface ICardTabItemProps {
	id: string;
	title: string;
	icon?: IconProps['icon'];
	// In case of controlled component
	value?: string | number,
	children: ReactNode;
}
export const CardTabItem: FC<ICardTabItemProps> = ({ id, title, icon, children }) => {
	throw new Error(
		`Title ${title} component should be used as a child in the component Card.Id: ${id}, Icon Name: ${icon}, Children: ${children},`,
	);
};


export interface ICardProps extends HTMLAttributes<HTMLElement> {
	tag?: TagWrapperProps['tag']
	className?: string;
	children: React.ReactElement | React.ReactElement[];
	hasTab?: boolean;
	tabBsButtonColor?: ColorType;
	tabBsButtonColorInactive?: ColorType;
	tabBsButtonClassName?: string;
	tabBsButtonClassNameInactive?: string;
	tabBodyClassName?: string;
	tabWrapperClassName?: string;
	shadow?: CardShadowType;
	borderSize?: BorderSizeType;
	borderColor?: ColorType;
	stretch?: boolean | 'full' | 'semi' | null;
	isCompact?: boolean;
	defaultTab?: string | number;
	setActiveTab?: (value: string | number) => void,
	onSubmit?(...args: unknown[]): unknown;
	noValidate?: true;
}
const Card = forwardRef<HTMLDivElement, ICardProps>(
	(
		{
			tag = 'div',
			className,
			children,
			hasTab = false,
			tabBsButtonColor = 'primary',
			tabBsButtonColorInactive = 'light',
			tabBsButtonClassName,
			tabBsButtonClassNameInactive,
			tabWrapperClassName,
			tabBodyClassName,
			shadow,
			borderSize,
			borderColor,
			stretch,
			isCompact,
			defaultTab,
			setActiveTab,
			...props
		},
		ref,
	) => {
		const [innerActiveTab, setInnerActiveTab] = useState<string | number>(defaultTab || 0);

		const onClickHandler = (value: string | undefined, index: number) => {
			if (setActiveTab && value) {
				setActiveTab(value)
				setInnerActiveTab(value)
			} else {
				setInnerActiveTab(index)
			}
		}

		return (
			<TagWrapper
				ref={ref}
				tag={tag}
				className={clsx(
					'card',
					{
						[`card-stretch-${stretch === 'semi' ? 'semi' : 'full'}`]: stretch,
						'card-compact': isCompact,
						[`shadow${shadow !== 'md' ? `-${shadow}` : ''}`]:
							!!shadow && shadow !== '3d',
						[`u-shadow-3d--primary-sm`]: shadow === '3d',
						border: borderSize || borderSize === 0,
						[`border-${borderSize}`]: borderSize || borderSize === 0,
						[`border-${borderColor}`]: borderColor,
					},
					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{!hasTab ? (
					children
				) : (
					<>
						<CardHeader borderSize={1}>
							<CardActions className={tabWrapperClassName}>
								{Children.map(children, (item, index) => {
									const { id, icon, title, value } = item.props;
									const isActive = value ? innerActiveTab === value : innerActiveTab === index;

									return (
										<BsButton
											// @ts-ignore
											key={id}
											isLight
											color={isActive ? tabBsButtonColor : tabBsButtonColorInactive}
											role='tab'
											// @ts-ignore
											aria-controls={id}
											aria-selected={index === innerActiveTab}
											isActive={isActive}
											// @ts-ignore
											icon={icon || null}
											className={isActive ? tabBsButtonClassName : tabBsButtonClassNameInactive}
											onClick={() => onClickHandler(value, index)}>
											{/* @ts-ignore */}
											{title}
										</BsButton>
									)
								})}
							</CardActions>
						</CardHeader>
						{Children.map(children, (item, index) => {
							const isActive = item.props.value ? innerActiveTab === item.props.value : innerActiveTab === index;

							if (isActive) {
								return (
									<CardBody
										// @ts-ignore
										key={item.props.id}
										role='tabpanel'
										// @ts-ignore
										aria-labelledby={item.props.id}
										className={tabBodyClassName}>
										{/* @ts-ignore */}
										{item.props.children}
									</CardBody>
								);
							}
							return null;
						})}
					</>
				)}
			</TagWrapper>
		);
	},
);

export default Card;
