import AuthLayout from "../auth/AuthLayout";
import metadata from "../../commons/data/metadata";
import IntlMessage from "../../components/IntlMessage";
import PageContent from "../../components/layouts/PageContent";
import {LoadFlagIcons} from "../../components/forms/CountryPicker";

import config from "../../config";
import {globalT} from "../../lang";
import rpnClient from "../../network";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import Label from "../../components/forms/Label";
import useSettingsStore from "../../store/slices/settings";
import DeathDocument from "../../commons/models/DeathDocument";
import DragAndDrop, {FileItem} from "../../components/DragAndDrop";
import DeathDocumentType from "../../commons/models/DeathDocumentType";
import Accordion, {AccordionItem} from "../../components/bootstrap/Accordion";
import {setDeathDocumentTypes} from "../../store/slices/deathDocuments/actions";
import {getFilePath, globalLoadResource} from "../../commons/helpers/funcHelper";
import NotificationManager from "../../components/notifications/NotificationManager";
import {FetchResourceWatcher, RPNClientApiPaginatedResponse} from "../../network/network.types";

const UploadDeathDocuments = () => {

    const locale = useSettingsStore(s => s.locale)
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)
    const params = new URLSearchParams(window.location.search);
    const [reference, setReference] = useState<string|null>(null);

    const [filledDeathDocuments, setFilledDeathDocuments] = useState<DeathDocument[]>([]);
    const [deathDocumentsForm, setDeathDocumentsForm] = useState<Array<{
        deathDocumentType: DeathDocumentType,
        files: FileItem[]
    }>>([]);

    const loadDeathDocumentTypes = () => {
        setDeathDocumentTypes()
            .then((res: RPNClientApiPaginatedResponse<DeathDocumentType[]>) => {
                setDeathDocumentsForm(res.content.map(d => ({
                    deathDocumentType: d,
                    files: []
                })))
            })
            .catch(() => null)
    }

    const loadFilledDeathDocuments = (deathId: string) => {
        rpnClient.deaths.getPublicDocuments(deathId)
        .then((response) => {
            setFilledDeathDocuments(response.data);
        })
    }

    useEffect(() => {
        loadDeathDocumentTypes();
        if((params.get('reference')+"").length > 0) {
            setReference(params.get('reference'));
            loadFilledDeathDocuments(params.get('reference')+"");
            // loadDeathDocuments(params.get('reference')+"");
        }
    }, []);

    const isFilesValid = () => {
        if (deathDocumentsForm.filter(dd => dd.files.length > 0).length === 0) {
            NotificationManager.warning(globalT('community.death.document.update.required'))
            return false
        }

        return true
    }

    const onFilesSubmit = () => {
        if (!isFilesValid())
            return;

        setRequestGlobalLoader(true)
        Promise.all(deathDocumentsForm.filter(dd => dd.files.length > 0).map(dd => {
            return rpnClient
                .deaths
                .createOrUpdatePublicDocument({
                    deathReference: reference,
                    documentTypeReference: dd.deathDocumentType.id,
                    file: dd.files[0]
                }, ['file'])
        }))
        .then(() => {
            NotificationManager.success(globalT('community.death.document.update.success'));
            window.location.href = config.rpnAppUrl;
        })
        .catch((e) => console.log('onFilesSubmit e ', e))
        .finally(() => setRequestGlobalLoader(false))
    }

    return (
        <AuthLayout 
            titleString={metadata.dashboard.deaths.list.title} 
            title={(
                <h3 className='h3 text-center'>
                    <IntlMessage id={'community.death.documentTypes'} />
                </h3>
            )}
            paperClassName={"col-md-10 col-lg-7 py-15"}
        >
            <LoadFlagIcons />
            <PageContent>
                <div className='row'>
                    { deathDocumentsForm.length > 0 && filledDeathDocuments.length > 0 && (
                    <div className='row g-4'>
                        <div className="col-sm-12">
                            <Accordion id="death-documents" shadow='none'>
                                {deathDocumentsForm.filter(df => filledDeathDocuments?.map(c => c.documentType.id).includes(df.deathDocumentType.id)).map(deathDocument => {
                                    const oldDocument = filledDeathDocuments!.find(d => d.documentType.id === deathDocument.deathDocumentType.id)
                                    const color = oldDocument?.path && deathDocument.files[0] ? 'info'
                                        : oldDocument?.path ? 'primary'
                                            : 'warning'
                                    return (
                                        <AccordionItem
                                            id={deathDocument.deathDocumentType.id}
                                            key={deathDocument.deathDocumentType.id}
                                            title={(
                                                <span>
                                                    {deathDocument.deathDocumentType.getName(locale)}
                                                </span>
                                            )}
                                            overWriteColor={color}
                                            // iconColor={color}
                                            icon={
                                                oldDocument ? 'bx-check'
                                                    : 'bx-error'
                                            }
                                        >
                                            <div className="col-sm-12 col-md-12">
                                                <Label id={`${deathDocument.deathDocumentType.id}-label`} style={{ width: '100%' }}>
                                                    <span className="d-flex justify-content-between">
                                                        { oldDocument?.path && (
                                                            <a href={getFilePath(oldDocument?.path)} target='_blank'>
                                                                <IntlMessage id='community.death.list.column.specimen.link' />
                                                            </a>
                                                        )}
                                                    </span>
                                                </Label>
                                                <DragAndDrop
                                                    files={deathDocument.files}
                                                    setFiles={(files) => {
                                                        const arr = [ ...deathDocumentsForm ]
                                                        const inState = arr.find(d => d.deathDocumentType.id === deathDocument.deathDocumentType.id)
                                                        if (inState) {
                                                            inState.files = files
                                                        }
                                                        setDeathDocumentsForm(arr)
                                                    }}
                                                    maxFiles={1}
                                                />
                                            </div>
                                        </AccordionItem>
                                    )
                                })}
                            </Accordion>
                        </div>

                        <div className="col-sm-12">
                            {/* <p>
                                (<span className='bx bx-xs bxs-star text-danger' />): <IntlMessage id='community.death.document.required' />
                            </p> */}
                            <Button
                                onClick={onFilesSubmit}
                                color="secondary"
                                variant="contained"
                                className="fw-bold text-inherit me-3">
                                <IntlMessage id="button.save" />
                            </Button>
                        </div>

                    </div>
                )}
                </div>
            </PageContent>
        </AuthLayout>
    );
};

export default UploadDeathDocuments;
