import * as yup from 'yup';
import React, { useEffect } from 'react';
import {NavLink} from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {yupResolver} from "@hookform/resolvers/yup";
import PersonIcon from '@mui/icons-material/Person';
import {SubmitHandler, useForm} from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";

import {globalT} from "../../../lang";
import AuthLayout from "../AuthLayout";
import rpnClient from "../../../network";
import {AUTH, CONDITIONS} from "../../../commons/urls/front";
import IntlMessage from "../../../components/IntlMessage";
import {setJwtData} from "../../../commons/helpers/jwtHelper";
import useSettingsStore from "../../../store/slices/settings";
import * as yupSchema from '../../../components/forms/yupShema'
import {setAuthUser} from "../../../store/slices/authUser/actions";
import InputPassword from "../../../components/forms/InputPassword";
import InputComponent from "../../../components/forms/InputComponent";
import NotificationManager from "../../../components/notifications/NotificationManager";
import { AxiosError } from 'axios';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    login: yupSchema.name,
});

type FormType = {
    login?: string
    password?: string
}

const Login = () => {
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader);
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        if(params.get('emailVerified')?.toString().toLowerCase() === 'true') {
            NotificationManager.success(globalT("emailVerified"))
        }
    }, []);

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormType> = (data) => {
        setRequestGlobalLoader(true)
        rpnClient
            .auth
            .login({ login: data.login as string, password: data.password as string })
            .then((res) => {
                setJwtData(res.data.token)

                setAuthUser()
                    .then(() => setRequestGlobalLoader(false))
                    .catch(() => null)
            })
            .catch((err: AxiosError) => {
                setRequestGlobalLoader(false)
                if (err.response && err.response.status) {
                    if (err.response.status === 412) {
                        NotificationManager.error(globalT("auth.login.error.unverifiedEmail"))
                    } else if (err.response.status === 423) {
                        NotificationManager.error(globalT("auth.login.error.associationBlocked"))
                    } else {
                        NotificationManager.error(globalT("auth.login.error"))
                    }
                } else {
                    NotificationManager.error(globalT("auth.login.error"))
                }
            });
    }

    return (
        <AuthLayout
            titleString='auth.login'
            title={(
                <h3 className='h3 text-center'>
                    <IntlMessage id='auth.login' />
                </h3>
            )}
            paperClassName="col-md-10 col-lg-7 py-15 text-center">
            <form onSubmit={handleSubmit(onSubmit)} className="">
                <div className="row g-4 justify-content-center">
                    <div className="col-sm-12">
                        <InputComponent
                            name="login"
                            errors={errors}
                            control={control}
                            noPlaceholder={false}
                            label={<IntlMessage id="form.field.email" />}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <PersonIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>

                    <div className="col-sm-12">
                        <InputPassword
                            name="password"
                            errors={errors}
                            control={control}
                            noPlaceholder={false}
                            label={<IntlMessage id="form.field.password" />}
                        />
                    </div>

                    <div className="col-md-12 mt-2 text-end">
                        <NavLink to={AUTH.FORGOT_PASSWORD.INDEX} className='a-tag text-end'>
                            <IntlMessage id='auth.forgotPassword.question' />
                        </NavLink>
                    </div>

                    <div className="col-md-12 mt-2">
                        <Button
                            fullWidth
                            type='submit'
                            color='primary'
                            variant='contained'
                            className='text-inherit'>
                            <IntlMessage id='auth.login.btn' />
                        </Button>
                    </div>

                    <div className="col-md-12 mt-3">
                        <Typography>
                            <IntlMessage
                                id='auth.login.text.signup'
                                values={{
                                    a: (chunks: string) => (
                                        <NavLink to={AUTH.REGISTER.INDEX} className='a-tag text-end'>
                                            {chunks}
                                        </NavLink>
                                    )
                                }}
                            />
                        </Typography>
                    </div>
                    <p className='p-tag mb-0'>
                        <IntlMessage
                            id='login.acceptConvention'
                            values={{
                                a: (chunks: React.ReactNode) => (
                                    <a className='a-tag' href={CONDITIONS.CGU} target="_blank">
                                        {chunks}
                                    </a>
                                ),
                                a2: (chunks: React.ReactNode) => (
                                    <a className='a-tag' href={CONDITIONS.CONVENTION} target="_blank">
                                        {chunks}
                                    </a>
                                )
                            }}
                        />
                    </p>
                </div>
            </form>
        </AuthLayout>
    );
};

export default Login;
