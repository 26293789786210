
export default class CommunityLight {
    public id: string
    public name: string

    constructor(data: any) {
        this.id = data.reference || data.id
        this.name = data.name
    }
}
