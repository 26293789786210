import {Enumify} from "./Enumify";
import {formatRelationTypeToForm} from "../helpers/businessHelper";

export default class RelationTypeEnum extends Enumify<string> {
    static readonly parent = new RelationTypeEnum('PARENT');
    static readonly grand_parent = new RelationTypeEnum('GRAND_PARENT');
    static readonly uncle_aunt = new RelationTypeEnum('UNCLE_AUNT');
    static readonly cousin_cousine = new RelationTypeEnum('COUSIN_COUSINE');
    static readonly nephew_niece = new RelationTypeEnum('NEPHEW_NIECE');
    static readonly son_daughter = new RelationTypeEnum('SON_DAUGHTER');
    static readonly brother_sister = new RelationTypeEnum('BROTHER_SISTER');
    static readonly husband_wife = new RelationTypeEnum('HUSBAND_WIFE');
    static readonly friend = new RelationTypeEnum('FRIEND');
    static readonly myself = new RelationTypeEnum('MYSELF');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatRelationTypeToForm(this)
    }
}
