import React from 'react';
import * as yup from "yup";
import {AxiosError} from "axios";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";

import {globalT} from "../../../../lang";
import rpnClient from "../../../../network";
import IntlMessage from "../../../../components/IntlMessage";
import useSettingsStore from "../../../../store/slices/settings";
import * as yupSchema from "../../../../components/forms/yupShema";
import CountryPicker from "../../../../components/forms/CountryPicker";
import InputComponent from "../../../../components/forms/InputComponent";
import NotificationManager from "../../../../components/notifications/NotificationManager";
import {RegistrationManager as RegistrationManagerModel} from "../../../../network/network.types";
import CountryManager, {CountryLight} from "../../../../commons/helpers/CountryManager";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    name: yupSchema.globalname,
    acronym: yupSchema.acronym,
    city: yupSchema.username,
    address: yupSchema.address,
    postalCode: yupSchema.postalCode,
    phoneNumber: yupSchema.telephone,
    email: yupSchema.email,
    region: yupSchema.region,
    country: yupSchema.country,
});

type FormType = {
    name?: string
    acronym?: string
    country?: CountryLight
    city?: string
    region?: {label: string, value: string}
    address?: string
    postalCode?: string
    phoneNumber?: string
    email?: string
}

type RegistrationCommunityProps = {
    manager: RegistrationManagerModel
    onGoToPrevious: () => void
    onGoToNext: (community: Required<RegistrationManagerModel['community']>, action: 'create' | 'update') => void
}

const RegistrationCommunity = ({ manager, onGoToPrevious, onGoToNext }: RegistrationCommunityProps) => {
    const locale = useSettingsStore(s => s.locale)
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)

    const {
        control,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        defaultValues: {
            name: manager.community?.name,
            acronym: manager.community?.acronym,
            country: CountryManager.getCountryAlpha2CodeWithFlagMap(manager.community?.alpha2Code || 'CA', locale)
                || CountryManager.getCountryAlpha2CodeWithFlagMap(manager.alpha2Code, locale)
                || undefined,
            city: manager.community?.city,
            region: rpnClient.staticTypes.canadaRegions.find(r => r.value == manager.community?.region) || undefined,
            address: manager.community?.address,
            postalCode: manager.community?.postalCode,
            phoneNumber: manager.community?.phoneNumber || manager.phoneNumber,
            email: manager.community?.email || manager.email,
        },
        resolver: yupResolver(schema),
    });

    const isFormValid = (data: FormType) => {
        let isValid = true
        if ( !data.country ) {
            NotificationManager.warning(globalT("form.errors.country"))
            isValid = false
        }

        return isValid
    }

    const onSubmit: SubmitHandler<FormType> = (data) => {
        if (!isFormValid(data))
            return

        const _data = {
            name: data.name,
            acronym: data.acronym,
            country: data.country?.alpha2Code,
            city: data.city,
            address: data.address,
            postalCode: data.postalCode,
            telephone: data.phoneNumber,
            email: data.email,
            region: data.region?.value,
            managerReference: manager.id,
        }

        if (manager.community?.reference)
            // @ts-ignore
            _data.reference = manager.community?.reference

        // console.log(_data);

        setRequestGlobalLoader(true)
        rpnClient
            .auth
            .register
            .communities
            .create(_data)
            .then((res) => {
                onGoToNext(res.data, manager.community ? 'update' : 'create')
            })
            .catch((err: AxiosError) => {
                if (err.response && err.response.status) {
                    if (err.response.status === 409) {
                        NotificationManager.error(globalT("form.errors.community.name"))
                    }
                }
            })
            .finally(() => setRequestGlobalLoader(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="container mt-2">
            <div className="row g-4">
                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="name"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        label={<IntlMessage id="community.name" />}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="acronym"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        label={<IntlMessage id="form.field.acronym" />}
                    />
                </div>

                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="phoneNumber"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        label={<IntlMessage id="form.field.phoneNumber" />}
                    />
                </div>

                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="email"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        label={<IntlMessage id="form.field.email" />}
                    />
                </div>

                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="address"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        isRequired={false}
                        label={<IntlMessage id="form.field.address" />}
                    />
                </div>

                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="city"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        label={<IntlMessage id="form.field.city" />}
                    />
                </div>

                <div className="col-sm-12 col-md-4">
                    <InputComponent
                        name="postalCode"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        isRequired={false}
                        label={<IntlMessage id="form.field.postalCode" />}
                    />
                </div>

                <div className="col-sm-12 col-md-4">
                    <InputComponent
                        setValue={setValue}
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        name="region"
                        componentType="select"
                        options={rpnClient.staticTypes.canadaRegions}
                        label={<IntlMessage id="form.field.location.region" />}
                    />
                </div>

                <div className="col-sm-12 col-md-4">
                    <CountryPicker
                        isClearable
                        isSearchable
                        name="country"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        label={<IntlMessage id="form.field.country" />}
                        placeholder={<IntlMessage id="form.field.country" />}
                    />
                </div>

                <div className="col-sm-12">
                    <div className="center-ver justify-content-between">
                        <Button
                            size='small'
                            type='button'
                            color='secondary'
                            variant='outlined'
                            onClick={() => onGoToPrevious()}
                            className='text-inherit px-3'>
                            <IntlMessage id='button.prev' />
                        </Button>
                        <Button
                            size='small'
                            type='submit'
                            color='secondary'
                            variant='contained'
                            endIcon={<SendIcon />}
                            className='text-inherit px-3'>
                            <IntlMessage id='button.next' />
                        </Button>
                    </div>
                </div>

            </div>
        </form>
    );
};

export default RegistrationCommunity;
