import {Enumify} from "./Enumify";
import {formatContributionStatusToForm} from "../helpers/businessHelper";

export default class ContributionStatusEnum extends Enumify<string> {
    static readonly paid = new ContributionStatusEnum('PAID');
    static readonly unpaid = new ContributionStatusEnum('UNPAID');
    static readonly processing = new ContributionStatusEnum('PROCESSING');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatContributionStatusToForm(this)
    }

    get statusMap() {
        switch (this) {
            case ContributionStatusEnum.paid:
                return { color: 'primary' }
            case ContributionStatusEnum.processing:
                return { color: 'warning' }
            case ContributionStatusEnum.unpaid:
            default:
                return { color: 'danger' }
        }
    }
}
