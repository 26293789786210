import React, {ReactHTML} from 'react';

export interface TagWrapperProps extends React.HTMLAttributes<any> {
    tag: keyof ReactHTML,
    children: React.ReactNode
}

const TagWrapper = React.forwardRef<HTMLDivElement | HTMLAnchorElement | HTMLFormElement | React.ReactElement, TagWrapperProps>(({ tag: Tag, children, ...props }, ref) => {
    return (
        // @ts-ignore
        <Tag ref={ref} {...props}>
            {children}
        </Tag>
    );
});

TagWrapper.defaultProps = {
    tag: 'div',
    children: null,
};

export default TagWrapper;
