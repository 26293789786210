import React, {forwardRef, HTMLAttributes} from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import TagWrapper from './TagWrapper';
import {ColorTypeExtended} from "../components.types";

export interface ButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    isToolbar?: boolean,
    isVertical?: boolean,
    size?: 'sm' | 'lg',
    ariaLabel?: string
}

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
    ({ children, className, isToolbar, isVertical, size, ariaLabel, ...props }, ref) => {
        const _prefix = isToolbar ? 'toolbar' : 'group';
        return (
            <div
                ref={ref}
                className={clsx(
                    {
                        [`btn-${_prefix}`]: !isVertical,
                        'btn-group-vertical': isVertical && _prefix === 'group',
                        [`btn-group-${size}`]: size,
                    },
                    className,
                )}
                role={_prefix}
                aria-label={ariaLabel}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}>
                {children}
            </div>
        );
    },
);

export interface BsButtonProps extends React.HTMLAttributes<any> {
    /**
     * Button HTML tag
     */
    tag?: 'button' | 'a' | 'input' | 'link'
    /**
     * Change button type attribute
     */
    type?: 'button' | 'submit' | 'reset',
    to?: string,
    href?: string,
    isActive?: boolean,
    /**
     * Theme colors
     */
    color?: ColorTypeExtended | 'storybook',
    /**
     * if isOutline is true isLight and isLink must be false
     */
    isOutline?: boolean,
    /**
     * if isLight is true isOutline and isLink must be false
     */
    isLight?: boolean,
    /**
     * if isLink is true isOutline and isLight must be false
     */
    isLink?: boolean,
    /**
     * Whether to use internal icon or not
     */
    customIcon?: React.ReactElement,
    icon?: string,
    iconProps?: Partial<HTMLAttributes<HTMLSpanElement>>
    iconPosition?: 'left' | 'right',
    iconSize?: 'xl' | 'x-sm' | 'sm' | 'md' | 'lg',
    resetIconClsx?: boolean,
    rounded?: 'default' | 0 | 1 | 2 | 3 | 'bottom' | 'top' | 'circle' | 'end' | 'start' | 'pill',
    size?: 'sm' | 'lg',
    /**
     * Disabled buttons have ***pointer-events: none*** applied to, preventing hover and active states from triggering.
     */
    isDisable?: boolean,
    shadow?: 'none' | 'sm' | 'default' | 'lg',
    hoverShadow?: 'none' | 'sm' | 'default' | 'lg',
    /**
     * If tag is "a", the target attribute specifies where to open the linked document.
     */
    target?: '_blank' | '_self' | '_parent' | '_top',
    isVisuallyHidden?: boolean
}

const _anchorLinkPattern = /^#/i;

const BsButton = forwardRef<HTMLElement, BsButtonProps>(
    (
        {
            children,
            tag = 'button',
            type = 'button',
            to,
            href,
            isActive,
            color,
            isOutline,
            isLight,
            isLink,
            className,
            resetIconClsx,
            customIcon,
            icon,
            iconPosition = 'left',
            iconSize = 'x-sm',
            iconProps,
            rounded,
            size,
            isDisable,
            shadow,
            hoverShadow,
            target,
            isVisuallyHidden,
            ...props
        },
        ref,
    ) => {
        const _btnClass = clsx(
            'btn',
            {
                [`btn-${isOutline || isLink ? `outline-${color}` : color}`]:
                (color && !isLight) || (color && isLink),
                'border-transparent': isLink,
                [`btn-${size}`]: size,
                [`border-${color}`]: isLight,
                [`btn-hover-shadow${hoverShadow !== 'default' ? `-${hoverShadow}` : ''}`]:
                hoverShadow,
                [`btn-light-${color}`]: isLight,
                [`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
                [`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
                'rounded-0':
                    rounded === 'bottom' ||
                    rounded === 'top' ||
                    rounded === 'end' ||
                    rounded === 'start' ||
                    rounded === 0,
                'btn-only-icon': !children || isVisuallyHidden,
                disabled: isDisable,
                active: isActive,
                // 'center-hor-ver': icon && !customIcon && !resetIconClsx
            },
            className,
        );

        const _Icon = (
            <>
                {icon && (
                    <>
                        {customIcon ? (
                            {customIcon}
                        ) : ( // @ts-ignore
                            <i className={clsx({ [`bx bx-${iconSize} vertical-align-middle ${iconPosition === 'left' ? 'pe-1' : 'ps-1'}`]: !resetIconClsx }, icon)} {...iconProps} />
                        )}
                    </>
                )}
            </>
        )

        const _Inner = (
            <>
                {iconPosition === 'left' && _Icon}
                {isVisuallyHidden ? (
                    <span className='visually-hidden'>Toggle Dropdown</span>
                ) : (
                    children
                )}
                {iconPosition === 'right' && _Icon}
            </>
        );

        const disableProps = isDisable && {
            tabIndex: '-1',
            'aria-disabled': 'true',
            disabled: true,
        };

        if (tag === 'a') {
            if (to && _anchorLinkPattern.test(to)) {
                return (
                    <HashLink
                        // @ts-ignore
                        ref={ref}
                        className={_btnClass}
                        to={to}
                        {...disableProps}
                        {...props}>
                        {_Inner}
                    </HashLink>
                );
            }
            if (to) {
                return (
                    <Link
                        // @ts-ignore
                        ref={ref}
                        className={_btnClass}
                        to={to}
                        rel='noopener'
                        target={target}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...disableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}>
                        {_Inner}
                    </Link>
                );
            }
            return (
                <a
                    // @ts-ignore
                    ref={ref}
                    className={_btnClass}
                    href={href}
                    role='button'
                    rel='noopener'
                    target={target}
                    {...disableProps}
                    {...props}>
                    {_Inner}
                </a>
            );
        }

        return (
            <TagWrapper
                // @ts-ignore
                ref={ref}
                tag={tag}
                type={type}
                className={_btnClass}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...disableProps}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}>
                {_Inner}
            </TagWrapper>
        );
    },
);

export default BsButton;
