import clsx from 'clsx'
import { useWindowSize } from 'react-use'
import { NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import CircleIcon from "@mui/icons-material/Circle"
import { Manager, Popper, Reference } from 'react-popper'
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
// @ts-ignore
import useEventOutside from '@omtanke/react-use-event-outside'
import React, { useCallback, useContext, useRef, useState } from 'react'

import { List } from './Navigation'
import config from "../../../config"
import { globalT } from '../../../lang'
import Collapse from '../../../components/bootstrap/Collapse'
import ThemeContext from '../../../commons/contexts/ThemeContext'
import useCurrentPath from '../../../commons/hooks/useCurrentPath'

interface NavigationItemProps {
	children?: React.ReactNode
	to?: string
	title?: string
	icon?: React.ReactElement
	id?: string
	parentId?: string
	rootId: string
	notification?: boolean | string
	isHorizontal?: boolean | string
	isMore?: boolean
	hide?: boolean
	activeItem?: string
	setActiveItem?(...args: unknown[]): unknown
}

const NavigationItem: React.FC<NavigationItemProps> = ({
														   children,
														   to,
														   title,
														   icon,
														   id,
														   parentId,
														   rootId,
														   isHorizontal = false,
														   notification = false,
														   isMore = false,
														   hide = false,
														   ...props
													   }) => {
	const currentPath = useCurrentPath()
	const { width } = useWindowSize()
	const { setAsideStatus } = useContext(ThemeContext)

	// eslint-disable-next-line react/prop-types
	const ACTIVE = props.activeItem === id

	const handleClick = () => {
		if (typeof props.setActiveItem !== 'undefined') {
			// eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-expressions
			ACTIVE ? props.setActiveItem(null) : props.setActiveItem(id)
		}
	}

	const linkHandleClick = () => {
		// For Mobile Design
		if (width < Number(config.ui.mobileBreakpointSize))
			setAsideStatus(false)
	}

	const ANCHOR_LINK_PATTERN = /^#/i

	// For aside menu
	const here = to === currentPath

	const LINK_CLASS = clsx('navigation-link a-mui', 'navigation-link-pill', {
		collapsed: !!children && !isHorizontal,
		active: here,
	})

	const INNER = (
		<>
			<span className='navigation-link-info'>
				{icon && <span className='navigation-icon'>{icon}</span>}
				{title && <span className='navigation-text'>{globalT(title)}</span>}
			</span>
			{(!!children || !!notification) && (
				<span className='navigation-link-extra'>
					{!!notification && (
						<CircleIcon
							className={clsx(
								'navigation-notification',
								{
									[`text-${notification}`]: typeof notification === 'string',
									'text-danger': typeof notification !== 'string',
								},
								'animate__animated animate__heartBeat animate__infinite animate__slower',
							)}
						/>
					)}
					{!!children && <ChevronRightIcon className='navigation-arrow' />}
				</span>
			)}
		</>
	)

	const WITHOUT_CHILD =
		!children &&
		!hide &&
		((typeof to === 'string' && ANCHOR_LINK_PATTERN.test(to) && (
			<NavHashLink className={LINK_CLASS} to={to} onClick={linkHandleClick}>
				{INNER}
			</NavHashLink>
		)) || (
			<NavLink
				end
				// @ts-ignore
				className={clsx(LINK_CLASS, ({ isActive }) => (isActive ? 'active' : ''))}
				to={`../${to}`}
				onClick={linkHandleClick}>
				{INNER}
			</NavLink>
		))

	// Dropdown
	const dropdownRef = useRef(null)

	const dropdownButtonRef = useRef(null)
	const setButtonRef = useCallback((node: null, ref: (arg0: any) => any) => {
		dropdownButtonRef.current = node
		return ref(node)
	}, [])

	const dropdownListRef = useRef(null)
	const setListRef = useCallback((node: null, ref: (arg0: any) => any) => {
		dropdownListRef.current = node
		return ref(node)
	}, [])

	const [dropdownStatus, setDropdownStatus] = useState(false)

	const dropdownButtonHandleClick = () => {
		setDropdownStatus(!dropdownStatus)
	}

	// Clicking outside to close
	const closeMenu = useCallback(() => {
		setDropdownStatus(false)
	}, [])
	useEventOutside(dropdownRef, 'mousedown', closeMenu)
	useEventOutside(dropdownRef, 'touchstart', closeMenu)

	if (children) {
		// submenu && in header
		if (isHorizontal) {
			return (
				<Manager>
					<li
						ref={dropdownRef}
						className={clsx('navigation-item', 'dropdown', {
							'navigation-item-more': isMore,
						})}>
						<Reference>
							{({ ref }) => (
								<span
									// @ts-ignore
									ref={(node) => setButtonRef(node, ref)}
									id={`${rootId}__${id}--link`}
									className={LINK_CLASS}
									// data-bs-toggle='dropdown'
									// data-bs-target={`#${rootId}__${id}`}
									aria-expanded={dropdownStatus}
									aria-controls={`${rootId}__${id}`}
									role='button'
									tabIndex={-1}
									onClick={dropdownButtonHandleClick}
									onKeyDown={dropdownButtonHandleClick}>
									{INNER}
								</span>
							)}
						</Reference>
						{dropdownStatus && (
							<Popper
								placement='bottom-start'
								modifiers={[
									{
										name: 'flip',
										options: {
											fallbackPlacements: [`bottom-end`, `bottom-start`],
										},
									},
								]}>
								{({ ref, style, placement }) => (
									<List
										// @ts-ignore
										ref={(node) => setListRef(node, ref)}
										style={style}
										data-placement={placement}
										id={`${rootId}__${id}`}
										className={clsx(
											'dropdown-menu',
											'show',
										)}
										ariaLabelledby={`${rootId}__${id}--link`}
										rootId={rootId}
										parentId={`${rootId}__${parentId}`}
										onMouseLeave={() => setDropdownStatus(false)}>
										{children}
									</List>
								)}
							</Popper>
						)}
					</li>
				</Manager>
			)
		}
		// submenu && in aside
		return (
			<li className='navigation-item'>
				<span
					id={`${rootId}__${id}--link`}
					className={LINK_CLASS}
					// data-bs-toggle='collapse'
					// data-bs-target={`#${rootId}__${id}`}
					aria-expanded={ACTIVE}
					aria-controls={`${rootId}__${id}`}
					role='button'
					tabIndex={-1}
					onClick={handleClick}
					onKeyDown={handleClick}>
					{INNER}
				</span>
				<Collapse isOpen={ACTIVE} isChildClone>
					<List
						id={`${rootId}__${id}`}
						ariaLabelledby={`${rootId}__${id}--link`}
						rootId={rootId}
						parentId={`${rootId}__${parentId}`}
						onMouseLeave={closeMenu}>
						{children}
					</List>
				</Collapse>
			</li>
		)
	}
	// without submenu
	return <li className='navigation-item'>{WITHOUT_CHILD}</li>
}

export default React.memo(NavigationItem)
