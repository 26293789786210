import React from 'react';
import * as yup from "yup";
import {AxiosError} from "axios";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";

import {globalT} from "../../../lang";
import AuthLayout from "../AuthLayout";
import rpnClient from "../../../network";
import {AUTH} from "../../../commons/urls/front";
import {NavLink, useNavigate} from "react-router-dom";
import IntlMessage from "../../../components/IntlMessage";
import useSettingsStore from "../../../store/slices/settings";
import * as yupSchema from "../../../components/forms/yupShema";
import {isReference} from "../../../commons/helpers/funcHelper";
import InputPassword from "../../../components/forms/InputPassword";
import {getAllQueryParams} from "../../../commons/helpers/urlHelpers";
import NotificationManager from "../../../components/notifications/NotificationManager";


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    // login: yupSchema.name,
    password: yupSchema.password,
    confirmPassword: yupSchema.password,
});

type FormType = {
    // login?: string
    password?: string
    confirmPassword?: string
}

const SetCredentials = () => {
    const navigate = useNavigate()
    let reference = getAllQueryParams<{ reference: string }>().reference
    reference = isReference(reference) ? reference : undefined

    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)

    const {
        watch,
        setError,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const isFormValid = (data: FormType) => {
        if (data.password !== data.confirmPassword) {
            setError("confirmPassword", { type: "custom", message: globalT('form.errors.password.confirmation') }, { shouldFocus: true });
            return false
        }

        return true
    }

    const onSubmit: SubmitHandler<FormType> = (data) => {
        if (!isFormValid(data))
            return

        setRequestGlobalLoader(true)
        rpnClient
            .auth
            .loginSetCredentials({
                login: "login" as string,
                password: data.password as string,
            }, reference!)
            .then(() => {
                setRequestGlobalLoader(false)
                NotificationManager.success(globalT("auth.login.credentials.success.message"))
                navigate(AUTH.LOGIN)
            })
            .catch((err: AxiosError) => {
                setRequestGlobalLoader(false)
                if (err.response && err.response.status) {
                    if (err.response.status === 404) {
                        NotificationManager.error(globalT("auth.login.credentials.reference.notFound"))
                    }
                    if (err.response.status === 409) {
                        NotificationManager.error(globalT("form.errors.login.unique"))
                    }
                }
            })
    }

    return (
        <AuthLayout
            titleString='auth.login.credentials.title'
            title={(
                <h3 className='h3 text-center'>
                    <IntlMessage id='auth.login.credentials.title' />
                </h3>
            )}
            paperClassName="col-md-10 col-lg-7 py-15 text-center">
            {!reference ? (
                <Alert severity="warning">
                    <IntlMessage id='auth.login.credentials.reference.notFound' />
                </Alert>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} className="">
                    <div className="row g-4 justify-content-center">
                        {/* <div className="col-sm-12">
                            <InputComponent
                                size='medium'
                                name="login"
                                errors={errors}
                                control={control}
                                noPlaceholder={false}
                                label={<IntlMessage id="form.field.login" />}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <PersonIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div> */}

                        <div className="col-sm-12">
                            <InputPassword
                                size='medium'
                                name="password"
                                errors={errors}
                                control={control}
                                noPlaceholder={false}
                                label={<IntlMessage id="form.field.password" />}
                            />
                        </div>

                        <div className="col-sm-12">
                            <InputPassword
                                size='medium'
                                errors={errors}
                                control={control}
                                noPlaceholder={false}
                                name="confirmPassword"
                                label={<IntlMessage id="form.field.password.confirmation" />}
                                otherValidators={{
                                    validate: (val: string | undefined) => (watch('password') === val) || (
                                        <IntlMessage id='form.errors.password.confirmation' />
                                    )
                                }}
                            />
                        </div>

                        <div className="col-md-12">
                            <Button
                                fullWidth
                                type='submit'
                                color='secondary'
                                variant='outlined'
                                startIcon={<SaveIcon />}
                                className='text-inherit'>
                                <IntlMessage id='button.save' />
                            </Button>
                        </div>

                        <div className="col-md-12 mt-3">
                            <Typography>
                                <IntlMessage
                                    id='auth.login.text.signup'
                                    values={{
                                        a: (chunks: string) => (
                                            <NavLink to={AUTH.REGISTER.INDEX} className='a-tag text-end text-secondary'>
                                                {chunks}
                                            </NavLink>
                                        )
                                    }}
                                />
                            </Typography>
                        </div>
                    </div>
                </form>
            )}
        </AuthLayout>
    );
};

export default SetCredentials;
