import _ from 'lodash';
import { AbilityBuilder, createMongoAbility, PureAbility } from '@casl/ability';

import Permissions from './index';
import useAuthUserStore from "../../store/slices/authUser";

export function defineRulesFor(allPermissionNames: string[]) {
	const { can, rules } = new AbilityBuilder(createMongoAbility);

	// Enable each permission
	allPermissionNames.forEach((permissionName) => {
		can(permissionName, Permissions);
	});
	return rules;
}

const ability = new PureAbility([]);

/*--------------------------------------------------------------
 * The following script sets permissions for authenticated user
 * So we listen for authUser state and we update permissions accordingly
 */
useAuthUserStore.subscribe((state, prevState) => {
	// Check if it's a new user
	if (!_.isEqual(prevState.content, state.content)) {
		// Update its permissions
		if (state.content === null) // @ts-ignore
			ability.update(defineRulesFor([]));
		else // @ts-ignore
			ability.update(defineRulesFor(state.content.getAllPermissionCodes()));
	}
})

export default ability;
