import dayjs, {Dayjs} from "dayjs";
import TicketType from "./TicketType";
import TicketStatusEnum from "../enums/TicketStatusEnum";

export default class Ticket {
    public id: string
    public displayId: number
    public title: string
    public author: string
    public content: string
    public file?: string
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public type?: TicketType
    public owner?: boolean
    public status: TicketStatusEnum

    constructor(data: any) {
        this.id = data.reference || data.id
        this.displayId = data.id
        this.title = data.title
        this.content = data.content
        this.file = data.file
        this.owner = data.owner
        this.author = data.name
        this.status = TicketStatusEnum.tryParse(data.status)
        this.type = new TicketType(data.ticketType)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getStatusMap = () => {
        switch (this.status) {
            case TicketStatusEnum.pending:
                return { color: 'warning' }
            case TicketStatusEnum.treated:
                return { color: 'primary' }
            case TicketStatusEnum.cancelled:
                return { color: 'danger' }
            default:
                return { color: 'danger' }
        }
    }
}
