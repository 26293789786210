import React from 'react';
import Helmet from 'react-helmet';
import useSettingsStore from "../../store/slices/settings";
import InputComponent, {InputComponentProps} from "./InputComponent";
import CountryManager, {CountryLight} from "../../commons/helpers/CountryManager";

export type CountryPickerProps = InputComponentProps & {
    alpha?: 2 | 3
}

export const LoadFlagIcons = () => (
    <Helmet>
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
        />
    </Helmet>
)

export const FormatCountryLight = (option: CountryLight) => (
    <span>
        <i className={`fi fi-${option.alpha2Code.toLowerCase()}`} />
        &nbsp;
        {option.translation}
    </span>
)

const CountryPicker = ({ alpha = 2, ...restProps }: CountryPickerProps) => {
    const locale = useSettingsStore(state => state.locale);

    return (
        <>
            <LoadFlagIcons />
            <InputComponent
                componentType='select'
                options={CountryManager.getCountriesWithFlagMap(locale)}
                // @ts-ignore
                getOptionValue={(option: CountryLight) => option[`alpha${alpha}Code`]}
                getOptionLabel={(option: CountryLight) => option.translation}
                formatOptionLabel={FormatCountryLight}
                {...restProps}
            />
        </>
    );
};

export default CountryPicker;
