import {Enumify} from "./Enumify";
import {formatMemberTypeToForm} from "../helpers/businessHelper";

export default class MemberTypeEnum extends Enumify<string> {
    static readonly visitor = new MemberTypeEnum('VISITOR');
    static readonly resident = new MemberTypeEnum('RESIDENT');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatMemberTypeToForm(this)
    }
}
