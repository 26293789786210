import dayjs, {Dayjs} from "dayjs"
import Role from "./Role"
import Permission from "./Permission"
import Community from "./Community";

export default class AuthUser {
    public id: string
    public reference: string
    public login: string
    public email: string
    public language: string
    public user: {
        avatar: string
        firstName: string
        lastName: string
        address: string
        postalCode: string
        city: string
        country: string
        birthDate: string | null
        telephone: string
        idType: string
        idNumber: string
    }
    public community: Community | null
    public role: Role
    public permissions: Permission[]
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.id
        this.reference = data.reference
        this.login = data.login
        this.email = data.email
        this.language = data.language
        this.user = {
            avatar: data.avatar,
            firstName: data.firstName,
            lastName: data.lastName,
            address: data.address,
            postalCode: data.postalCode,
            city: data.city,
            country: data.country,
            birthDate: data.birthDate,
            telephone: data.telephone,
            idType: data.idType,
            idNumber: data.idNumber,
        }
        this.community = data.community ? new Community(data.community) : null
        this.role = new Role(data.role)
        /*const p = [
            {
                "code": "role-management",
                "reference": "9793e5dd-c886-f067-7357-6832d2af0f32",
                "fr": "Gestion des rôles",
                "en": "Roles management"
            },
            {
                "code": "setting-management",
                "reference": "2e3c5e2e-9c09-acb7-9ca9-192d7c115e77",
                "fr": "Gestion des paramètres",
                "en": "Settings management"
            },
            {
                "code": "pending-communities-management",
                "reference": "fbb57c87-3e0e-6bdd-8e36-d9e8f4fab3d4",
                "fr": "Gestion des communautés en attente",
                "en": "Pending communities management"
            },
            {
                "code": "communities-management",
                "reference": "76b1ef79-fec0-e3d4-de14-bcf12a0122ca",
                "fr": "Gestion des communautés",
                "en": "Communities management"
            },
            {
                "code": "managers-management",
                "reference": "ca7e39d8-3f05-40c0-471e-2f777df89387",
                "fr": "Gestion des administrateurs",
                "en": "Managers management"
            }
        ]*/
        // this.permissions = (p || data.role.permissions).map((p: unknown) => new Permission(p))
        this.permissions = data.role.permissions.map((p: unknown) => new Permission(p))
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getAllPermissionCodes = () => {
        return this.permissions.map(p => p.code)
    }

    get fullName() {
        return `${this.user.firstName} ${this.user.lastName}`
    }
}
