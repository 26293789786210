import dayjs, {Dayjs} from "dayjs";
import DocumentTypeEnum from "../enums/DocumentTypeEnum";
import {LanguageDict} from "../types";

export default class Document {
    public id: string
    public displayId: number
    public available: boolean
    public file: string
    public nameEn: boolean
    public nameFr: boolean
    public type: DocumentTypeEnum
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.displayId = data.id
        this.available = data.available
        this.file = data.file
        this.nameEn = data.nameEn
        this.nameFr = data.nameFr
        this.type = DocumentTypeEnum.tryParse(data.type)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
