import Member from "./Member";
import dayjs, {Dayjs} from "dayjs";
import DeathMemberStatusEnum from "../enums/DeathMemberStatusEnum";
import CountryManager, {CountryLight} from "../helpers/CountryManager";

export default class DeathRequest {
    public id: string
    public photo: string
    public status: DeathMemberStatusEnum
    public member: Member
    public country: CountryLight | null
    public city: string
    public contactName: string
    public contactEmail: string
    public contactPhone: string
    public contactCity: string
    public contactAddress: string
    public contactRegion: string
    public contactPostalCode: string
    public contactCountry: CountryLight | null
    public address: string
    public postalCode: string
    public locationName: string
    public region: string
    public deathAt: Dayjs
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public approvalDate: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.photo = data.photo
        this.member = new Member(data.member)
        this.status = DeathMemberStatusEnum.tryParse(data.status)
        this.country = data.country ? CountryManager.getCountryAlpha2CodeWithFlagMap(data.country) : null
        this.contactCountry = data.contactCountry ? CountryManager.getCountryAlpha2CodeWithFlagMap(data.contactCountry) : null
        this.city = data.city
        this.locationName = data.locationName
        this.contactName = data.contactName
        this.contactRegion = data.contactRegion
        this.contactEmail = data.contactEmail
        this.contactPhone = data.contactPhone
        this.contactAddress = data.contactAddress
        this.contactPostalCode = data.contactPostalCode
        this.contactCity = data.contactCity
        this.address = data.address
        this.region = data.region
        this.postalCode = data.postalCode
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.deathAt = dayjs(data.deathAt).hour(23)
        this.approvalDate = dayjs(data.approvalDate)
    }
}
