export enum SystemSettingsEnum {
    AMOUNT_TO_COLLECT_PER_EVENT = 'AMOUNT_TO_COLLECT_PER_EVENT',
    AUTO_APPROVE_COMMENT = 'AUTO_APPROVE_COMMENT',
    AUTO_APPROVE_ACTIVATION = 'AUTO_APPROVE_ACTIVATION',
    AUTO_APPROVE_MEMBER_TRANSFER_REQUEST = 'AUTO_APPROVE_MEMBER_TRANSFER_REQUEST',
    BABY_AGE_LIMIT_IN_DAY = 'BABY_AGE_LIMIT_IN_DAY',
    MINIMUM_MEMBER_AGE = 'MINIMUM_MEMBER_AGE',
    EVENT_DEADLINE_VALIDITY_DELAY_IN_DAY = 'EVENT_DEADLINE_VALIDITY_DELAY_IN_DAY',
    EVENT_PAYMENT_DELAY_IN_DAY = 'EVENT_PAYMENT_DELAY_IN_DAY',
    EVENT_PAYMENT_PENALITY = 'EVENT_PAYMENT_PENALITY',
    MEMBER_TRANSFER_FEES = 'MEMBER_TRANSFER_FEES',
    MINIMUM_ADMIN_PER_COMMUNITY = 'MINIMUM_ADMIN_PER_COMMUNITY',
    MINIMUM_MEMBER_PER_COMMUNITY = 'MINIMUM_MEMBER_PER_COMMUNITY',
    PLATFORM_COMMISSION_PER_MEMBER = 'PLATFORM_COMMISSION_PER_MEMBER',
    RESIDENT_ARRIVAL_LIMIT_IN_DAY = 'RESIDENT_ARRIVAL_LIMIT_IN_DAY',
    RESIDENT_COUNTRY_ALLOWED_ISO2 = 'RESIDENT_COUNTRY_ALLOWED_ISO2',
    ORIGIN_COUNTRY_ALLOWED_ISO2 = 'ORIGIN_COUNTRY_ALLOWED_ISO2',
    RESIDENT_PROBATION_DELAY_IN_DAY = 'RESIDENT_PROBATION_DELAY_IN_DAY',
    VISITOR_ARRIVAL_LIMIT_IN_DAY = 'VISITOR_ARRIVAL_LIMIT_IN_DAY',
    VISITOR_PROBATION_DELAY_IN_DAY = 'VISITOR_PROBATION_DELAY_IN_DAY',
    EMAIL_TECHNICAL_TEAM = 'EMAIL_TECHNICAL_TEAM',
    EMAIL_FINANCIAL_TEAM = 'EMAIL_FINANCIAL_TEAM',
    EMAIL_PRESIDENCE_TEAM = 'EMAIL_PRESIDENCE_TEAM',
    EMAIL_SECRETARY_TEAM = 'EMAIL_SECRETARY_TEAM',
    EMAIL_REMAINING_TEAM = 'EMAIL_REMAINING_TEAM',
    DEATH_DOCUMENTS_EXPIRATION_DELAY_IN_DAY = 'DEATH_DOCUMENTS_EXPIRATION_DELAY_IN_DAY',
    SUBSCRIPTION_FEE = 'SUBSCRIPTION_FEE',
    INACTIVITY_VISITOR_LIMIT_DELAY = 'INACTIVITY_VISITOR_LIMIT_DELAY',
    INACTIVITY_RESIDENT_LIMIT_DELAY = 'INACTIVITY_RESIDENT_LIMIT_DELAY',
    VISITOR_LIMIT_DELAY = 'VISITOR_LIMIT_DELAY',
    FIXED_AMOUNT_TO_COLLECT_PER_EVENT = 'FIXED_AMOUNT_TO_COLLECT_PER_EVENT',
}

export type GetSettingsDetails = {
    systemSetting: SystemSettingsEnum,
    label: string,
    type: 'number' | 'country' | 'boolean' | 'string',
    isMulti: boolean,
    subType: 'currency' | 'day' | 'count' | 'boolean' | 'country' | 'text',
}

export default class SystemSettings {
    public settings: Array<{ key: SystemSettingsEnum, value: string }>

    constructor(settings: Array<{ key: SystemSettingsEnum, value: string }>) {
        this.settings = [...settings].sort(((a, b) => a.key.localeCompare(b.key)))
    }

    public getValueByKey(key: SystemSettingsEnum): string | null {
        const item = this.settings.find(i => i.key === key)
        return item?.value || null
    }

    public getSettingsDetails(key: SystemSettingsEnum): GetSettingsDetails {
        switch (key) {
            case SystemSettingsEnum.EMAIL_TECHNICAL_TEAM:
            case SystemSettingsEnum.EMAIL_FINANCIAL_TEAM:
            case SystemSettingsEnum.EMAIL_PRESIDENCE_TEAM:
            case SystemSettingsEnum.EMAIL_SECRETARY_TEAM:
            case SystemSettingsEnum.EMAIL_REMAINING_TEAM:
                return {
                    systemSetting: key,
                    label: `static.types.systemSettings.${key}`,
                    type: 'string',
                    isMulti: false,
                    subType: 'text',
                }
            case SystemSettingsEnum.AMOUNT_TO_COLLECT_PER_EVENT:
            case SystemSettingsEnum.EVENT_PAYMENT_PENALITY:
            case SystemSettingsEnum.MEMBER_TRANSFER_FEES:
            case SystemSettingsEnum.SUBSCRIPTION_FEE:
            case SystemSettingsEnum.PLATFORM_COMMISSION_PER_MEMBER:
                return {
                    systemSetting: key,
                    label: `static.types.systemSettings.${key}`,
                    type: 'number',
                    isMulti: false,
                    subType: 'currency',
                }
            case SystemSettingsEnum.MINIMUM_ADMIN_PER_COMMUNITY:
            case SystemSettingsEnum.MINIMUM_MEMBER_PER_COMMUNITY:
                return {
                    systemSetting: key,
                    label: `static.types.systemSettings.${key}`,
                    type: 'number',
                    isMulti: false,
                    subType: 'count',
                }
            case SystemSettingsEnum.BABY_AGE_LIMIT_IN_DAY:
            case SystemSettingsEnum.MINIMUM_MEMBER_AGE:
            case SystemSettingsEnum.EVENT_DEADLINE_VALIDITY_DELAY_IN_DAY:
            case SystemSettingsEnum.EVENT_PAYMENT_DELAY_IN_DAY:
            case SystemSettingsEnum.RESIDENT_ARRIVAL_LIMIT_IN_DAY:
            case SystemSettingsEnum.RESIDENT_PROBATION_DELAY_IN_DAY:
            case SystemSettingsEnum.VISITOR_ARRIVAL_LIMIT_IN_DAY:
            case SystemSettingsEnum.VISITOR_PROBATION_DELAY_IN_DAY:
            case SystemSettingsEnum.DEATH_DOCUMENTS_EXPIRATION_DELAY_IN_DAY:
            case SystemSettingsEnum.INACTIVITY_VISITOR_LIMIT_DELAY:
            case SystemSettingsEnum.INACTIVITY_RESIDENT_LIMIT_DELAY:
            case SystemSettingsEnum.VISITOR_LIMIT_DELAY:
                return {
                    systemSetting: key,
                    label: `static.types.systemSettings.${key}`,
                    type: 'number',
                    isMulti: false,
                    subType: 'day',
                }
            case SystemSettingsEnum.RESIDENT_COUNTRY_ALLOWED_ISO2:
            case SystemSettingsEnum.ORIGIN_COUNTRY_ALLOWED_ISO2:
                return {
                    systemSetting: key,
                    label: `static.types.systemSettings.${key}`,
                    type: 'country',
                    isMulti: true,
                    subType: 'country',
                }
            case SystemSettingsEnum.AUTO_APPROVE_COMMENT:
            case SystemSettingsEnum.AUTO_APPROVE_MEMBER_TRANSFER_REQUEST:
            case SystemSettingsEnum.AUTO_APPROVE_ACTIVATION:
            default:
                return {
                    systemSetting: key,
                    label: `static.types.systemSettings.${key}`,
                    type: 'boolean',
                    isMulti: true,
                    subType: 'boolean',
                }
        }
    }

}
