import Config, {Env} from "./config.types";

const config: Config = {
    appName: 'RPN',
    // Set env, default to prod
    env: (process.env.REACT_APP_ENV as Env) || Env.prod,
    monerisEnv: process.env.REACT_APP_MONERIS_ENVIRONMENT || '',
    rpnApiUrl: process.env.REACT_APP_RPN_API_URL || '',
    rpnAppUrl: process.env.REACT_APP_RPN_APP_URL || '',
    language: {
        available: ['en', 'fr'],
        default: 'fr',
        fullAvailable: {
            en: {
                text: 'language.en',
                locale: 'en',
                icon: 'CustomUsa',
            },
            fr: {
                text: 'language.fr',
                locale: 'fr',
                icon: 'CustomFrance',
            },
        }
    },
    url: {
        keyValueSeparator: '~~',
        valuesSeparator: '~'
    },
    storage: {
        prefix: 'rpn__'
    },
    dataTable: {
        defaultPageSize: 100,
        defaultPageOptions: [25, 50, 100, 250, 500]
    },
    ui: {
        mobileBreakpointSize: 768,
        asideBreakpointSize: 1024,
    },
    colors: {
        primary: {
            50: '#e0f2f1',
            100: '#b2dfdb',
            200: '#80cbc3',
            300: '#4db6ab',
            400: '#26a699',
            500: '#009687',
            600: '#00897a',
            700: '#00796a',
            800: '#00695b',
            900: '#004d3f',
            light: '#33ab9f',
            dark: '#00695f',
            default: '#009687', // 500
        },
        secondary: {
            50: '#fdeaef',
            100: '#facad4',
            200: '#e9949d',
            300: '#dd6b78',
            400: '#e84559',
            500: '#ed2c41',
            600: '#de213f',
            700: '#cc1639',
            800: '#BF0A30',
            900: '#b00026',
            light: '#cb3b59',
            dark: '#850721',
            default: '#BF0A30', // 800
        }
    }
}

export default config
