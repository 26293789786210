import dayjs, {Dayjs} from "dayjs";
import { LanguageDict } from "../types";

export default class Article {
    public id: number
    public titleFr: string;
    public contentFr: string;
    public titleEn: string;
    public contentEn: string;
    public cover: string
    public tagsFr: string[]
    public tagsEn: string[]
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference || data.id
        this.titleFr = data.titleFr;
        this.contentFr = data.contentFr;
        this.titleEn = data.titleEn;
        this.contentEn = data.contentEn;
        this.cover = data.cover
        this.tagsFr = data.tagsFr
        this.tagsEn = data.tagsEn
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getTitle = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`title${locale[0].toUpperCase()}${locale.substring(1)}`]
    }

    getContent = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`content${locale[0].toUpperCase()}${locale.substring(1)}`]
    }

    getTags = (locale: LanguageDict): string[] => {
        // @ts-ignore
        return this[`tags${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
