import dayjs from "dayjs";
import {Portal} from "@mui/material";
import React, {Suspense, useMemo} from 'react';
import {IntlProvider} from "react-intl";

import AppLocale from "./lang";
import RoutesIndex from "./routes";
import {Language} from "./commons/types";
import useSettingsStore from "./store/slices/settings";
import RequestGlobalLoader from "./components/RequestGlobalLoader";
import ReactNotificationWrapper from "./components/notifications/ReactNotificationWrapper";
import ErrorBoundary from "./components/errors/ErrorBoundaries";
import FullScreenLoader from "./components/loaders/FullScreenLoader";
import {ThemeContextProvider} from "./commons/contexts/ThemeContext";

const Main = () => {
    const locale = useSettingsStore(state => state.locale);

    const currentAppLocale = useMemo(() => {
        const _currentAppLocale: Language = AppLocale[locale];

        // Apply selected language to dates
        dayjs.locale(_currentAppLocale.locale);

        return _currentAppLocale;
    }, [locale]);

    return (
        <IntlProvider
            key={currentAppLocale.locale}
            locale={currentAppLocale.locale}
            // @ts-ignore
            messages={currentAppLocale.messages}>
            <ErrorBoundary>
                <Suspense fallback={<FullScreenLoader />}>
                    <ThemeContextProvider>
                        <>
                            <RoutesIndex />

                            <Portal>
                                <ReactNotificationWrapper />
                            </Portal>

                            <RequestGlobalLoader />
                        </>
                    </ThemeContextProvider>
                </Suspense>
            </ErrorBoundary>

        </IntlProvider>
    );
};

export default Main;
