import { LanguageDict } from "../types";
import StorageHelper, {StorageKeys} from "./StorageHelper";

/**
 * Get browser locale
 * @returns {string}
 */
export const getLocaleFromBrowser = (): string => {
    // @ts-ignore Allow userLanguage since its for old browsers version
    const browserLanguage: string = window.navigator.language || window.navigator.userLanguage;
    return browserLanguage.split('-')[0];
};

/**
 * Get the default language i.e previous language or browser language or english in case of not found
 * @returns {*}
 */
export function getDefaultLanguage() : LanguageDict {
    // let userLanguage = StorageHelper.getItem(StorageKeys.LANGUAGE);

    // if (!userLanguage) {
    //     userLanguage = getLocaleFromBrowser();
    // }

    // In case of not found or any other unknown value, fallback to default language
    const initialLanguage: LanguageDict = "fr";
    //config.language.available.includes(userLanguage as LanguageDict)
    //    ? (userLanguage as LanguageDict)
    //    : config.language.default;

    // Reset locale storage with good value since many options were possible
    StorageHelper.setItem(StorageKeys.LANGUAGE, initialLanguage);

    return initialLanguage;
}
