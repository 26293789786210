import React, {useEffect, useState} from 'react';
import AuthRoutes from "./AuthRoutes";
import UnauthRoutes from "./UnauthRoutes";
import useAuthUserStore from "../store/slices/authUser";
import {setAuthUser} from "../store/slices/authUser/actions";
import FullScreenLoader from "../components/loaders/FullScreenLoader";

const RoutesIndex = () => {
    const [appLoading, setAppLoading] = useState(true);
    const authUser = useAuthUserStore(s => s.content)

    const loadData = () => {
        setAuthUser().then(() => setAppLoading(false))
    }

    useEffect(() => {
        loadData()
    }, []);

    if (appLoading)
        return (<FullScreenLoader />)

    if (authUser)
        return (<AuthRoutes />)

    return (<UnauthRoutes />);
};

export default RoutesIndex;
