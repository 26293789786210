import clsx from "clsx";
import React from 'react';
import {Helmet} from "react-helmet";
import Paper from "@mui/material/Paper";

import {globalT} from "../../lang";
import Logo from "../../components/Logo";
import Language from "../../components/layouts/Language";
import BaseConvention from "../../components/conditions/convention";

const wrapperStyle = {
    backgroundImage: 'url(/assets/images/philadelphia-murale.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
}

const Convention = () => {
    return (
        <>
            <Helmet>
                <title>{globalT('convention')}</title>
            </Helmet>
            <div className='position-fixed left-0px right-0px overflow-x-hidden overflow-y-auto h-100' style={wrapperStyle}>
                <div className='container'>
                    <div className="row">
                        <div className="col-12">
                            <div className="row mt-3">
                                <div className="col">
                                    <div className='center-ver justify-content-between'>
                                        <Logo width={100} />
                                        <div className="d-flex">
                                            <Language style={{marginLeft: '5px'}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="container">
                                    <div className="d-flex justify-content-center row mx-2 mx-sm-0">
                                        <Paper
                                            elevation={16}
                                            style={{ borderRadius: 10 }}
                                            className={clsx('col-md-12 col-lg-9 py-15 text-center')}
                                        >
                                            <BaseConvention />
                                        </Paper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Convention;
