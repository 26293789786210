import React from 'react';

import BaseRequest from "./BaseRequest";
import IntlMessage from "../IntlMessage";

const ResourceNotFound404 = () => (
    <BaseRequest
        showBtn
        message={{
            id: 'errors.404.message'
        }}
        title={<IntlMessage id="errors.404.title" />}
    />
);

export default ResourceNotFound404;
