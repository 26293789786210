import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import React, {useEffect} from 'react';

import IntlMessage from "../../../components/IntlMessage";
import useSystemSettingsStore from "../../../store/slices/systemSettings";
import {setSystemSettings} from "../../../store/slices/systemSettings/actions";
import { SystemSettingsEnum } from "../../../commons/models/SystemSettings";

const Welcome: React.FC<{ onPreConditionOver: () => void }> = ({ onPreConditionOver }) => {
    const systemSettings = useSystemSettingsStore(s => s.content);

    useEffect(() => {
        setSystemSettings().catch(() => null);
    }, []);

    return (
        <div className='row'>
            <div className='col-sm-12 col-md-12 text-center'>
                <ol style={{listStylePosition: 'inside', width: 'fit-content', textAlign: 'left', display: 'inline-block'}}>
                    <p><IntlMessage id='registration.welcome.subtitle' />:</p>
                    <li><IntlMessage id='registration.welcome.step1' /></li>
                    <li><IntlMessage id='registration.welcome.step2' values={{minimumMembers: Number(systemSettings?.settings?.find((setting: { key: SystemSettingsEnum, value: string }) => setting.key === SystemSettingsEnum.MINIMUM_MEMBER_PER_COMMUNITY)?.value)}} /></li>
                    <li><IntlMessage id='registration.welcome.step3' /></li>
                    <li><IntlMessage id='registration.welcome.step4' /></li>
                    <li><IntlMessage id='registration.welcome.step5' values={{minimumManagers: Number(systemSettings?.settings?.find((setting: { key: SystemSettingsEnum, value: string }) => setting.key === SystemSettingsEnum.MINIMUM_ADMIN_PER_COMMUNITY)?.value)}} /></li>
                    <li><IntlMessage id='registration.welcome.step6' values={{subscriptionFees: 'CAD '+Number(systemSettings?.settings?.find((setting: { key: SystemSettingsEnum, value: string }) => setting.key === SystemSettingsEnum.SUBSCRIPTION_FEE)?.value)}} /></li>
                </ol>
            </div>
            <div className='col-sm-12 col-md-12 text-center'>
                <Button
                    size='small'
                    color='secondary'
                    variant='contained'
                    onClick={() => onPreConditionOver()}
                    endIcon={<SendIcon />}
                    className='text-inherit mt-3 px-3 ms--10px'>
                    <IntlMessage id='button.startRegistration' />
                </Button>
            </div>
        </div>
    );
};

export default Welcome;
