import useDeathDocumentTypesStore from './index'
import rpnClient from "../../../network";
import DeathDocumentType from "../../../commons/models/DeathDocumentType";
import {RPNClientApiPaginatedResponse} from "../../../network/network.types";

export const setDeathDocumentTypesLoader = () => useDeathDocumentTypesStore.setState((state) => {
    state.loading = true
})

export const setDeathDocumentTypesSuccess = (value: RPNClientApiPaginatedResponse<DeathDocumentType[]>) => useDeathDocumentTypesStore.setState((state) => {
    state.loading = false
    state.fetched = true
    state.content = value
    state.error = null
})
export const  setDeathDocumentTypesFailure = (value: any) => useDeathDocumentTypesStore.setState((state) => {
    state.loading = false
    state.fetched = true
    state.content = null
    state.error = value || new Error()
})

export const setDeathDocumentTypes = (filterParams?: object) => {
    setDeathDocumentTypesLoader()
    return rpnClient.deaths.documentTypes.getAll(false, filterParams || {})
        .then((res) => {
            setDeathDocumentTypesSuccess(res.data)
            return Promise.resolve(res.data)
        })
        .catch(err => {
            setDeathDocumentTypesFailure(err)
            return Promise.resolve(err)
        })
}

