import React from 'react';
import {CircularProgress} from "@mui/material";

const FullScreenLoader = () => {
    return (
        <div className="position-fixed h-100vh w-100 center-hor-ver">
            <CircularProgress color='secondary' />
        </div>
    );
};

export default FullScreenLoader;
