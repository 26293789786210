import {AxiosError} from "axios";
import React, {useState} from 'react';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox'
import SaveIcon from "@mui/icons-material/Save";

import {globalT} from "../../../../lang";
import rpnClient from "../../../../network";
import {CONDITIONS} from "../../../../commons/urls/front";
import IntlMessage from "../../../../components/IntlMessage";
import useSettingsStore from "../../../../store/slices/settings";
import CircleCheckAnim from "../../../../components/icons/animated/CircleCheckAnim";
import NotificationManager from "../../../../components/notifications/NotificationManager";
import {RegistrationManager as RegistrationManagerModel} from "../../../../network/network.types";
import CGUDialog from "../../../../components/conditions/dialogs/cguDialog";
import ConventionDialog from "../../../../components/conditions/dialogs/conventionDialog";

type UserConditionsProps = {
    manager: RegistrationManagerModel,
    onGoToPrevious: () => void
    onGoToNext: () => void
}

const UserConditions: React.FC<UserConditionsProps> = ({ manager, onGoToPrevious, onGoToNext }) => {
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)
    const [isRegistrationFinish, setIsRegistrationFinish] = useState(false);
    const [showConvention, setShowConvention] = useState(false);
    const [showCGU, setShowCGU] = useState(false);

    const [checker, setChecker] = useState({
        acceptCGU: false,
        acceptConvention: false
    });

    const onAcceptConditions = () => {
        setRequestGlobalLoader(true)
        rpnClient
            .auth
            .register
            .communities
            .acceptCGU(manager.community?.reference!)
            .then(() => {
                setIsRegistrationFinish(true)
                onGoToNext()
            })
            .catch((err: AxiosError) => {
                if (err.response && err.response.status) {
                    if (err.response.status === 409) {
                        NotificationManager.error(globalT("form.errors.community.name"))
                    }
                }
            })
            .finally(() => setRequestGlobalLoader(false))
    }

    if (isRegistrationFinish) {
        return (
            <div className='container'>
                <div className="row text-center justify-content-center">
                    <div className="col-sm-12">
                        <div style={{ transform: 'scale(0.75)', marginBottom: '-25px', marginTop: '-25px' }}>
                            <CircleCheckAnim color='success' isDarkMode={false} />
                        </div>
                        <h3 className="h3 mt--10px">
                            <IntlMessage id='registration.over.title' />
                        </h3>
                        <p className="text-size-larger mb-0">
                            <IntlMessage
                                id='registration.over.text.1'
                                values={{
                                    strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>
                                }}
                            />
                        </p>
                        {/* <Button
                            color='primary'
                            variant='contained'
                            className='text-inherit'
                            style={{ marginTop: 20 }}
                            onClick={() => {
                                window.location.href = AUTH.LOGIN;
                            }}
                        >
                            <IntlMessage id='auth.login.btn' />
                        </Button> */}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='container mt-3'>
            <div className="row g-4 justify-content-center">
                <div className="col-sm-12 col-md-9">
                    <div className='center-ver'>
                        <Checkbox
                            checked={checker.acceptCGU}
                            onChange={(e) => {
                                if(e.target.checked) {
                                    setShowCGU(true);
                                } else {
                                    setChecker({ ...checker, acceptCGU: false }); 
                                }
                            }}
                            name="acceptCGU"
                            color="primary"
                        />
                        <p className='p-tag mb-0'>
                            <IntlMessage
                                id='registration.step.3.acceptConditions'
                                values={{
                                    a: (chunks: React.ReactNode) => (
                                        <a className='a-tag' href={CONDITIONS.CGU} target="_blank">
                                            {chunks}
                                        </a>
                                    )
                                }}
                            />
                        </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-9">
                    <div className='center-ver'>
                        <Checkbox
                            checked={checker.acceptConvention}
                            onChange={(e) => {
                                if(e.target.checked) {
                                    setShowConvention(true);
                                } else {
                                    setChecker({ ...checker, acceptConvention: false }); 
                                }
                            }}
                            name="acceptCGU"
                            color="primary"
                        />
                        <p className='p-tag mb-0'>
                            <IntlMessage
                                id='registration.step.3.acceptConvention'
                                values={{
                                    a: (chunks: React.ReactNode) => (
                                        <a className='a-tag' href={CONDITIONS.CONVENTION} target="_blank">
                                            {chunks}
                                        </a>
                                    )
                                }}
                            />
                        </p>
                    </div>
                </div>

                <div className="col-sm-12 col-md-9">
                    <div className="center-ver justify-content-between">
                        <Button
                            size='small'
                            type='button'
                            color='secondary'
                            variant='outlined'
                            onClick={() => onGoToPrevious()}
                            className='text-inherit px-3'>
                            <IntlMessage id='button.prev' />
                        </Button>
                        <Button
                            size='small'
                            color='secondary'
                            variant='contained'
                            startIcon={<SaveIcon />}
                            disabled={!(checker.acceptCGU && checker.acceptConvention)}
                            className='text-inherit px-3'
                            onClick={() => onAcceptConditions()}
                        >
                            <IntlMessage id='button.finish' />
                        </Button>
                    </div>
                </div>
                <CGUDialog show={showCGU} onClose={() => setShowCGU(false)} onAccept={() => { setChecker({ ...checker, acceptCGU: true }); setShowCGU(false); }} />
                <ConventionDialog show={showConvention} onClose={() => setShowConvention(false)} onAccept={() => { setChecker({ ...checker, acceptConvention: true }); setShowConvention(false) }} />
            </div>
        </div>
    );
};

export default UserConditions;
