import authUserStore from './index'
import rpnClient from "../../../network";
import AuthUser from "../../../commons/models/AuthUser";
import StorageHelper, {StorageKeys} from "../../../commons/helpers/StorageHelper";

export const setAuthUserLoader = () => authUserStore.setState((state) => {
    state.loading = true
})

export const setAuthUserSuccess = (value: AuthUser) => authUserStore.setState((state) => {
    state.loading = false
    state.fetched = true
    state.content = value
    state.error = null
})
export const setAuthUserFailure = (value: any) => authUserStore.setState((state) => {
    state.loading = false
    state.fetched = true
    state.content = null
    state.error = value || new Error()
})

export const logout = () => {
    StorageHelper.removeItem(StorageKeys.JWT)
    authUserStore.setState((state) => {
        state.loading = false
        state.fetched = true
        state.content = null
        state.error = null
    })
}

export const setAuthUser = () => {
    setAuthUserLoader()
    return rpnClient.auth.getDetails()
        .then((res) => {
            setAuthUserSuccess(res.data)
            return Promise.resolve()
        })
        .catch(err => {
            setAuthUserFailure(err)
            return Promise.resolve()
        })
}

