import {InternalAxiosRequestConfig} from "axios";

export type RPNClientApiPaginatedResponse<T> = {
    content: T,
    pageable: {
        pageNumber: number,
        pageSize: number,
    },
    totalElements: number,
    totalPages: number,
}

export type AxiosRequestConfigExtended = InternalAxiosRequestConfig & {
    shouldSkipToken?: boolean;
    shouldSkipDataParsing?: boolean;
    skipSnakeCaseTransform?: boolean;
    skipSnakeCaseTransformRequest?: boolean;
    /**
     * Whether to skip the auto transform present before axios interceptors
     */
    skipAutoFormDataTransform?: boolean;
    /**
     * Whether to remove value like undefined, '' and []
     */
    removeTrashValueRequest?: boolean;
    fileData?: Array<string>;
    noFormData?: boolean;
    skipAllErrors?: boolean;
    skipErrorCodes?: Array<number>;
}

export interface FetchResource<ContentType, Error = any> {
    loading: boolean,
    fetched?: boolean,
    error: Error,
    content: ContentType | null,
}

export interface FetchResourceWatcher<ContentType, Error = any> extends FetchResource<ContentType, Error> {
    watcher: string
}

export type PaginatedRequestParams = {
    page?: number
    pageSize?: number
    orderBy?: string
    orderDirection?: string
    search?: string
}


/* Request */

export type LoginRequest = {
    password: string,
    login: string,
}

export type PasswordChangeRequest = {
    password: string,
    oldPassword: string,
}

export class RegistrationManager {
    public id: string
    public firstName: string
    public lastName: string
    public birthDate: string
    public email: string
    public idCardType: string
    public idCardNumber: string
    public alpha2Code: string
    public city: string
    public address: string
    public postalCode: string
    public phoneNumber: string
    public login?: string
    public emailVerified: boolean
    public language: string
    public community: null | {
        id: number
        reference: string
        name: string
        acronym: string
        memberCount: number
        conditionAccepted: boolean
        address: string
        email: string
        city: string
        region: string
        alpha2Code: string
        phoneNumber: string
        postalCode: string
        status: string
    }

    constructor(data: any) {
        this.id = data.reference;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.birthDate = data.birthdate;
        this.email = data.email;
        this.emailVerified = data.emailVerified;
        this.idCardType = data.identificationType;
        this.idCardNumber = data.identificationNumber;
        this.alpha2Code = data.country;
        this.city = data.city;
        this.address = data.address;
        this.postalCode = data.postalCode;
        this.language = data.language;
        this.phoneNumber = data.telephone;
        this.login = data.login;
        this.community = data.community ? {
            reference: data.community.reference,
            id: data.community.id,
            name: data.community.name,
            email: data.community.email,
            acronym: data.community.acronym,
            conditionAccepted: data.community.conditionAccepted,
            memberCount: Number.isInteger(Number(data.community.memberCount)) ? Number(data.community.memberCount) : 0,
            alpha2Code: data.community.alpha2Code || '',
            city: data.community.city,
            region: data.community.region,
            address: data.community.address,
            phoneNumber: data.community.telephone,
            postalCode: data.community.postalCode,
            status: data.community.status,
        } : null;
    }

}
