import dayjs, {Dayjs} from "dayjs";
import {LanguageDict} from "../types";

export default class DeathDocumentType {
    public id: string
    public nameFr: string
    public nameEn: string
    public required: boolean
    public specimen: string
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.nameFr = data.nameFr
        this.nameEn = data.nameEn
        this.required = data.required
        this.specimen = data.specimen
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
