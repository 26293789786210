import React from 'react'
import * as yup from "yup";
import { AxiosError } from 'axios';
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";

import rpnClient from "../../../../network";
import {AppLanguages, globalT} from "../../../../lang";
import IntlMessage from "../../../../components/IntlMessage";
import {LanguageDict} from "../../../../commons/types";
import useSettingsStore from "../../../../store/slices/settings";
import * as yupSchema from "../../../../components/forms/yupShema";
import InputComponent from "../../../../components/forms/InputComponent";
import StorageHelper, {StorageKeys} from "../../../../commons/helpers/StorageHelper";
import NotificationManager from "../../../../components/notifications/NotificationManager";
import {RegistrationManager as RegistrationManagerModel} from "../../../../network/network.types";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    firstName: yupSchema.username,
    lastName: yupSchema.username,
    phoneNumber: yupSchema.canadianPhone,
    email: yupSchema.email,
    language: yupSchema.language,
});

type FormType = {
    firstName?: string
    lastName?: string
    phoneNumber?: string
    language?: { label: string; value: string; },
    email?: string
}

type RegistrationManagerProps = {
    oldManager: RegistrationManagerModel | null
    onGoToPrevious: () => void
    onGoToNext: (newManager: RegistrationManagerModel, action: 'create' | 'update') => void
}

const getDefaultValues = (oldManager: RegistrationManagerProps['oldManager'], locale: LanguageDict): FormType => {

    if (!oldManager)
        return {}

    return {
        firstName: oldManager.firstName,
        lastName: oldManager.lastName,
        phoneNumber: oldManager.phoneNumber,
        email: oldManager.email,
        language: AppLanguages.map((lang) => { return {label: globalT(lang.name), value: lang.id} }).find(l => l.value === (oldManager.language+"").toLowerCase()) || undefined
    }
}

const RegistrationManager: React.FC<RegistrationManagerProps> = ({ oldManager, onGoToNext, onGoToPrevious }) => {
    const locale = useSettingsStore(s => s.locale)
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)

    const {
        control,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        defaultValues: getDefaultValues(oldManager, locale),
        resolver: yupResolver(schema),
    });

    const isFormValid = (data: FormType) => {
        let isValid = true

        return isValid
    }

    const onSubmit: SubmitHandler<FormType> = (data) => {
        if (!isFormValid(data)) {
            return;
        }
        
        const _data = {
            firstName: data.firstName,
            lastName: data.lastName,
            telephone: data.phoneNumber,
            email: data.email,
            language: data.language ? data.language.value.toUpperCase() : 'EN',
        }
        
        if (oldManager?.id)
            // @ts-ignore
        _data.userReference = oldManager.id

        setRequestGlobalLoader(true)

        rpnClient
            .auth
            .register
            .managers
            .create(_data)
            .then((res) => {
                StorageHelper.setItem(StorageKeys.REGISTRATION_MANAGER_ID, res.data.id)
                onGoToNext(res.data, oldManager?.id ? 'update' : 'create')
            })
            .catch((err: AxiosError) => {
                if (err.response && err.response.status) {
                    if (err.response.status === 409) {
                        NotificationManager.error(globalT("form.errors.email.inuse"))
                    }
                }
            })
            .finally(() => setRequestGlobalLoader(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="container">
            <div className="row g-4">
                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="firstName"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        label={<IntlMessage id="form.field.firstName" />}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="lastName"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        label={<IntlMessage id="form.field.lastName" />}
                    />
                </div>

                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        name="phoneNumber"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        isOutsideLabel
                        label={<IntlMessage id="form.field.phoneNumber" />}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <InputComponent
                        setValue={setValue}
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        name="language"
                        componentType="select"
                        label={<IntlMessage id="language" />}
                        options={AppLanguages.map((lang) => { return {label: globalT(lang.name), value: lang.id} })}
                    />
                    {/* <Label>
                        <IntlMessage id='language'/>
                    </Label>
                    <InputComponent
                        id="language"
                        name="language"
                        errors={errors}
                        control={control}
                        componentType="custom"
                        // @ts-ignore
                        render={({ field }) => (
                            <Select
                                {...field}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => globalT(option.name)}
                                defaultValue={AppLanguages.find(l => l.id === oldManager?.language!.toLowerCase())}
                                placeholder={<IntlMessage id='language'/>}
                                options={AppLanguages}
                            />
                        )}
                    /> */}
                </div>

                <div className="col-sm-12 col-md-12">
                    <InputComponent
                        name="email"
                        errors={errors}
                        control={control}
                        noPlaceholder={false}
                        label={<IntlMessage id="form.field.email" />}
                    />
                    <Alert severity='info' className='mt-2'>
                        <IntlMessage id='registration.checking.email.message' />
                    </Alert>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    <Button
                        size='small'
                        type='button'
                        color='secondary'
                        variant='outlined'
                        onClick={() => onGoToPrevious()}
                        className='text-inherit px-3'>
                        <IntlMessage id='button.prev' />
                    </Button>
                </div>
                <div className="col text-end">
                    <Button
                        size='small'
                        type='submit'
                        color='secondary'
                        variant='contained'
                        endIcon={<SendIcon />}
                        className='text-inherit px-3'>
                        <IntlMessage id='button.next' />
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default RegistrationManager;
