import {Enumify} from "./Enumify";
import {formatTransferTypeToForm} from "../helpers/businessHelper";

export default class TransferTypeEnum extends Enumify<string> {
    static readonly sender = new TransferTypeEnum('SENDER');
    static readonly receiver = new TransferTypeEnum('RECEIVER');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatTransferTypeToForm(this)
    }
}
