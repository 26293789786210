import configDev from './config.dev';
import configProd from './config.prod';
import {Env} from "./config.types";

const config = (function () {
    if (process.env.REACT_APP_ENV === Env.local) {
        return configDev;
    } else return configProd;
})();

export default config;
