import {Enumify} from "./Enumify";
import {formatTransactionDirectionToForm} from "../helpers/businessHelper";

export default class TransactionDirectionEnum extends Enumify<string> {
    static readonly cash_in = new TransactionDirectionEnum('CASH_IN');
    static readonly cash_out = new TransactionDirectionEnum('CASH_OUT');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatTransactionDirectionToForm(this)
    }

    get statusMap() {
        switch (this) {
            case TransactionDirectionEnum.cash_in:
                return { color: 'primary' }
            case TransactionDirectionEnum.cash_out:
            default:
                return { color: 'danger' }
        }
    }
}
