import clsx from 'clsx'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import React, { FC, forwardRef, HTMLAttributes, ReactNode, useContext, useState } from 'react'

import { MenuItem } from './menu'
import Item from './NavigationItem'
import { globalT } from '../../../lang'
import Permissions from '../../../commons/permissions'
import { AbilityContext } from '../../../commons/permissions/Can'

interface IListProps extends HTMLAttributes<HTMLUListElement> {
	id?: string
	children?: ReactNode
	className?: string
	ariaLabelledby?: string
	parentId?: string
	rootId?: string
	horizontal?: boolean
}
export const List = forwardRef<HTMLUListElement, IListProps>(
	({ id, children, className, ariaLabelledby, parentId, rootId, horizontal, ...props }, ref) => {
		return (
			<ul
				ref={ref}
				id={id}
				className={clsx('navigation', { 'navigation-menu': horizontal }, className)}
				aria-labelledby={ariaLabelledby}
				data-bs-parent={
					parentId === `${rootId}__${rootId}`
						? `#${rootId}`
						: (parentId && `#${parentId}`) || null
				}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				{children}
			</ul>
		)
	},
)

interface INavigationLineProps {
	className?: string
}
export const NavigationLine: FC<INavigationLineProps> = ({ className }) => {
	return <hr className={clsx('navigation-line', className)} />
}

interface INavigationTitleProps extends HTMLAttributes<HTMLSpanElement> {
	children: ReactNode
}

export const NavigationTitle: FC<INavigationTitleProps> = ({ className, children, ...props }) => {
	return (
		<li className='navigation-item'>
			<span className={clsx('navigation-title', className)} {...props}>
				{children}
			</span>
		</li>
	)
}

interface NavigationProps {
	horizontal?: boolean,
	menu: Record<string, MenuItem>
	id: string
	className?: string
}

const Navigation = forwardRef<HTMLElement, NavigationProps>(
	({ menu, horizontal, id, className, ...props }, ref) => {
		const [activeItem, setActiveItem] = useState(undefined)
		const ability = useContext(AbilityContext)

		function fillMenu(
			data: Record<string, MenuItem>,
			parentId: string,
			rootId: string,
			isHorizontal: boolean | undefined,
			isMore: boolean | undefined,
		) {
			return Object.keys(data).map((key: string) => {
				const item = data[key]

				// Permissions middleware
				if (item.hide) {
					return <React.Fragment key={item.id} />
				}

				if (item.permissions) {
					const func = item.some ? 'some' : 'every'
					if ( ! item.permissions[func](p => ability.can(p, Permissions)) ) {
						return <React.Fragment key={item.id} />
					}
				}

				if (item.isLine) {
					return (<NavigationLine key={item.id} />)
				}

				return item.path ? (
					<Item
						key={item.id}
						rootId={rootId}
						id={item.id}
						title={item.text}
						icon={item.icon}
						to={`${item.path}`}
						parentId={parentId}
						isHorizontal={isHorizontal}
						setActiveItem={setActiveItem}
						activeItem={activeItem}
						notification={item.notification}
						hide={item.hide}>
						{(item.subMenu) &&
						fillMenu(
							item.subMenu,
							item.id,
							rootId,
							isHorizontal,
							undefined,
						)}
					</Item>
				) : (
					!isMore &&
					!isHorizontal && (
						<NavigationTitle key={item.id}>{globalT(item.text as string)}</NavigationTitle>
					)
				)
			})
		}

		return (
			// @ts-ignore
			// eslint-disable-next-line react/jsx-props-no-spreading
			<nav ref={ref} aria-label={id} className={className} {...props}>
				<List id={id} horizontal={horizontal}>
					{fillMenu(menu, id, id, horizontal, undefined)}
					{horizontal && (
						<Item
							rootId={`other-${id}`}
							title={globalT('more')}
							icon={<MoreHorizIcon />}
							isHorizontal
							isMore>
							{fillMenu(menu, `other-${id}`, `other-${id}`, false, true)}
						</Item>
					)}
				</List>
			</nav>
		)
	},
)

export default Navigation
