import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import {CurrencyType, LanguageDict} from "../../commons/types";
import StorageHelper, { StorageKeys } from '../../commons/helpers/StorageHelper';
import { getDefaultLanguage } from '../../commons/helpers/localizationHelper';

type SettingsStore = {
    locale: LanguageDict,
    setLocale: (value: LanguageDict) => void,
    requestGlobalLoader: boolean,
    setRequestGlobalLoader: (value: boolean) => void,
    appCurrency: CurrencyType,
    setAppCurrency: (value: CurrencyType) => void
}

const useSettingsStore = create<SettingsStore>()(devtools(immer(
    (set) => ({
        locale: StorageHelper.getItem(StorageKeys.LANGUAGE) as LanguageDict ?? getDefaultLanguage(),
        requestGlobalLoader: false,
        setRequestGlobalLoader: (value: boolean) => set((state) => {
            state.requestGlobalLoader = value
        }),
        setLocale: (value: LanguageDict) => set((state) => {
            state.locale = value;
            StorageHelper.setItem(StorageKeys.LANGUAGE, value);
        }),
        appCurrency: {
            code: "general.currency.cad.code",
            name: "general.currency.cad.name",
            symbol: "general.currency.cad.symbol",
            placement: 'left',
            symbolWithNumber: "general.currency.cad.symbol.n",
        },
        setAppCurrency: (value: CurrencyType) => set((state) => {
            state.appCurrency = value
        })
    })), { name: 'SettingsStore', store: 'SettingsStore' })
)

export default useSettingsStore;
