import React from 'react';

import Icon from "../icons";
import config from "../../config";
import {globalT} from "../../lang";
import BsButton from "../bootstrap/BsButton";
import useSettingsStore from "../../store/slices/settings";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../bootstrap/Dropdown';

const Language = ({style}: {style?: any}) => {
    const locale = useSettingsStore(s => s.locale)
    const setLocale = useSettingsStore(s => s.setLocale)

    return (
        <div className={`col-auto`} style={style}>
            <Dropdown>
                <DropdownToggle hasIcon={false}>
                    <BsButton
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        isLight
                        size='lg'
                        color='light'
                        aria-label='Change language'
                        data-tour='lang-selector'
                    >
                        {/* @ts-ignore */}
                        <Icon icon={config.language.fullAvailable[locale]!.icon} />
                        <span style={{ fontWeight: 100, color: 'black', fontSize: 12, marginLeft: 5 }}>{globalT(config.language.fullAvailable[locale]!.text)}</span>
                    </BsButton>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd isCloseAfterLeave={false}>
                    {Object.values(config.language.fullAvailable).map((langItem) => (
                        <DropdownItem key={langItem.locale}>
                            <BsButton
                                icon={langItem.icon}
                                onClick={() => {
                                    setLocale(langItem.locale);
                                    window.location.reload();
                                }}
                            >
                                {/* @ts-ignore */}
                                <Icon icon={langItem.icon} />
                                {globalT(langItem.text)}
                            </BsButton>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default Language;
