/**
 * App Language Provider
 * Add more locales here
 */
import dayjs from "dayjs";
import * as locales from './locales';
import {Language, LanguageDict} from "../commons/types";
import { createIntlCache, createIntl } from 'react-intl';
import {FormatXMLElementFn, PrimitiveType} from "intl-messageformat";
import StorageHelper, { StorageKeys } from "../commons/helpers/StorageHelper";
import { getDefaultLanguage } from "../commons/helpers/localizationHelper";

// Prepare locale for dates
require('dayjs/locale/en');
require('dayjs/locale/fr');

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const AppLocale: Record<LanguageDict, Language> = {
    'en': locales.EnLang,
    'fr': locales.FrLang,
};

export const AppLanguages: Array<Language> = [locales.EnLang, locales.FrLang];

// Get language for int API
// const locale = useSettingsStore(s => s.locale)
// console.log(locale);
const lang = StorageHelper.getItem(StorageKeys.LANGUAGE) as LanguageDict ?? getDefaultLanguage();
const currentAppLocale = AppLocale[lang];

// Dates configuration
// Check to apply chosen locale because by default en is applied
if (currentAppLocale.locale !== locales.EnLang.locale) {
    dayjs.locale(currentAppLocale.locale);
}

// @ts-ignore
const intl = createIntl(currentAppLocale, cache);

export type IntlValuesParam = Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;

/**
 * Programmatically access to internationalization
 * Usefully for non components file
 * @param id
 * @param values
 */
export const globalT = (id: string, values?: IntlValuesParam) => {
    return intl.formatMessage({ id }, values);
}

export const onIntlError = (err: any) => {
    if (err.code === "MISSING_TRANSLATION") {
        console.warn("Missing translation", err.message);
        return;
    }
    throw err;
};

export default AppLocale;
