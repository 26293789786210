import clsx from "clsx";
import React, {forwardRef} from "react";

interface ISubHeaderLeftProps {
    children: React.ReactNode;
    className?: string;
}
export const SubHeaderLeft: React.FC<ISubHeaderLeftProps> = ({ children, className }) => {
    return <div className={clsx('subheader-left', 'col-sm', className)}>{children}</div>;
};


interface ISubHeaderRightProps {
    children: React.ReactNode;
    className?: string;
}
export const SubHeaderRight: React.FC<ISubHeaderRightProps> = ({ children, className }) => {
    return (
        <div className={clsx('subheader-right', 'col-sm-auto', className)}>{children}</div>
    );
};

interface ISubheaderSeparatorProps {
    className?: string;
}
export const SubheaderSeparator: React.FC<ISubheaderSeparatorProps> = ({ className }) => {
    return <div className={clsx('subheader-separator', className)} />;
};

interface ISubHeaderProps {
    children: React.ReactNode;
    className?: string;
}
export const SubHeader: React.FC<ISubHeaderProps> = ({ children, className }) => {
    return (
        <div className={clsx('subheader row mx-0', className)}>
            {children}
        </div>
    );
};

interface PageContentProps {
    children: React.ReactNode;
    container?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fluid';
    className?: string;
}

const PageContent = forwardRef<HTMLDivElement, PageContentProps>(
    ({ children, className, container = 'xxl', ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={clsx('page-content', className, {
                    [`container${typeof container === 'string' ? `-${container}` : ''}`]: !!container,
                })}
                {...props}>
                {children}
            </div>
        );
    },
);

export default PageContent
