import config from "../../config";


/***
 * The goal here is to centralize query params management
 * For array values, we've created a custom key value system defined as follows
 *  - key: {realKey}{keyValueSeparator}{value1}{valuesSeparator}{value2}
 *  - value: '' empty
 *
 *  Example for name=john, campaignIds = [12, 487, 620], keyValueSeparator = '~~' and valuesSeparator = '~
 *  in the url, we'd have: ?campaignIds~~12~487~620=&name=john
 *
 *  To delete a key in this system, simply that an empty array
 */

/**
 * Get all query parameter from url
 */
export const getAllQueryParams = <Filters>(): Partial<Filters> => {
    const params = new URLSearchParams(window.location.search);

    // @ts-ignore
    return Array.from(params.keys()).reduce(
        // @ts-ignore
        (acc: Record<keyof Filters, string | null>, key: string) => {
            // [Deprecated] old array system
            if (key.includes('[]')) {
                return {
                    ...acc,
                    [key]: params.getAll(key)
                }
            }

            // Custom key-value system
            if (key.includes(config.url.keyValueSeparator)) {
                const [realKey, realValue] = key.split(config.url.keyValueSeparator)
                return {
                    ...acc,
                    [realKey]: realValue?.includes(config.url.valuesSeparator)
                        ? realValue?.split(config.url.valuesSeparator)
                        : realValue
                }
            }

            const value = params.get(key)
            return {
                ...acc,
                [key]: value?.includes(',')
                    ? value?.split(',')
                    : value
            }
        },
        {}
    );
}

export const setValuesToQueryParams = <Filters>(values: Partial<Filters>) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(elt => {
        // @ts-ignore
        if (![undefined, null, NaN, ''].includes(elt[1])) {
            // [Deprecated] Handle array case
            if (elt[0].includes('[]') && Array.isArray(elt[1])) {
                url.searchParams.delete(elt[0]);
                elt[1].forEach((item) => {
                    url.searchParams.append(elt[0], `${item}`);
                });
            }

            // Handle new array case with custom key-value system
            else if (Array.isArray(elt[1])) {
                // Deletion
                Array.from(params.keys()).forEach(key => {
                    if (key.includes(elt[0])) {
                        url.searchParams.delete(key);
                    }
                })
                if (elt[1].length > 0) {
                    // Set new value
                    // The result should look like sites~=12,45,02 dependent on separators values
                    const newKey = elt[0] + config.url.keyValueSeparator + elt[1].join(config.url.valuesSeparator)
                    url.searchParams.set(newKey, '');
                }
            }
            // literal value case
            else url.searchParams.set(elt[0], `${elt[1]}`);
        } else {
            url.searchParams.delete(elt[0]);
        }
    });

    window.history.replaceState(null, '', url.toString());
}
