import dayjs, {Dayjs} from "dayjs";
import Role from "./Role";
import { AppLanguages } from "../../lang";
import { Language } from "../types";
import Community from "./Community";

export default class Manager {
    public id: string
    public firstName: string
    public lastName: string
    public birthDate: string
    public email: string
    public idCardType: string
    public idCardNumber: string
    public alpha2Code: string
    // public country: CountryLight | null
    public city: string
    public address: string
    public postalCode: string
    public phoneNumber: string
    public language: Language | undefined 
    public emailVerified: boolean
    public blocked: boolean
    public avatar: string | null
    public role: Role | null
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public community: Community | null

    constructor(data: any) {
        this.id = data.reference;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.birthDate = data.birthdate;
        this.email = data.email;
        this.emailVerified = data.emailVerified;
        this.blocked = data.blocked;
        this.idCardType = data.identificationType;
        this.idCardNumber = data.identificationNumber;
        this.alpha2Code = data.country;
        // this.country = CountryManager.getCountryAlpha2CodeWithFlagMap(data.country);
        this.city = data.city;
        this.address = data.address;
        this.postalCode = data.postalCode;
        this.phoneNumber = data.telephone;
        this.avatar = data.avatar || null;
        this.role = data.role ? new Role(data.role) : null;
        this.language = AppLanguages.find(l => l.id === (data.language+"").toLowerCase()) || AppLanguages.at(0);
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.community = data.community ? new Community(data.community) : null
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`
    }

    static getClone = (data: any) => {
        return new Manager({
            ...data,
            reference: data.id,
            birthdate: data.birthDate,
            country: data.country,
            telephone: data.phoneNumber,
            identificationType: data.idCardType,
            identificationNumber: data.idCardNumber,
        })
    }
}
