import React from 'react';
import {CircularProgress, CircularProgressProps} from "@mui/material";

const ContentLoader: React.FC<CircularProgressProps> = (props) => {
    return (
        <CircularProgress {...props} />
    );
};

export default ContentLoader;
