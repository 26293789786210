import dayjs, {Dayjs} from "dayjs";
import { LanguageDict } from "../types";

export default class Founder {
    public id: number
    public name: string    
    public descriptionEn: string
    public descriptionFr: string
    public image: string
    public roleEn: string
    public roleFr: string
    public period: string
    public startDate: Dayjs
    public endDate: Dayjs
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference || data.id
        this.name = data.name
        this.descriptionEn = data.descriptionEn
        this.descriptionFr = data.descriptionFr
        this.image = data.image
        this.roleFr = data.roleFr
        this.roleEn = data.roleEn
        this.period = data.period
        this.startDate = dayjs(data.startDate).hour(23)
        this.endDate = dayjs(data.endDate).hour(23)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getRole = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`role${locale[0].toUpperCase()}${locale.substring(1)}`]
    }

    getDescription = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`description${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
