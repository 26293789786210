import Permission from "./Permission";

export default class Role {
    public id: string;
    public name: string;
    public description: string;
    public default: boolean;
    public permissions: Permission[]

    constructor(data: any) {
        this.id = data.reference;
        this.name = data.label;
        this.description = data.description;
        this.default = data.default;
        this.permissions = (data.permissions || []).map((p: unknown) => new Permission(p))
    }

    get permissionsIds (): string[] {
        return this.permissions.map(p => p.id)
    }
}
