import React from 'react';
import {CSSTransition, SwitchTransition as RTGSwitchTransition} from "react-transition-group";

export type MultipleItemTransitionsProps = {
    mode: string | number,
    children: React.ReactNode;
}

const MultipleItemTransitions = ({ mode, children }: MultipleItemTransitionsProps) => {
    return (
        <RTGSwitchTransition mode="out-in">
            <CSSTransition
                key={mode.toString()}
                classNames="fade"
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
            >
                {children}
            </CSSTransition>
        </RTGSwitchTransition>
    );
};

export default MultipleItemTransitions;
