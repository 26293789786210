import * as yup from "yup";
import {AxiosError} from "axios";
import React, {useState} from 'react';
import Button from "@mui/material/Button";
import { useTimer } from 'react-timer-hook';
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";

import {globalT} from "../../../../lang";
import rpnClient from "../../../../network";
import IntlMessage from "../../../../components/IntlMessage";
import {FetchResource} from "../../../../network/network.types";
import useSettingsStore from "../../../../store/slices/settings";
import * as yupSchema from "../../../../components/forms/yupShema";
import {stringifyDigit} from "../../../../commons/helpers/funcHelper";
import InputComponent from "../../../../components/forms/InputComponent";
import NotificationManager from "../../../../components/notifications/NotificationManager";
import MultipleItemTransitions from "../../../../components/transitions/MultipleItemTransitions";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    code: yupSchema.name,
});

type FormType = {
    code?: string
}

type CodeCheckingProps = {
    isRetry: boolean,
    onGoToPrev: () => void
    onVerificationDone: () => void
    managerEmail: string
    managerId: string
}

function getMinutesTimer(minute: number) {
    const time = new Date()
    time.setSeconds(time.getSeconds() + (60 * minute))
    return time
}

const CodeChecking: React.FC<CodeCheckingProps> = ({ isRetry, onGoToPrev, onVerificationDone, managerEmail, managerId }) => {
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)
    const [showSendCodePage, setShowSendCodePage] = useState<boolean>(!isRetry);
    const [receiveCode, setReceiveCode] = useState<FetchResource<boolean>>({
        content: null,
        loading: false,
        error: null
    });

    const { seconds, minutes, restart, isRunning } = useTimer({ expiryTimestamp: getMinutesTimer(10) })

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onReceiveCodeClick = () => {
        restart(getMinutesTimer(10))
        setReceiveCode({
            ...receiveCode,
            loading: true
        })
        rpnClient
            .auth
            .register
            .managers
            .emailChecking
            .resendCode(managerId)
            .then(() => {
                setShowSendCodePage(true)
                setReceiveCode({
                    content: true,
                    loading: false,
                    error: null,
                })
            })
            .catch(() => null)
    }

    const onSubmit: SubmitHandler<FormType> = (data) => {
        setRequestGlobalLoader(true)
        rpnClient
            .auth
            .register
            .managers
            .emailChecking
            .checkCode(managerId, data.code!)
            .then(() => {
                onVerificationDone()
            })
            .catch((err: AxiosError) => {
                if (err && err.response && err.response.status === 400) {
                    NotificationManager.warning(globalT('registration.checking.email.code.incorrect'))
                }
            })
            .finally(() => setRequestGlobalLoader(false))
    }

    return (
        <MultipleItemTransitions mode={showSendCodePage.toString()}>
            {!showSendCodePage ? (
                <div className='row justify-content-center text-center py-3'>
                    <div className="col-sm-12">
                        <h6 className='h6 fw-600'>
                            <IntlMessage id='registration.checking.email.resend.title' />
                        </h6>
                        <p className='p-tag py-3'>
                            <IntlMessage
                                id='registration.checking.email.resend.message'
                                values={{
                                    email: managerEmail,
                                    strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>
                                }}
                            />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="center-ver justify-content-between">
                            <Button
                                size='small'
                                color='secondary'
                                variant='outlined'
                                className='text-inherit px-3'
                                onClick={() => onGoToPrev()}>
                                <IntlMessage id='button.prev' />
                            </Button>
                            <LoadingButton
                                size='small'
                                color="secondary"
                                variant="contained"
                                onClick={onReceiveCodeClick}
                                loading={receiveCode.loading}
                                className='text-inherit px-3'
                            >
                                <span><IntlMessage id='button.code.receive' /></span>
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} className="container">
                    <div className="row g-4 text-center justify-content-center">
                        <div className="col-sm-12">
                            <h6 className='h6 fw-600 mt-3'>
                                <IntlMessage id='registration.checking.email.code.title' />
                            </h6>
                            <p className='p-tag mb-0'>
                                <IntlMessage
                                    id='registration.checking.email.code.message'
                                />
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <InputComponent
                                name="code"
                                errors={errors}
                                control={control}
                                noPlaceholder={false}
                                label={<IntlMessage id="form.field.code" />}
                            />
                            <Typography className='fs-small'>
                                <IntlMessage
                                    id='registration.checking.email.code.notReceived'
                                />
                                {isRunning ? (
                                    <span className='px-1 text-muted font-weight-bold'>
                                        <IntlMessage id='registration.checking.email.code.notReceived.disabled' />
                                        {' '}{stringifyDigit(minutes)}:{stringifyDigit(seconds)}
                                    </span>
                                ) : (
                                    <button
                                        className='text-primary text-underline-hover d-inline-flex px-1'
                                        aria-disabled={isRunning}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            onReceiveCodeClick()
                                        }}
                                    >
                                        <IntlMessage id='registration.checking.email.code.notReceived.2' />
                                    </button>
                                )}
                            </Typography>
                        </div>
                    </div>
                    <div className="row mt-1 g-4 text-center justify-content-center">
                        <div className="col-sm-12 col-md-6">
                            <div className="center-ver justify-content-between">
                                <Button
                                    size='small'
                                    color='secondary'
                                    variant='outlined'
                                    className='text-inherit px-3'
                                    onClick={() => onGoToPrev()}>
                                    <IntlMessage id='button.prev' />
                                </Button>
                                <Button
                                    size='small'
                                    type='submit'
                                    color='secondary'
                                    variant='contained'
                                    endIcon={<SendIcon />}
                                    className='text-inherit px-3'>
                                    <IntlMessage id='button.next' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </MultipleItemTransitions>
    );
};

export default CodeChecking;
