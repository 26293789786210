import React from 'react';
import {motion, AnimatePresence} from "framer-motion";

const SingleItemTransition: React.FC<{ show: boolean, children: React.ReactNode, className?: string }> = ({ show, children, className }) => {
    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    key="modal"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={className}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default SingleItemTransition;
