import clsx from "clsx";
import React from 'react';
import {Helmet} from "react-helmet";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import {globalT} from "../../lang";
import Logo from "../../components/Logo";
import {HOME} from "../../commons/urls/front";
import IntlMessage from "../../components/IntlMessage";
import Language from "../../components/layouts/Language";

const wrapperStyle = {
    backgroundImage: 'url(/assets/images/philadelphia-murale.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
}

type AuthLayoutProps = {
    titleString: string,
    title: React.ReactNode
    paperClassName: React.ReactNode
    children: React.ReactNode
    headerRight?: React.ReactNode
}

const AuthLayout = ({ paperClassName, headerRight, title, titleString, children }: AuthLayoutProps) => {
    return (
        <>
            <Helmet>
                <title>{globalT(titleString)}</title>
            </Helmet>
            <div className='position-fixed left-0px right-0px overflow-x-hidden overflow-y-auto h-100' style={wrapperStyle}>
                <div className='container'>
                    <div className="row">
                        <div className="col-12">
                            <div className="row mt-3">
                                <div className="col">
                                    <div className='center-ver justify-content-between'>
                                        <Logo width={100} />
                                        <div className="d-flex">
                                            <Button
                                                component='a'
                                                href={HOME}
                                                color='secondary'
                                                variant='contained'
                                                className='bg-white text-dark text-capitalize'>
                                                <IntlMessage id='home' />
                                            </Button>
                                            {headerRight}
                                            <Language style={{marginLeft: '5px'}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="container">
                                    <div className="d-flex justify-content-center row mx-2 mx-sm-0">
                                        <Paper
                                            elevation={16}
                                            style={{ borderRadius: 10 }}
                                            className={clsx(paperClassName)}
                                        >
                                            <div className='pb-4 px-sm-4 px-0'>
                                                <div className='p-15'>
                                                    {title}
                                                </div>
                                                {children}
                                            </div>
                                        </Paper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthLayout;
