import {LanguageDict} from "../types";
import dayjs, {Dayjs} from "dayjs";

export default class DeathEvent {
    public id: string
    public date: string
    public description: string
    public fees: number
    public nameEn: string
    public nameFr: string
    public platformFees: number
    public unitFees: number
    public amountCollected: number
    public amountDue: number
    public penalityCollected: number
    public penalityDue: number
    public createdAt: Dayjs
    public deadline: Dayjs
    public updatedAt: Dayjs
    public files: string

    constructor(data: any) {
        this.id = data.reference
        this.date = data.date
        this.description = data.description
        this.fees = data.fees
        this.amountCollected = data.amountCollected
        this.amountDue = data.amountDue
        this.penalityCollected = data.penalityCollected
        this.penalityDue = data.penalityDue
        this.nameEn = data.nameEn
        this.nameFr = data.nameFr
        this.platformFees = data.platformFees
        this.unitFees = data.unitFees
        this.createdAt = dayjs(data.createdAt)
        this.deadline = dayjs(data.deadline).hour(23)
        this.updatedAt = dayjs(data.updatedAt)
        this.files = data.files;
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
