import {Enumify} from "./Enumify";
import {formatLogItemKeyToForm} from "../helpers/businessHelper";

export default class LogItemKeyEnum extends Enumify<string> {

    static readonly birthdate = new LogItemKeyEnum('BIRTHDATE');
    static readonly lastName = new LogItemKeyEnum('LAST_NAME');
    static readonly firstName = new LogItemKeyEnum('FIRST_NAME');
    static readonly nationality = new LogItemKeyEnum('NATIONALITY');
    static readonly city = new LogItemKeyEnum('CITY');
    static readonly email = new LogItemKeyEnum('EMAIL');
    static readonly address = new LogItemKeyEnum('ADDRESS');
    static readonly country = new LogItemKeyEnum('COUNTRY');
    static readonly comment = new LogItemKeyEnum('COMMENT');
    static readonly type = new LogItemKeyEnum('TYPE');
    static readonly telephone = new LogItemKeyEnum('TELEPHONE');
    static readonly arrivalDate = new LogItemKeyEnum('ARRIVAL_DATE');
    static readonly postalCode = new LogItemKeyEnum('POSTAL_CODE');
    static readonly expirationDate = new LogItemKeyEnum('EXPIRATION_DATE');
    static readonly gender = new LogItemKeyEnum('GENDER');
    static readonly relationReference = new LogItemKeyEnum('RELATION_REFERENCE');
    static readonly identificationNumber = new LogItemKeyEnum('IDENTIFICATION_NUMBER');
    static readonly affiliationLink = new LogItemKeyEnum('AFFILIATION_LINK');
    static readonly relationShip = new LogItemKeyEnum('RELATIONSHIP');
    static readonly identificationType = new LogItemKeyEnum('IDENTIFICATION_TYPE');
    static readonly memberStatus = new LogItemKeyEnum('MEMBER_STATUS');
    static readonly status = new LogItemKeyEnum('STATUS');
    static readonly filiation = new LogItemKeyEnum('FILIATION');
    static readonly filiationDescription = new LogItemKeyEnum('FILIATION_DESCRIPTION');
    static readonly deathRequest = new LogItemKeyEnum('DEATH_REQUEST');
    static readonly inscription = new LogItemKeyEnum('INSCRIPTION');
    static readonly region = new LogItemKeyEnum('REGION');

    static readonly transferPaid = new LogItemKeyEnum('TRANSFER_PAID');
    static readonly transferPending = new LogItemKeyEnum('TRANSFER_PENDING');
    static readonly transferCancelledBySource = new LogItemKeyEnum('TRANSFER_CANCELLED_BY_SOURCE');
    static readonly transferConfirmedByTarget = new LogItemKeyEnum('TRANSFER_CONFIRMED_BY_TARGET');
    static readonly transferCancelledBytarget = new LogItemKeyEnum('TRANSFER_CANCELLED_BY_TARGET');
    static readonly transferConfirmedByPlatform = new LogItemKeyEnum('TRANSFER_CONFIRMED_BY_PLATFORM');
    static readonly transferCancelledByPlatform = new LogItemKeyEnum('TRANSFER_CANCELLED_BY_PLATFORM');
    


    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatLogItemKeyToForm(this)
    }
}
