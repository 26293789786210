import React from 'react';
import dayjs from 'dayjs';
import {ThemeProvider} from "@mui/material";
import { BrowserRouter } from 'react-router-dom';
import { AbilityContext } from './commons/permissions/Can';

import Main from "./Main";
import theme from "./commons/theme";
import ability from "./commons/permissions/ability";

import 'animate.css'
import './assets/sass/styles.scss'

// Initialize date and enable its localization
// eslint-disable-next-line @typescript-eslint/no-var-requires
const localizedFormat = require('dayjs/plugin/localizedFormat');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const relativeTime = require('dayjs/plugin/relativeTime');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  return (
      <BrowserRouter>
          <AbilityContext.Provider value={ability}>
              <ThemeProvider theme={theme}>
                  <Main />
              </ThemeProvider>
          </AbilityContext.Provider>
      </BrowserRouter>
  )
}

export default App;
