import {LanguageDict} from "../commons/types";

export enum Env {
    local = "local",
    prod = "prod",
}

export default interface Config {
    env: Env;
    appName: 'RPN';
    rpnApiUrl: string;
    rpnAppUrl: string;
    monerisEnv: string;
    language: {
        available: LanguageDict[],
        default: LanguageDict,
        fullAvailable: Record<LanguageDict, {
            text: string,
            locale: LanguageDict,
            icon: string
        }>,
    },
    url: {
        keyValueSeparator: string,
        valuesSeparator: string
    },
    storage: {
        prefix: string
    },
    dataTable: {
        defaultPageSize: number,
        defaultPageOptions: number[],
    },
    ui: {
        mobileBreakpointSize: number,
        asideBreakpointSize: number,
    },
    colors: {
        [key: string]: Record<string, string>;
    },
}
