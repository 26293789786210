import customAxios from './axiosConfig';
import Client from './rpnClient';

/**
 * Client class generic for centralization purpose
 * Some general code can be written here
 */
class RPNClient extends Client {}

// Create client class
const rpnClient = new RPNClient(customAxios);

// Use csuClient by default
export default rpnClient;
