import { globalT } from '../../lang';
import csuClient from '../../network';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Layout from "../../components/layouts";
import React, { useEffect, useState } from 'react';
import IntlMessage from "../../components/IntlMessage";
import {HOME, COMMUNITY} from "../../commons/urls/front";
import useSettingsStore from '../../store/slices/settings';
import useAuthUserStore from '../../store/slices/authUser';
import useSystemSettingsStore from '../../store/slices/systemSettings';
import { SystemSettingsEnum } from '../../commons/models/SystemSettings';
import { setSystemSettings } from '../../store/slices/systemSettings/actions';
import CircleCheckAnim from '../../components/icons/animated/CircleCheckAnim';
import MonerisCheckout from '../../components/forms/PaymentForm/MonerisCheckout';
import Statistics from './Statistics';
import CommunityStatusEnum from '../../commons/enums/CommunityStatusEnum';

const Dashboard = () => {
    const navigate = useNavigate();
    const systemSettings = useSystemSettingsStore(s => s.content);
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [paymentToken, setPaymentToken] = useState<string|null>(null);
    const authUserData = useAuthUserStore(s => s.content!);

    useEffect(() => {
        setSystemSettings().catch(() => null);
    }, []);

    const requestForSubscriptionToken: any = () => {
        if(authUserData?.community) {
            setRequestGlobalLoader(true)

            csuClient
                .payment
                .createSubscriptionToken(authUserData?.community?.id)
                .then((response: any) => {
                    setShowPaymentModal(true);
                    setPaymentToken(response.data);
                })
                .catch(() => null)
                .finally(() => setRequestGlobalLoader(false))
        }
    }
    const validateSubscription: any = (token: string) => {
        if(authUserData?.community) {
            setRequestGlobalLoader(true)

            csuClient
                .payment
                .validateSubscription(authUserData?.community?.id, token)
                .then((response: any) => {
                    setShowPaymentModal(true);
                    setPaymentToken(response.data);
                    window.location.href = HOME;
                })
                .catch(() => null)
                .finally(() => setRequestGlobalLoader(false))
        }
    }

    return (
        <Layout className='d-flex'>
            { authUserData?.community && authUserData?.community.status == CommunityStatusEnum.confirmed  ? (
                <div className='d-flex justify-content-center w-100'>
                    { authUserData?.community?.managerCount < authUserData?.community?.minimumManagerRequired ? (
                        <div className='bg-white d-flex flex-column justify-content-center align-items-center p-15 w-100'>
                            <p className='p-tag my-2 px-5 text-center'>
                                {globalT('requirement.insuficientManagers', {minimum: authUserData?.community?.minimumManagerRequired, count: authUserData?.community?.managerCount})}
                            </p>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => {
                                    navigate(COMMUNITY.MANAGERS.CREATE)
                                }}
                                className='text-inherit px-2 w-50'
                                style={{ marginTop: 20 }}
                            >
                                <IntlMessage id='community.managers.create' />
                            </Button>
                        </div>
                    ) : authUserData?.community?.currentMemberCount < Number(systemSettings?.settings?.find((setting: { key: SystemSettingsEnum, value: string }) => setting.key === SystemSettingsEnum.MINIMUM_MEMBER_PER_COMMUNITY)?.value) ? (
                        <div className='bg-white d-flex flex-column justify-content-center align-items-center p-15 w-100'>
                            <p className='p-tag my-2 px-5 text-center'>
                                {globalT('requirement.insuficientMembers', {minimum: Number(systemSettings?.settings?.find((setting: { key: SystemSettingsEnum, value: string }) => setting.key === SystemSettingsEnum.MINIMUM_MEMBER_PER_COMMUNITY)?.value), count: authUserData?.community?.currentMemberCount})}
                            </p>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => {
                                    navigate(COMMUNITY.MEMBERS.CREATE)
                                }}
                                className='text-inherit px-2 w-50'
                                style={{ marginTop: 20 }}
                            >
                                <IntlMessage id='community.members.create' />
                            </Button>
                        </div>
                    ) : (
                        <div className='bg-white d-flex flex-column justify-content-center align-items-center p-15 w-100'>
                            { showPaymentModal ? paymentToken ? (
                                <MonerisCheckout 
                                    token={paymentToken} 
                                    onCompleted={(e: any) => {
                                        // setShowPaymentModal(false);
                                        setPaymentToken(null);
                                        validateSubscription(JSON.parse(e).ticket);
                                    }}
                                    onCancelled={(e: any) => {
                                        setShowPaymentModal(false);
                                        setPaymentToken(null);
                                    }}
                                    onError={(e: any) => {
                                        setShowPaymentModal(false);
                                        setPaymentToken(null);
                                    }}
                                />
                            ) : (
                                <div className="col-sm-12 d-flex flex-column justify-content-center align-items-center">
                                    <div style={{ transform: 'scale(0.75)', marginBottom: '25px', marginTop: '-25px' }}>
                                        <CircleCheckAnim color='success' isDarkMode={false} />
                                    </div>
                                    <IntlMessage id="payment.success" />
                                </div> 
                            ) : (
                                <>
                                    <p className='p-tag my-2 px-5 text-center'>
                                        {globalT('requirement.pay')}
                                    </p>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={() => {
                                            requestForSubscriptionToken();
                                        }}
                                        className='text-inherit px-2 w-50'
                                        style={{ marginTop: 20 }}
                                    >
                                        <IntlMessage id='button.paySubscription' />
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div className='bg-white d-flex flex-column justify-content-center align-items-center p-15 w-100'>
                    <p className='p-tag my-2 px-5'>
                        <IntlMessage id="welcometoRPN" />
                    </p>
                    { authUserData?.community && (
                        <p className='p-tag my-2 px-5'>
                            {authUserData.community.name}
                        </p>
                    )}
                    <Statistics />
                </div>
            )}
        </Layout>
    );
};

export default Dashboard;
