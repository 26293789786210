import dayjs, {Dayjs} from "dayjs";
import { LanguageDict } from "../types";

export default class Advert {
    public id: string;
    public titleFr: string;
    public contentFr: string;
    public titleEn: string;
    public contentEn: string;
    public image: string;
    public link: string;
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public expiredAt: Dayjs

    constructor(data: any) {
        this.id = data.reference;
        this.titleFr = data.titleFr;
        this.contentFr = data.contentFr;
        this.titleEn = data.titleEn;
        this.contentEn = data.contentEn;
        this.image = data.image;
        this.link = data.link;
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.expiredAt = dayjs(data.expiredAt).hour(23)
    }
    
    getTitle = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`title${locale[0].toUpperCase()}${locale.substring(1)}`]
    }

    getContent = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`content${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
