// import Cropper from 'react-easy-crop';
import Button from "@mui/material/Button";
// import 'react-easy-crop/react-easy-crop.css';
import 'react-image-crop/dist/ReactCrop.css'
import React, { useState, useRef } from 'react';
import {FileItem} from "../../components/DragAndDrop";
import IntlMessage from "../../components/IntlMessage";
import DialogComponent from '../../components/dialogs/DialogComponent';

import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import ReactCrop, { PixelCrop, centerCrop, makeAspectCrop, type Crop } from 'react-image-crop';

type CroppImageModalProps = {
    show: boolean,
    file: FileItem,
    onClose: () => void,
    onSuccess: (fileItem: FileItem) => void
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: 300,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }


const CropImageModal: React.FC<CroppImageModalProps> = ({ file, show, onClose, onSuccess }) => {

    const scale = 1;
    const rotate =0;
    const aspect = 1;
    const blobUrlRef = useRef('');
    const [crop, setCrop] = useState<Crop>();
    const imgRef = useRef<HTMLImageElement>(null);
    const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
          const { width, height } = e.currentTarget
          setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onDownloadCropClick() {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d') as CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null;

        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        // const blob = await offscreen.convertToBlob({
        //   type: file.type
        // });

        previewCanvas.toBlob(function(blob) {
            if(blob) {
                if (blobUrlRef.current) {
                    URL.revokeObjectURL(blobUrlRef.current)
                }
                blobUrlRef.current = URL.createObjectURL(blob);

                const croppedFile = new File([blob], file.name, {
                    type: file.type,
                    lastModified: Date.now(),
                });

                onSuccess(Object.assign(croppedFile, {
                    preview: URL.createObjectURL(croppedFile)
                }));

            }
        }, file.type);
    }

    useDebounceEffect(
        
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )
    
    return (
        <DialogComponent
            maxWidth='md'
            disableEscapeKeyDown
            disableBackdropClick
            show={show}
            style={{ height: 'inherit' }}
            onClose={() => onClose()}
            title={(
                <IntlMessage id="crop.image" />
            )}
        >
            <div className='bg-white d-flex flex-column justify-content-center align-items-center p-15'>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                >
                    <img
                        alt=""
                        ref={imgRef}
                        id="imageId"
                        src={file?.preview}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: 20, marginBottom: 20}}>
                    <Button
                        color='primary'
                        variant='contained'
                        className='text-inherit px-3 fw-bold'
                        onClick={() => {
                            onDownloadCropClick();
                        }}
                    >
                        <IntlMessage id='button.confirm' />
                    </Button>
                </div>

                {!!completedCrop && (
                    <div style={{ display: 'none' }}>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                        <button onClick={onDownloadCropClick}>Download Crop</button>
                        <a
                            href="#hidden"
                            ref={hiddenAnchorRef}
                            download
                            style={{
                                position: 'absolute',
                                top: '-200vh',
                                visibility: 'hidden',
                            }}
                        >
                            Hidden download
                        </a>
                    </div>
                )}
            </div>
        </DialogComponent>
    );
};

export default CropImageModal;
