import {Enumify} from "./Enumify";
import {ColorType} from "../../components/components.types";
import {formatCommunityStatusToForm} from "../helpers/businessHelper";

export default class CommunityStatusEnum extends Enumify<string> {
    static readonly pending = new CommunityStatusEnum('PENDING');
    static readonly activated = new CommunityStatusEnum('ACTIVATED');
    static readonly confirmed = new CommunityStatusEnum('CONFIRMED');
    static readonly blocked = new CommunityStatusEnum('BLOCKED');
    static readonly suspended = new CommunityStatusEnum('SUSPENDED');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatCommunityStatusToForm(this)
    }

    static readonly getStatusMap = (status: CommunityStatusEnum): {
        color: ColorType
    } => {
        switch (status) {
            case CommunityStatusEnum.pending:
                return { color: 'info' }
            case CommunityStatusEnum.activated:
                return { color: 'primary' }
            case CommunityStatusEnum.blocked:
            case CommunityStatusEnum.suspended:
                return { color: 'danger' }
            case CommunityStatusEnum.confirmed:
            default:
                return { color: 'warning' }
        }
    }
}
