import {Enumify} from "./Enumify";
import {ColorType} from "../../components/components.types";
import {formatTransferStatusToForm} from "../helpers/businessHelper";

export default class TransferStatusEnum extends Enumify<string> {
    static readonly pending = new TransferStatusEnum('PENDING');
    static readonly paid = new TransferStatusEnum('PAID');

    static readonly activated = new TransferStatusEnum('ACTIVATED');
    static readonly suspended = new TransferStatusEnum('SUSPENDED');
    static readonly confirmed = new TransferStatusEnum('CONFIRMED');
    static readonly blocked = new TransferStatusEnum('BLOCKED');
    
    static readonly cancelledBySource = new TransferStatusEnum('CANCELLED_BY_SOURCE');
    static readonly cancelledByTarget = new TransferStatusEnum('CANCELLED_BY_TARGET');
    static readonly cancelledByPlatform = new TransferStatusEnum('CANCELLED_BY_PLATFORM');
    static readonly confirmedByTarget = new TransferStatusEnum('CONFIRMED_BY_TARGET');
    static readonly confirmedByPlatform = new TransferStatusEnum('CONFIRMED_BY_PLATFORM');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatTransferStatusToForm(this)
    }

    static getStatusMap = (status: TransferStatusEnum): {
        color: ColorType
    } => {
        switch (status) {
            case TransferStatusEnum.pending:
            // case TransferStatusEnum.confirmed:
                return { color: 'info' }
            case TransferStatusEnum.confirmedByTarget:
                return { color: 'warning' }
            case TransferStatusEnum.paid:
            case TransferStatusEnum.confirmedByPlatform:
            // case TransferStatusEnum.activated:
                return { color: 'primary' }
            case TransferStatusEnum.cancelledBySource:
            case TransferStatusEnum.cancelledByTarget:
                return { color: 'secondary' }
            case TransferStatusEnum.cancelledByPlatform:
            // case TransferStatusEnum.blocked:
            default:
                return { color: 'danger' }
        }
    }
}
