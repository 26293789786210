import React from 'react';
import FormText from "./FormText";
import {FieldError} from "react-hook-form/dist/types/errors";

export type ErrorInputComponentProps = {
    fieldError: FieldError | undefined;
}

/**
 * Display error message bound to an input
 * @returns {*}
 * @constructor
 */
const ErrorInputComponent: React.FC<ErrorInputComponentProps> = ({ fieldError }) => {
    if (!(fieldError && fieldError.message))
        return null

    return (
        <FormText
            id={`affiliateFiles-text`}
            style={{ marginLeft: '1rem' }}
            className='fw-bold text-danger'>
            {fieldError.message}
        </FormText>
    )
};

export default ErrorInputComponent;
