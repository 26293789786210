import * as yup from "yup";
import {AxiosError} from "axios";
import React, {useState} from 'react';
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import {yupResolver} from "@hookform/resolvers/yup";
import {NavLink} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";

import {globalT} from "../../../lang";
import AuthLayout from "../AuthLayout";
import rpnClient from "../../../network";
import {AUTH} from "../../../commons/urls/front";
import IntlMessage from "../../../components/IntlMessage";
import useSettingsStore from "../../../store/slices/settings";
import * as yupSchema from "../../../components/forms/yupShema";
import InputComponent from "../../../components/forms/InputComponent";
import NotificationManager from "../../../components/notifications/NotificationManager";
import SingleItemTransition from "../../../components/transitions/SingleItemTransition";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yupSchema.email,
});

type FormType = {
    email?: string
}

const ForgotPassword = () => {
    //const navigate = useNavigate()
    const setRequestGlobalLoader = useSettingsStore(s => s.setRequestGlobalLoader)
    const [isDone, setIsDone] = useState(false);

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormType> = (data) => {
        setRequestGlobalLoader(true)
        rpnClient
            .auth
            .forgotPassword
            .sendLink(data.email!)
            .then(() => {
                setIsDone(true)
                setRequestGlobalLoader(false)
            })
            .catch((err: AxiosError) => {
                setRequestGlobalLoader(false)
                if (err.response && err.response.status) {
                    if (err.response.status === 404) {
                        NotificationManager.error(globalT("auth.forgotPassword.init.email.notFound"))
                    }
                }
            })
    }

    return (
        <AuthLayout
            titleString='auth.forgotPassword.init.title'
            title={(
                <h3 className='h3 text-center'>
                    <IntlMessage id='auth.forgotPassword.init.title' />
                </h3>
            )}
            paperClassName="col-md-10 col-lg-7 py-15 text-center">
            {isDone ? (
                <SingleItemTransition show={true}>
                    <div className="col-sm-12">
                        <Alert severity='success'>
                            <IntlMessage id='auth.forgotPassword.init.success.message' />
                        </Alert>
                    </div>
                </SingleItemTransition>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} className="">
                    <div className="row g-4 justify-content-center">
                        <div className="col-sm-12">
                            <InputLabel className='white-space-inherit'>
                                <IntlMessage id='auth.forgotPassword.init.message' />
                            </InputLabel>
                        </div>

                        <div className="col-sm-12">
                            <InputComponent
                                autoFocus
                                type='email'
                                name="email"
                                errors={errors}
                                control={control}
                                noPlaceholder={false}
                                label={<IntlMessage id="form.field.email" />}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>

                        <div className="col-md-12">
                            <Button
                                fullWidth
                                type='submit'
                                color='secondary'
                                variant='contained'
                                disabled={isDone}
                                endIcon={<SendIcon />}
                                className='text-inherit'>
                                <IntlMessage id='button.submit' />
                            </Button>
                        </div>

                        <div className="col-md-12 mt-3">
                            <Typography>
                                <IntlMessage
                                    id='auth.forgotPassword.init.return.text'
                                    values={{
                                        a: (chunks: string) => (
                                            <NavLink to={AUTH.LOGIN} className='a-tag text-end text-secondary text-underline'>
                                                {chunks}
                                            </NavLink>
                                        )
                                    }}
                                />
                            </Typography>
                        </div>
                    </div>
                </form>
            )}
        </AuthLayout>
    );
};

export default ForgotPassword;
